import React, { useEffect, useState } from "react";
import { LOCAL_CONSTANTS } from "../../constants/localConstants";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { useMeMutation } from "../../service/baseService";
import { getLocalStorage } from "../../utils";
import Loader from "../../components/common/Loader";
import { Navigate, useLocation } from "react-router-dom";

function LandingPage() {
  const [getUserDetails, { isLoading }] = useMeMutation();
  const [path, setPath] = useState(null);
  const token = getLocalStorage(LOCAL_CONSTANTS.ACCESS);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reason = queryParams.get("reason");

  const getUser = () => {
    if (token) {
      getUserDetails()
        .unwrap()
        .then((response) => {
          const permission = response?.data?.permission;
          if (permission.length > 0) {
            const path = Array.isArray(permission?.[0]?.path)
              ? permission[0].path[0]
              : permission[0].path;
            setPath(path);
          } else {
            setPath(ROUTE_CONSTANTS.ACCESS_DENIED);
          }
        })
        .catch(() => {
          setPath(ROUTE_CONSTANTS.LOGIN);
        });
    } else {
      setPath(ROUTE_CONSTANTS.LOGIN);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return isLoading || !path ? (
    <Loader />
  ) : (
    <Navigate
      to={
        reason ? `${path}?${new URLSearchParams({ reason }).toString()}` : path
      }
    />
  );
}

export default LandingPage;
