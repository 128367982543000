import React from "react";
import ReactApexChart from "react-apexcharts";

function InvoiceChart() {
  const series = [
    {
      name: "Average Daily Bill",
      data: [120, 140, 110, 160, 130, 150, 170],
    },
  ];

  const options = {
    chart: {
      type: "line",
      height: 350,
    },
    xaxis: {
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "sat"],
      title: {
        text: "WeekDay",
      },
    },
    yaxis: {
      title: {
        text: "Bill Amount($)",
      },
    },
    dataLabels: {
      enabled: true,
    },
    colors: ["#000000"],
  };

  return (
    <div>
      <h2 className="text-lg">129 Doller(Average)</h2>
      <ReactApexChart
        options={options}
        series={series}
        type="line"
        height={350}
      />
    </div>
  );
}

export default InvoiceChart;
