import { Divider } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import CrudAcademyLogo from "../../assets/svg/Crud-Academy-logo2.svg";
import Header from "../../components/common/Header";

function Payslip() {

  return (
    <div>
      <Header title="Payslip" />
      <Content className="bg-white m-6 rounded-lg p-8">
        <div className="text-center p-5 font-medium">
          <u>PAYSLIP FOR THE MONTH OF JUNE 2023</u>
        </div>
        <div className="flex justify-between">
          <div className="p-5">
            <p className="text-gray-400 font-medium">Payslip No</p>
            <p className="font-bold">456787</p>
          </div>
          <div className="p-5 text-right">
            <p className="text-gray-400 font-medium">Payment Date</p>
            <p className="font-bold">Jul 02, 2022 - 12:30 am</p>
          </div>
        </div>
        <Divider />
        <div className="flex justify-between m-6">
          <div>
            <img src={CrudAcademyLogo} alt="logo" className="w-20" />
            <span className="font-bold">CRUD ACADEMY</span>
            <address className="text-left">
              D 103, Kuber Solutions, <br />
              Opp. Town Hall,
              <br />
              Sardar Patel Road,
              <br />
              Ahmedabad - 380015
              <br />
              <span className="text-blue-500"> test@gmail.com</span>
            </address>
          </div>
          <div>
            <div className="font-medium text-right text-gray-400 ">
              Payment To <br /> <span className="text-black">Jhon</span>
            </div>
            <address className="text-right">
              {" "}
              207, Prem Sagar Appt.,
              <br />
              Near Income Tax Office,
              <br />
              Ashram Road,
              <br />
              Ahmedabad - 380057
              <br />
              <span className="text-blue-500"> jhon@gmail.com</span>
            </address>
          </div>
        </div>
        <div className="flex">
          <div className="w-2/4 p-8">
            <h2 className="font-bold p-4">Earnings</h2>
            <div className="border border-solid border-grey-500 p-3 flex justify-between">
              <div className="font-bold">Basic</div>
              <span>$8,568</span>
            </div>
            <div className="border border-solid border-grey-500 p-3 flex justify-between">
              <div className="font-bold">HRA</div>
              <span>$800</span>
            </div>
            <div className="border border-solid border-grey-500 p-3 flex justify-between">
              <div className="font-bold">DA</div>
              <span>$75</span>
            </div>
            <div className="border border-solid border-grey-500 p-3 flex justify-between">
              <div className="font-bold">Special Allowance</div>
              <span>$8907</span>
            </div>
            <div className="border border-solid border-grey-500 p-3 flex justify-between font-bold">
              <div>Total Earnings</div>
              <span>$89078</span>
            </div>
          </div>
          <div className="w-2/4 p-8">
            <h2 className="font-bold p-4">Deductions</h2>
            <div className="border border-solid border-grey-500 p-3 flex justify-between">
              <div className="font-bold">Provident fund</div>
              <span>$8,568</span>
            </div>
            <div className="border border-solid border-grey-500 p-3 flex justify-between">
              <div className="font-bold">Professional Tax</div>
              <span>$800</span>
            </div>
            <div className="border border-solid border-grey-500 p-3 flex justify-between">
              <div className="font-bold">ESI</div>
              <span>$75</span>
            </div>
            <div className="border border-solid border-grey-500 p-3 flex justify-between">
              <div className="font-bold">Home Loan</div>
              <span>$8907</span>
            </div>
            <div className="border border-solid border-grey-500 p-3 flex justify-between font-bold">
              <div>Total Deductions</div>
              <span>$89078</span>
            </div>
          </div>
        </div>
        <div className="flex justify-between p-5">
          <div className="p-5">
            <p className="font-bold">Net Pay: 8,550.00</p>
            <p>Eight Thousand Five Hundred Fifty Two Only</p>
            <div />
          </div>
          <div className="p-5">
            <p className="font-bold">For Priya</p>
            <p>Authorised Signatory</p>
            <div />
          </div>
        </div>
        <div className="text-center">
          <button
            className="bg-blue-500 text-white p-2 rounded-lg"
          >
            Print the Receipt
          </button>
        </div>
      </Content>
    </div>
  );
}

export default Payslip;
