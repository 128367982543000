import React from "react";
import { Card } from "antd";
import Datatable from "../../../../components/common/Datatable";
import InvoiceChart from "./InvoiceChart";
import Header from "../../../../components/common/Header";

function Invoice() {
  const renderStatus = (_, { status }) => {
    if (status === "Paid") {
      return (
        <p className="text-green-900 bg-green-100 border-0 w-14 border-emerald-600 rounded-md text-center">
          {status}
        </p>
      );
    } else if (status === "Partially paid") {
      return (
        <p className="text-orange-400 bg-orange-100 border-0 w-14 border-orange-600 rounded-md text-center px-0">
          {status}
        </p>
      );
    } else {
      return (
        <p className="text-red-500 bg-red-100 border-0 w-14 border-rose-600 rounded-md text-center">
          {status}
        </p>
      );
    }
  };

  const data = [
    {
      key: 1,
      invoiceNo: "INV001",
      clientName: "John Doe",
      dueDate: "2023-12-31",
      status: "Paid",
      total: "$500.00",
    },
    {
      key: 2,
      invoiceNo: "INV002",
      clientName: "Jane Smith",
      dueDate: "2023-12-15",
      status: "Unpaid",
      total: "$300.50",
    },
    {
      key: 3,
      invoiceNo: "INV002",
      clientName: "Jane Smith",
      dueDate: "2023-12-15",
      status: "Partially paid",
      total: "$300.50",
    },
    {
      key: 4,
      invoiceNo: "INV002",
      clientName: "Jane Smith",
      dueDate: "2023-12-15",
      status: "Unpaid",
      total: "$300.50",
    },
  ];

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "invoiceNo",
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: renderStatus,
    },
    {
      title: "Total",
      dataIndex: "total",
    },
  ];
  return (
    <div className="flex justify-evenly gap-5">
      <Card className="w-3/4 bg-white min-h-50px relative mb-5 border border-solid border-f2f4f9 rounded-10px">
        <Header title="Invoices" />
        <section className="mt-10 customizedTable">
          <Datatable tableData={data} columns={columns} title="Invoices" />
        </section>
      </Card>
      <Card
        title="Average Daily Bill"
        className="w-2/5 bg-white min-h-50px relative mb-5 border border-solid border-f2f4f9 rounded-10px"
      >
        <InvoiceChart />
      </Card>
    </div>
  );
}

export default Invoice;
