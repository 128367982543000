import { Button, Divider, Table } from "antd";
import React, { useRef } from "react";
import CrudOperations from "../../assets/svg/Crud-Operation.png";
import { Content } from "antd/es/layout/layout";
import { DownloadOutlined, LeftOutlined } from "@ant-design/icons";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { useGetClientsQuery } from "../../service/clientService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const fixedColumns = [
  {
    title: "Account Details",
    dataIndex: "accountDetails",
  },
  {
    title: "",
    dataIndex: "details",
  },
];

const fixedData = [];
for (let i = 0; i < 4; i += 1) {
  fixedData.push({
    key: i,
    accountDetails: [
      "Bank Name",
      "Account Name",
      "Account Number",
      "IFSC Code",
    ][i % 4],
    details: [
      "ICICI BANK LIMITED",
      "CRUD OPERATIONS PRIVATE LIMITED",
      "321805001151",
      "ICIC0003218",
    ][i % 4],
  });
}

function Invoice() {
  const location = useLocation();
  const navigate = useNavigate();
  const invoiceContainerRef = useRef();
  const record = location.state?.record || null;
  const { data: clients } = useGetClientsQuery();
  const selectedClient = clients?.find(
    (client) => client.name === record.clientName
  );

  const columns = [
    { title: "No", dataIndex: "invoiceNumber" },
    {
      title: "Description",
      dataIndex: "description",
      className:
        "overflow-hidden overflow-ellipsis whitespace-wrap max-w-[250px]",
    },
    { title: "ProjectName", dataIndex: "projectName" },
    { title: "Amount", dataIndex: "amount" },
    { title: "GST", dataIndex: "gst" },
    {
      title: "Total",
      dataIndex: "totalAmount",
      render: (text) => <span className="font-bold">{text}</span>,
    },
  ];

  const handlePrint = () => {
    const width = 180;
    const height = 200;
    html2canvas(invoiceContainerRef.current, {
      width1: width,
      height1: height,
      scrollY: -window.scrollY,
      scrollX: -window.scrollX,
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: [width, height],
      });
      pdf.addImage(imgData, "PNG", 0, 0, width, height);
      pdf.save("invoice.pdf");
    });
  };

  return (
    <div>
      <Button
        type="link"
        className="text-black hover:!text-black flex items-center p-0 border-0"
        onClick={() => navigate(-1)}
      >
        <LeftOutlined />
        <span className="text-base">Back</span>
      </Button>
      <Content className="bg-white mt-10 mx-0" ref={invoiceContainerRef}>
        <div className="flex justify-between items-center p-8">
          <img src={CrudOperations} alt="logo" className="w-40" />
          <div className="text-right">
            <h2 className="font-bold text-sm">
              CRUD-OPERATIONS PRIVATE LIMITED
            </h2>
            <div className="flex justify-end" >
            <p  className="w-2/5 ">{record.location}</p>
            </div>
          </div>
        </div>
        <Divider></Divider>
        <div className="flex justify-between m-10">
          <div>
            <h2 className="font-bold">BILLED TO:</h2>
            <p className="font-medium">Name: {record.clientName}</p>
            {selectedClient && (
              <>
                <p>Phone No: {selectedClient.phone}</p>
                <p className="text-blue-500">Email: {selectedClient.email}</p>
                <address>Address: {selectedClient.address}</address>
              </>
            )}
          </div>
          <div className="text-right">
            <p> Invoice No: {record.invoiceNumber}</p>
            <p> IssueDate: {moment(record.createdAt).format("YYYY-MM-DD")}</p>
            <p> DueDate: {moment(record.dueDate).format("YYYY-MM-DD")}</p>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={[
            {
              invoiceNumber: record.invoiceNumber,
              description: record.description,
              projectName: record.projectName,
              amount: record?.currency + " " + parseFloat(record.amount).toLocaleString(),
              gst: record.gstRate,
              totalAmount:
              (
                record.amount + (record.amount * record.gstRate) / 100
              ).toLocaleString(),
            },
          ]}
          pagination={false}
          bordered
          className="m-10"
        />
        <div className="flex flex-col m-10 gap-10">
          <div className="w-full">
            <Table
              columns={fixedColumns}
              dataSource={fixedData}
              pagination={false}
              bordered
            />
          </div>
          {record.terms && (
            <div className="w-full mx-auto">
              <h2 className="text-xl font-medium mb-4">Terms & Conditions</h2>
              <p className="border p-4 resize-none">{record.terms}</p>
            </div>
          )}
        </div>
        <h1 className="font-bold text-2xl text-center">Thank You!</h1>
        <div className="text-right m-10">
          <h2 className="font-medium text-sm">
            For CRUD-OPERATIONS PVT LIMITED
          </h2>
          <p className="my-8">Authorised Signatory</p>
        </div>
        <Divider />
        <div className="text-right m-10 pb-6">
          <button
            className="bg-blue-800 text-white p-2 rounded-md font-bold text-lg"
            data-html2canvas-ignore="true"
            onClick={handlePrint}
          >
            <DownloadOutlined className="text-white font-bold" /> Download
          </button>
        </div>
      </Content>
    </div>
  );
}

export default Invoice;
