import { createSlice } from "@reduxjs/toolkit";
import { baseService } from "../service/baseService";

const initialState = { isLoading: false, permission: null, user: {} };

const { me: userDetails } = baseService.endpoints;

const baseReducer = createSlice({
  name: "base",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(userDetails.matchFulfilled, (state, action) => {
        const { permission, ...user } = action.payload.data;
        return { ...state, user, permission, isLoading: false };
      })
      .addMatcher(userDetails.matchPending, (state) => {
        return { ...state, isLoading: true };
      })
      .addMatcher(userDetails.matchRejected, (state) => {
        return { ...state, isLoading: false };
      });
  },
});

export default baseReducer.reducer;
