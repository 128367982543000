import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const departmentService = createApi({
  reducerPath: "departmentService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["DEPARTMENT"],
  endpoints: (builder) => ({
    getDepartment: builder.query({
      query: () => ENDPOINTS_CONSTANTS.DEPARTMENT,
      providesTags: ["DEPARTMENT"],
      transformResponse: (res) =>
        res.department.map((values) => ({ ...values, key: values._id })),
    }),
  }),
});

export const { useGetDepartmentQuery } = departmentService;
