import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import ErrorRoute from "../../components/common/ErrorRoute";
import Loader from "../../components/common/Loader";
import { LOCAL_CONSTANTS } from "../../constants/localConstants";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import MainLayout from "../../layout/MainLayout";
import { useMeMutation } from "../../service/baseService";
import { getLocalStorage } from "../localHelpers";

function PrivateRoute() {
  const [getUserDetails] = useMeMutation();
  const permission = useSelector((state) => state.application.permission);
  const isLoading = useSelector((state) => state.application.isLoading);
  const { pathname } = useLocation();
  const token = getLocalStorage(LOCAL_CONSTANTS.ACCESS);
  const isAuth = token;

  const isPermit =
    isAuth &&
    permission &&
    permission.some((doc) =>
      typeof doc.path === "string"
        ? doc.path === pathname || doc.path === `/${pathname.split("/")[1]}`
        : Array.isArray(doc.path)
        ? doc.path.some((value) => pathname.startsWith(value))
        : false
    );

  const fetchUserDetails = () => {
    getUserDetails();
  };

  useEffect(() => {
    if (!isLoading && !permission && token) {
      fetchUserDetails();
    }
  }, [isLoading, permission]);

  if (!isAuth) {
    return <Navigate to={ROUTE_CONSTANTS.HOME} />;
  }

  return isLoading ? (
    <Loader />
  ) : isPermit ? (
    <MainLayout>
      <Outlet />
    </MainLayout>
  ) : (
    <ErrorRoute />
  );
}

export default PrivateRoute;
