import React from "react";
import { Space } from "antd";
import { StarFilled, StarOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
function Ratings({ rating, ratingOutOff, totalRatings }) {
  return (
    <Space.Compact>
      <span>{rating}</span>
      {new Array(rating).fill("_").map((_, index) => (
        <StarFilled className="text-amber-500 text-base" key={index} />
      ))}
      {new Array(ratingOutOff - rating).fill("_").map((_, index) => (
        <StarOutlined className="text-amber-500 text-base" key={index} />
      ))}
      <span>{`(${totalRatings.toLocaleString()}) ratings`}</span>
    </Space.Compact>
  );
}
Ratings.propTypes = {
  rating: PropTypes.number.isRequired,
  ratingOutOff: PropTypes.number.isRequired,
  totalRatings: PropTypes.number.isRequired,
};
export default Ratings;
