import React from "react";
import { Breadcrumb } from "antd";
import PropsType from "prop-types";

function Header({ crumbs, title }) {
  return (
    <header className="flex justify-between items-center ">
      <h1 className="text-xl font-semibold">{title}</h1>
      <Breadcrumb className="text-lg" separator=">" items={crumbs} />
    </header>
  );
}
Header.propTypes = {
  crumbs: PropsType.array,
  title: PropsType.string,
};
export default Header;
