import React from "react";
import { Button, Table, Tag, Avatar, Progress } from "antd";
function EmployeeTasks() {
  const Pagination = {
    pageSize: 10,
  };
  const tasksData = [
    {
      id: 1,
      user: { id: 101, name: "John Doe" },
      task: " Project A",
      status: "In Progress",
      manager: "Jane Smith",
      progress: 60,
    },
    {
      id: 2,
      user: { id: 121, name: "John Doe" },
      task: " Project B",
      status: "In Progress",
      manager: "Jane Smith",
      progress: 60,
    },
  ];
  const tasksColumns = [
    {
      title: <span>User</span>,
      dataIndex: "user",
      key: "user",
      render: (user) => <Avatar>{user.id}</Avatar>,
      width: 100,
    },
    {
      title: <span>Task</span>,
      dataIndex: "task",
      key: "task",
      width: 150,
    },
    {
      title: <span>Status</span>,
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "In Progress" ? "blue" : "green"}>{status}</Tag>
      ),
      width: 100,
    },
    {
      title: <span>Manager</span>,
      dataIndex: "manager",
      key: "manager",
      width: 150,
    },
    {
      title: <span>Progress</span>,
      dataIndex: "progress",
      key: "progress",
      render: (text) => (
        <Progress className="m-0" percent={text} showInfo={false} />
      ),
      width: 100,
    },
    {
      title: <span>Details</span>,
      dataIndex: "details",
      key: "details",
      render: () => <Button>Details</Button>,
      width: 100,
    },
  ];
  return (
    <div className="flex flex-col w-full lg:w-full ml-0 lg:ml-4 mt-4 lg:mt-0">
      <div className="bg-white rounded-lg shadow-lg mb-4 lg:mb-0 mt-0 p-4">
        <h1 className="text-lg text-gray-400 p-2 font-bold mb-4">My Tasks</h1>
        <div>
          <Table
            dataSource={tasksData}
            columns={tasksColumns}
            pagination={Pagination}
            scroll={{ x: true }}
          />
        </div>
      </div>
    </div>
  );
}

export default EmployeeTasks;
