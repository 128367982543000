import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal, message, Form } from "antd";
import MyPermissionForm from "./MyPermissionForm";
import dayjs from "dayjs";
import { usePostMyPermissionMutation } from "../../service/MyPermissionService";

const MyPermissionModal = ({
  visible,
  onOk,
  onCancel,
  title,
  onEdit,
  editModalData,
  editModal,
}) => {
  const [form] = Form.useForm();
  const [postMyPermission] = usePostMyPermissionMutation();
  const customFormat = (value) => (value ? value.format("DD-MM-YYYY") : "");

  useEffect(() => {
    if (editModalData && editModal) {
      const { date, noOfHours, reason } = editModalData;
      form.setFieldsValue({
        date: dayjs(date),
        noOfHours,
        reason,
      });
    }
  }, [editModalData, editModal]);

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (editModalData) {
          onEdit(values);
        } else {
          postMyPermission(values)
            .unwrap()
            .then(() => {
              message.success("Permission Requested successfully");
              form.resetFields();
              onOk();
            })
            .catch((errorResponse) => {
              const errorMessage = errorResponse?.data?.error
                ? errorResponse.data.error
                : "Something went wrong";
              message.error(errorMessage);
            });
        }
      })
      .catch(() => {
        message.error("Please Enter all fields");
      });
  };

  const footerContent = (
    <div className="flex">
      <Button className="w-1/2" size="large" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        className="bg-blue-500 w-1/2"
        onClick={handleOk}
      >
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title={title}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footerContent}
    >
      <MyPermissionForm form={form} customFormat={customFormat} />
    </Modal>
  );
};

MyPermissionModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  onEdit: PropTypes.func,
  editModalData: PropTypes.object,
  editModal: PropTypes.bool,
  handleDatePickerChange: PropTypes.func,
  EMPID: PropTypes.string,
};

export default MyPermissionModal;
