import React from "react";
import { Dropdown, message } from "antd";
import { MoreOutlined, LikeFilled, DislikeFilled } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useUpdateTaskStatusMutation } from "../../service/timeSheetService";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useParams } from "react-router-dom";

function DropdownforTimeSheet({ openTaskModal, task, passingId, isPermit }) {
  const { id } = useParams();
  const user = useSelector((state) => state.application.user);
  const [updateTask] = useUpdateTaskStatusMutation();
  const handleApproval = (task) => {
    const update = {
      id: [task._id],
      isApproved: !task.isApproved,
      userId: id,
    };
    updateTask(update)
      .then(() => {
        message.success("Task updated Successfully");
      })
      .catch((error) => {
        message.error("Something went wrong");
        console.log(error); //eslint-disable-line
      });
  };
  return (
    <div>
      {user?.role !== "admin" && !isPermit ? (
        <Dropdown
          menu={{
            items: [
              {
                label: "Edit",
                key: "0",
                icon: <FontAwesomeIcon icon={faPenToSquare} />,
                onClick: () => openTaskModal(task),
                disabled: task.isApproved,
              },
              {
                label: "Delete",
                key: "1",
                icon: <FontAwesomeIcon icon={faTrashCan} />,
                onClick: () => passingId(task._id),
                disabled: task.isApproved,
              },
            ],
          }}
          trigger={["click"]}
          className="align-middle"
        >
          <MoreOutlined className="text-lg" />
        </Dropdown>
      ) : (
        <Dropdown
          menu={{
            items: [
              {
                label: "Approve",
                key: "0",
                icon: <LikeFilled />,
                onClick: () => handleApproval(task),
                disabled: task.isApproved,
              },
              {
                label: "Deny",
                key: "1",
                icon: <DislikeFilled />,
                onClick: () => handleApproval(task),
                disabled: !task.isApproved,
              },
            ],
          }}
          trigger={"click"}
          className="align-middle"
        >
          <MoreOutlined className="text-lg" />
        </Dropdown>
      )}
    </div>
  );
}

DropdownforTimeSheet.propTypes = {
  openTaskModal: PropTypes.func.isRequired,
  passingId: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
  isPermit: PropTypes.bool,
};

export default DropdownforTimeSheet;
