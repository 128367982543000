import React, { useState, useEffect } from "react";
import { Select, DatePicker } from "antd";
import PropTypes from "prop-types";

const { RangePicker } = DatePicker;
const { Option } = Select;

const FilterPayroll = ({ data, onFilter }) => {
  const [selectedOption, setSelectedOption] = useState("lastMonth");

  const handleDropdownChange = (value) => {
    setSelectedOption(value);
    filterData(value);
  };

  const handleCustomDateChange = (dates) => {
    filterData(selectedOption, dates);
  };

  const filterDataByDateRange = (startDate, endDate, months) => {
    const filteredData = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const formattedDate = `${currentDate.getMonth() + 1}-${currentDate.getFullYear()}`;
      const monthData = data.find((item) => item.date === formattedDate);
      
      if (monthData) {
        filteredData.push(monthData);
      }

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return months ? filteredData.slice(-months) : filteredData;
  };

  const filterData = (value, dates) => {
    let filteredData = [];
    let months;
    
    switch (value) {
      case "lastMonth":
        months = 1;
        break;
  
      case "lastThreeMonth":
        months = 3;
        break;
  
      case "lastSixMonth":
        months = 6;
        break;
  
      case "customMonth":
        if (dates) {
          const [startDate, endDate] = dates.map((date) => date.toDate());
          filteredData = filterDataByDateRange(startDate, endDate);
        }
        break;
  
      default:
        break;
    }
  
    if (months) {
      // Calculate start date based on the selected option
      const startDate = new Date();
      startDate.setMonth(startDate.getMonth() - months + 1);
  
      filteredData = filterDataByDateRange(startDate, new Date(), months);
    }
  
    onFilter(filteredData);
  };

  useEffect(() => {
    filterData("lastMonth");
  }, []);

  return (
    <div className="flex">
      <Select
        className="w-48 pl-5"
        placeholder="Select an option"
        onChange={handleDropdownChange}
        value={selectedOption}
      >
        <Option value="lastMonth">Latest</Option>
        <Option value="lastThreeMonth">Last Three Months</Option>
        <Option value="lastSixMonth">Last Six Months</Option>
        <Option value="customMonth">Custom Year</Option>
      </Select>
      {selectedOption === "customMonth" && (
        <div className="pl-3">
          <RangePicker
            picker="month"
            format={"MM-YYYY"}
            onChange={handleCustomDateChange}
          />
        </div>
      )}
    </div>
  );
};

FilterPayroll.propTypes = {
  data: PropTypes.array,
  onFilter: PropTypes.func,
};

export default FilterPayroll;
