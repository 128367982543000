import React, { useState } from "react";
import Header from "../../components/common/Header";
import { Space, Button, message } from "antd";
import ModelForAddEdit from "./ModalForAddEdit";
import DeleteModal from "../../components/common/DeleteModal";
import {
  useDeleteTaskMutation,
  usePostTaskMutation,
  useUpdateTaskMutation,
} from "../../service/timeSheetService";
import TimeSheetTasks from "../../components/common/TimeSheetTasks";
import { useSelector } from "react-redux";
function TimeSheet() {
  const [taskState, setTaskState] = useState({
    isModalOpen: false,
    selectedTaskData: null,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const user = useSelector((state) => state.application.user);
  const userObjectId = user?._id;
  const [addTask] = usePostTaskMutation();
  const [updateTask] = useUpdateTaskMutation();
  const [deleteTask] = useDeleteTaskMutation();
  const [inputDisable, setInputDisable] = useState(false);

  const openTaskModal = (task) => {
    setTaskState({
      selectedTaskData: task,
      isModalOpen: true,
    });
  };

  const handleAdd = async (values, timeRange) => {
    try {
      const [startTime, endTime] = timeRange;
      const response = await addTask({
        ...values,
        startTime: startTime.$d,
        endTime: endTime.$d,
        empId: userObjectId,
      });
      response && response.data
        ? message.success(response.data.message)
        : message.warning(response.error.data.message);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(`Error: ${error.response.data.message}`);
      } else {
        message.error("Error adding task. Please try again.");
      }
    }
  };

  const handleEdit = async (values, startTime, endTime, id) => {
    try {
      const updatedData = {
        ...values,
        startTime,
        endTime,
        id,
        empId: userObjectId,
      };
      const response = await updateTask(updatedData);
      response && response.data
        ? message.success(response.data.message)
        : message.warning(response.error.data.message);
      setInputDisable(false);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(`Error: ${error.response.data.message}`);
      } else {
        message.error("Error updating task. Please try again.");
      }
    }
  };

  const openModal = () => {
    setTaskState({ ...taskState, isModalOpen: true });
  };
  const closeModal = () => {
    setTaskState({ isModalOpen: false, selectedTaskData: null });
  };

  const [id, setId] = useState("");
  const passingId = (id) => {
    setDeleteModal(true);
    setId(id);
  };
  const handleDelete = async () => {
    try {
      const response = deleteTask(id);
      setDeleteModal(false);
      if (response.data && response.data.message) {
        message.success(response.data.message);
      } else {
        message.success("Task deleted successfully");
      }
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        message.error(`Error: ${error.response.data.message}`);
      } else {
        message.error("Error deleting task. Please try again.");
      }
    }
    setDeleteModal(false);
  };

  return (
    <div>
      <Header title="Time Sheet" />
      <Space
        className="bg-white mt-7 p-6 rounded-lg flex"
        direction="vertical"
        size="large"
      >
        <div className="flex items-end justify-between">
          <p className="font-semibold text-xl">Activities</p>
          <Button
            type="primary"
            className="bg-[#1677FF] hover:bg-blue-800 float-right"
            size="large"
            onClick={openModal}
          >
            Add New Entry
          </Button>
          <ModelForAddEdit
            open={taskState.isModalOpen}
            onEdit={handleEdit}
            onSave={handleAdd}
            onCancel={closeModal}
            selectedData={taskState.selectedTaskData}
            inputDisable={inputDisable}
            setInputDisable={setInputDisable}
            userObjectId={userObjectId}
          />
        </div>
        <TimeSheetTasks
          userId={userObjectId}
          openTaskModal={openTaskModal}
          setDeleteModal={setDeleteModal}
          passingId={passingId}
        />
      </Space>
      <DeleteModal
        title="Are you sure"
        message="You want to delete this task"
        visible={deleteModal}
        onOk={handleDelete}
        onCancel={() => setDeleteModal(false)}
      />
    </div>
  );
}

export default TimeSheet;
