import React from "react";
import PropsType from "prop-types";

function DashboardCardTitle({ title }) {
  return (
    <header className="flex justify-between items-center ">
      <h1 className="text-base font-semibold">{title}</h1>
    </header>
  );
}
DashboardCardTitle.propTypes = {
  title: PropsType.string,
};
export default DashboardCardTitle;
