import { React, useState } from "react";
import { Modal, Upload, Tooltip, message, Popover } from "antd";
import { InboxOutlined, CheckCircleFilled } from "@ant-design/icons";
import PropTypes from "prop-types";
import { FileFilled, ClockCircleOutlined } from "@ant-design/icons";
import { Progress, Tabs } from "antd";
import moment from "moment";
import { useUploadFileMutation } from "../../service/baseService";
import { useUpdateProjectMutation } from "../../service/projectService";
import excelLogo from "../../assets/images/excel.png";
import imageLogo from "../../assets/images/imageLogo.png";
import pdfLogo from "../../assets/images/pdfLogo.png";
import docLogo from "../../assets/images/pdfLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import FileMenu from "./FileMenu";

const { TabPane } = Tabs;
const { Dragger } = Upload;
const Previewmodal = ({ project, visible, onCancel, user }) => {
  const [uploadFile] = useUploadFileMutation();
  const [updateProject] = useUpdateProjectMutation();
  const [openIndex, setOpenIndex] = useState(false);

  const handleUpload = (formData, type, attachment) => {
    if (attachment?.length < 3) {
      uploadFile(formData)
        .unwrap()
        .then((response) => {
          const payload = {
            attachment: [...attachment, { ...response, type }],
            id: project?._id,
          };
          updateProject(payload)
            .then(() => {
              message.success("Uploaded");
            })
            .catch((error) => {
              const mes = error?.message
                ? error.message
                : "Something went wrong";
              message.error(mes);
            });
        })
        .catch((error) => {
          const mes = error?.message ? error.message : "Something went wrong";
          message.error(mes);
        });
    } else {
      message.error("You have reached your maximum limt");
    }
  };

  const props = {
    multiple: false,
    beforeUpload: () => {
      return false;
    },
    onChange: ({ file }) => {
      const formData = new FormData();
      formData.append("file", file);
      handleUpload(formData, file.type, project.attachment);
    },
  };

  const modalStyle = {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: "fixed",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const [activeTab, setActiveTab] = useState("overview");

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const getImage = (fileName) => {
    let defaultAsset = docLogo;
    if (typeof fileName === "string") {
      if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
        defaultAsset = imageLogo;
      } else if (fileName.match(/\.(xlsx|csv)$/i)) {
        defaultAsset = excelLogo;
      } else if (fileName.match(/\.(pdf)$/i)) {
        defaultAsset = pdfLogo;
      }
    }
    return defaultAsset;
  };

  const handleOpenChange = (index) => {
    setOpenIndex((prev) => (prev === index ? null : index));
  };

  return (
    <Modal
      className="preview"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      style={modalStyle}
    >
      <div className={"contentStyle"}>
        {project && (
          <div className="border rounded">
            <div className="h-20">
              <div className="bg-gradient-to-r from-gray-400 to-gray-200 h-full"></div>
              <div className="mx-2 mt-[-20px] ">
                <FileFilled className=" text-4xl text-gray-600 hidden" />
              </div>
            </div>
            <div className="flex items-center mt-0">
              <div className="w-1/2 p-4 mt-0">
                <div className="flex items-center">
                  <h2 className="text-xl font-bold mr-2">{project.title}</h2>
                  <div>
                    <ClockCircleOutlined className="text-gray-500 mr-2" />
                    In Progress
                  </div>
                </div>
              </div>
              <div className="w-1/2 flex items-center justify-end mx-4">
                {project.team?.map((teamMember, index) => (
                  <div
                    key={index}
                    className="w-8 h-8 bg-blue-500 text-white flex items-center justify-center rounded-full mx-1"
                  >
                    <Tooltip placement="top" title={teamMember?.firstName}>
                      {teamMember?.firstName?.charAt(0)}
                    </Tooltip>
                  </div>
                ))}
                {project.team?.length > 4 && (
                  <span className="text-black text-xl font-light mb-2 ml-2">
                    +{project.team.length - 4}
                  </span>
                )}
              </div>
            </div>
            <div className="flex  -mt-1 ml-4">
              <div className="flex-row">
                <div className="inline-block mr-4">
                  Start: {moment(project.created).format("DD-MM-YYYY")}
                </div>
                <div className="ml-4 inline-block">
                  End: {moment(project.deadline).format("DD-MM-YYYY")}
                </div>
              </div>
            </div>
            <div className="flex items-center -mt-3">
              <div className="w-full p-4">
                <Progress percent={project.projectProgress} />
              </div>
            </div>
          </div>
        )}
        <Tabs activeKey={activeTab} onChange={handleTabChange}>
          <TabPane tab={<span>Overview</span>} key="overview">
            {project && (
              <div>
                <div>
                  <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                  <p>{project.description}</p>
                </div>
                <div className="py-4 flex flex-wrap gap-4">
                  {project.attachment?.length > 0 &&
                    project.attachment.map((doc, index) => {
                      return (
                        <div
                          className="rounded-lg flex p-3 bg-gray-200 gap-2"
                          key={index}
                        >
                          <div className="p-2 bg-white rounded">
                            <img
                              width="25px"
                              height="25px"
                              alt="asset"
                              src={getImage(doc?.key)}
                            />
                          </div>
                          <div>
                            <div className="flex items-center">
                              <p className="font-bold">{doc?.key}</p>
                              <Popover
                                content={
                                  <FileMenu
                                    fileName={doc?.key}
                                    url={doc?.url}
                                    setOpenIndex={setOpenIndex}
                                    projectId={project?._id}
                                  />
                                }
                                trigger="click"
                                placement="bottom"
                                open={openIndex === index}
                                onOpenChange={() => handleOpenChange(index)}
                              >
                                <span className="w-[20px] cursor-pointer text-center">
                                  <FontAwesomeIcon icon={faEllipsisV} />
                                </span>
                              </Popover>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
          </TabPane>
          <TabPane tab={<span>Clients</span>} key="clients">
            {project?.client_data && (
              <div className={"overflow-auto max-h-80"}>
                <h3 className="text-xl font-bold mb-2">Clients</h3>
                <div className="flex justify-between items-center space-y-2">
                  <div className="mr-3 w-9 h-9 bg-blue-500 text-white flex items-center justify-center rounded-full mx-1">
                    {project?.client_data.name.charAt(0)}
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-600 font-medium text-base">
                      Name
                    </span>
                    <span className="text-black font-light text-base">
                      {project?.client_data.name}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-600 font-medium text-base">
                      Website
                    </span>
                    <span className="text-black font-light text-base">
                      {project?.client_data.website}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-600 font-medium text-base">
                      Email
                    </span>
                    <span className="text-black font-light text-base">
                      {project?.client_data.email}
                    </span>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-gray-600 font-medium text-base">
                      Phone
                    </span>
                    <span className="text-black font-light text-base">
                      {project?.client_data.phone}
                    </span>
                  </div>
                </div>
                <hr className="my-4" />
                <h3 className="text-xl font-bold mb-2">Associates</h3>
                {project?.client_data &&
                  project?.client_data?.associates.map((item, index) => {
                    const assigned =
                      item?._id === project?.selectedAssociateId
                        ? "text-green-600"
                        : null;
                    return (
                      <div key={index} className="flex items-center space-y-2">
                        <div
                          key={index}
                          className="mr-2 p-2 w-9 h-9 bg-blue-500 text-white flex items-center justify-center rounded-full "
                        >
                          {item?.name.charAt(0)}
                        </div>
                        <div className="flex flex-col w-1/4">
                          <span className="text-gray-600 font-medium text-base">
                            Name
                          </span>
                          <span className="text-black font-light text-base">
                            {item?.name}
                          </span>
                        </div>
                        <div className="flex flex-col w-1/3">
                          <span className="text-gray-600 font-medium text-base">
                            Email
                          </span>
                          <span className="text-black font-light text-base">
                            {item?.email}
                          </span>
                        </div>
                        <div className="flex flex-col w-1/4">
                          <span className="text-gray-600 font-medium text-base">
                            Contact
                          </span>
                          <span className="text-black font-light text-base">
                            {item?.phone}
                          </span>
                        </div>
                        <div className="flex flex-col w-1/4">
                          <span className="text-gray-600 font-medium text-base">
                            Assign Status
                          </span>
                          <span className={`${assigned}`}>
                            Assigned
                            {assigned ? (
                              <CheckCircleFilled className="ml-1" />
                            ) : null}
                          </span>
                        </div>
                      </div>
                    );
                  })}
              </div>
            )}
          </TabPane>
          {user.role === "admin" ? (
            <TabPane tab={<span>Files</span>} key="files">
              <div className="h-full">
                <Dragger {...props}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibited
                    from uploading company data or other banned files.
                  </p>
                </Dragger>
              </div>
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>
      </div>
    </Modal>
  );
};

Previewmodal.propTypes = {
  project: PropTypes.object.isRequired,
  visible: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  user: PropTypes.object,
};
export default Previewmodal;
