import React, { useState, useEffect } from "react";
import { Table, Input, Result } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import Loader from "./Loader";
function Datatable({
  tableData,
  columns,
  title,
  checkBox,
  renderActions,
  isLoading,
  isError,
  size = "medium",
  pagination = {},
}) {
  const [inputValue, setInputValue] = useState("");
  const [filteredData, setFilteredData] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const globalSearch = () => {
    const filtered = tableData?.filter((item) => {
      return Object.values(item).some((value) =>
        value?.toString().toLowerCase().includes(inputValue.toLowerCase())
      );
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    globalSearch();
  }, [inputValue, tableData]);

  const handleSearch = (e) => {
    setInputValue(e.target.value);
  };

  const selectChange = (selectedRowKeys) => {
    setSelectedRowKeys(selectedRowKeys);
  };
  const employeeCount = selectedRowKeys && selectedRowKeys.length > 0;

  const reset = () => {
    setSelectedRowKeys([]);
  };
  return (
    <section>
      {isError ? (
        <Result
          status="warning"
          title="There are some problems with your operation."
        />
      ) : (
        <Table
          columns={columns}
          rowSelection={
            checkBox ? { selectedRowKeys, onChange: selectChange } : false
          }
          dataSource={filteredData}
          loading={isLoading && <Loader />}
          title={() => {
            return title ? (
              <div
                className={`flex bg-[#DAE1F3] items-center justify-between text-[#575a5e] p-3 ${
                  employeeCount ? "bg-gray-300" : "bg-white"
                }`}
              >
                <p className="text-lg font-normal">
                  {selectedRowKeys?.length > 0
                    ? `${selectedRowKeys.length} row selected`
                    : title}
                </p>
                <div className="flex space-x-3">
                  <Input
                    size="large"
                    className="mx-3 "
                    placeholder="Search"
                    prefix={<SearchOutlined />}
                    value={inputValue}
                    onChange={(e) => handleSearch(e)}
                  />
                  {typeof renderActions === "function" &&
                    renderActions(selectedRowKeys, reset)}
                </div>
              </div>
            ) : null;
          }}
          size={size}
          pagination={pagination}
        />
      )}
    </section>
  );
}

Datatable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  checkBox: PropTypes.bool,
  renderActions: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  size: PropTypes.string,
  pagination: PropTypes.any
};

export default Datatable;
