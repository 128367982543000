export const ROUTE_CONSTANTS = {
  HOME: "/",
  LOGIN: "/login",
  DASH: "/dashboard",
  EMPLOYEES: "/employees",
  ATTENDANCE: "/attendance",
  CLIENTS: "/clients",
  MYTEAMS: "/myteams",
  MYLEAVES: "/myleaves",
  ALLPROJECTS: "/projects/allProjects",
  MYTASKS: "/mytasks",
  EMPLOYEEPROFILE: "/employees/:id",
  PAYROLL: "/employee-salary",
  PAYSLIP: "/employee-salary/payslip",
  ALLLEAVEREQUEST: "/leave-management/all-leave-request",
  LEAVEBALANCE: "/leave-management/leave-balance",
  LEAVETYPES: "/leave-management/leave-types",
  ALL_INVOICES: "/invoice",
  INVOICE: "/invoice/invoiceDetails",
  TIMESHEET: "/timesheet",
  PAYSLIPEMP: "/payslip",
  EVENTS: "/events",
  MYACCOUNT: "/myaccount",
  PROFILE: "/clients/profile",
  LEAVE: "/leave",
  LEAVE_BALANCE: "/leave-balance",
  LEAVE_CALENDAR: "/leave-calendar",
  PERMISSION: "/permission",
  APPROVALS_LEAVE: "/approvals/leave",
  APPROVALS_PERMISSION: "/approvals/permission",
  APPROVALS_TIMESHEET: "/approvals/timesheet",
  APPROVALS_TIMESHEET_EMP: "/approvals/timesheet/:id",
  EVENT_CALENDAR: "/calendar",
  HOLIDAYS: "/holidays",
  HOLIDAYS_CALENDAR: "/holidays/calendar",
  ACCESS_DENIED:"/permission-denied",
  APPROVALS_COMPOFF: "/approvals/compoff",
  BLOG:"/blog",
  PORTFOLIO:"/portfolio"
};
