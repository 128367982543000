import React from "react";
import { Content } from "antd/es/layout/layout";
import ReactApexChart from "react-apexcharts";
import DashboardCardTitle from "../../DashboardCardTitle";

function ProjectSurvayChart() {
  const chartHeight = 350;
  const options = {
    chart: {
      height: 350,
      type: "line",
      stacked: false,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#5D26C1", "#fdbb2d", "#FF4554"],
    series: [
      {
        name: "Project A",
        type: "column",
        data: [21.1, 23, 33.1, 34, 44.1, 44.9, 56.5, 58.5],
      },
      {
        name: "Project B",
        type: "column",
        data: [10, 19, 27, 26, 34, 35, 40, 38],
      },
      {
        name: "Project C",
        type: "line",
        data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6],
      },
    ],
    stroke: {
      width: [4, 4, 4],
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    xaxis: {
      categories: [2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016],
    },
    yaxis: [
      {
        seriesName: "Project A",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: "Columns",
        },
      },
      {
        seriesName: "Project A",
        show: false,
      },
      {
        opposite: true,
        seriesName: "Project C",
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        title: {
          text: "Line",
        },
      },
    ],
    tooltip: {
      shared: false,
      intersect: true,
      x: {
        show: false,
      },
    },
    legend: {
      horizontalAlign: "left",
      offsetX: 40,
    },
  };

  return (
    <Content className="bg-white mt-5 p-4 rounded-lg w-2/3">
      <DashboardCardTitle title="Project Survey" />
      <ReactApexChart
        options={options}
        series={options.series}
        type="line"
        height={chartHeight}
      />
    </Content>
  );
}

export default ProjectSurvayChart;
