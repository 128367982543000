import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Form, Input, Select, Button, message } from "antd";
import AddModalBalanceDetail from "./AddModalBalanceDetail";
import { useGetRolesQuery } from "../../service/roleService";
import { useGetAllEmployeesQuery } from "../../service/employeeService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
const AddModal = ({
  visible,
  onSave,
  onCancel,
  onEdit,
  selectedData,
  file,
  setFile,
  isLoading,
  title,
}) => {
  const [form] = Form.useForm();
  const [department, setDepartment] = useState();
  const { data } = useGetAllEmployeesQuery();
  const empList = data && data ? data : [];
  const { data: role } = useGetRolesQuery();
  const roleList = role && role ? role : [];
  const departmentOptions = roleList
    .map((item) => ({
      label: item.department,
      value: item.department,
    }))
    .filter(
      (value, index, self) =>
        self.findIndex(
          (v) => v.label === value.label && v.value === value.value
        ) === index
    );

  const handleDepartmentChange = (values) => {
    setDepartment(values);
  };

  const roles = roleList.filter((item) => item.department === department);

  const handleCancel = () => {
    setDepartment(null);
    form.resetFields();
    onCancel();
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        values.empId = `CRUD${values.empId}`;
        if (selectedData) {
          const updatedData = {
            ...values,
            id: selectedData._id,
          };
          onEdit(updatedData);
        } else {
          const updateValue = {
            ...values,
            resume: file,
          };
          onSave(updateValue);
        }
        handleCancel();
      })
      .catch(() => {
        message.error("Please enter all the fields");
      });
  };

  const footerContent = (
    <div className="w-full flex space-x-2">
      <Form.Item className="w-1/2 mb-0">
        <Button
          key="cancel"
          size="large"
          onClick={handleCancel}
          className="w-full"
        >
          Cancel
        </Button>
      </Form.Item>
      <Form.Item shouldUpdate className="w-1/2 mb-0">
        {() => (
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="bg-blue-600 hover:!bg-blue-600 w-full"
            onClick={handleOk}
            loading={isLoading}
          >
            {selectedData ? "Update" : "Add"}
          </Button>
        )}
      </Form.Item>
    </div>
  );

  useEffect(() => {
    if (selectedData) {
      setDepartment(selectedData.department);
      const { empId, reportingManager } = selectedData;
      const formattedEmpId = empId.startsWith("CRUD")
        ? empId.substring(5)
        : empId;

      const formattedReportingManager = empList.find(
        (employee) => employee._id === reportingManager
      );

      form.setFieldsValue({
        ...selectedData,
        empId: formattedEmpId,
        reportingManager: formattedReportingManager,
      });
    }
  }, [selectedData]);

  return (
    <Modal
      title={title}
      open={visible}
      onCancel={handleCancel}
      footer={footerContent}
      centered
    >
      <Form form={form} layout="vertical">
        {(values, formInstance) => {
          const empId = values?.empId;
          const empIdError = formInstance.getFieldError("empId");
          return (
            <>
              <div className="flex mt-2 w-full">
                <Form.Item
                  className="w-1/2 mr-2"
                  name="firstName"
                  label="First Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your First Name",
                    },
                  ]}
                  normalize={(value) =>
                    value && value.charAt(0).toUpperCase() + value.slice(1)
                  }
                >
                  <Input placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  className="w-1/2 mr-2"
                  name="lastName"
                  label="Last Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Last Name",
                    },
                  ]}
                  normalize={(value) =>
                    value && value.charAt(0).toUpperCase() + value.slice(1)
                  }
                >
                  <Input placeholder="Last Name" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  className={empId ? "w-full mr-2 mb-0 " : "w-full mr-2 "}
                  name="empId"
                  label="Employee Id"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Employee Id",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Employee ID must be numeric.",
                    },
                  ]}
                >
                  <Input
                    placeholder="Employee Id"
                    disabled={selectedData ? true : false}
                  />
                </Form.Item>
                {empId && !selectedData && empIdError.length === 0 && (
                  <div className="text-blue-500 text-xs relative">
                    <FontAwesomeIcon
                      icon={faCircleInfo}
                      className="absolute top-[3px]"
                    />
                    <span className="ml-4">
                      Incorporating the prefix CRUD, your employee ID is now
                      CRUD{empId}.
                    </span>
                  </div>
                )}
              </div>
              <div className="flex w-full">
                <Form.Item
                  className="w-1/2 mr-2"
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Gender",
                    },
                  ]}
                >
                  <Select
                    placeholder="Gender"
                    disabled={selectedData ? true : false}
                    options={[
                      {
                        value: "Male",
                        label: "Male",
                      },
                      {
                        value: "Female",
                        label: "Female",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  className="w-1/2 mr-2"
                  name="email"
                  label="E-mail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please enter your E-mail",
                    },
                  ]}
                >
                  <Input
                    placeholder="Email"
                    disabled={selectedData ? true : false}
                  />
                </Form.Item>
              </div>
              <div className="flex w-full">
                <Form.Item
                  className="w-1/2 mr-2"
                  name="department"
                  label="Department"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Department",
                    },
                  ]}
                >
                  <Select
                    className="w-full"
                    placeholder="Department"
                    disabled={selectedData ? true : false}
                    options={departmentOptions}
                    onChange={handleDepartmentChange}
                  />
                </Form.Item>
                <Form.Item
                  className="w-1/2 mr-2"
                  name="role"
                  label="Designation"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your Designation",
                    },
                  ]}
                >
                  <Select
                    placeholder="Designation"
                    className="w-1/2"
                    options={roles}
                    disabled={!department}
                  />
                </Form.Item>
              </div>
              <AddModalBalanceDetail
                selectedData={selectedData}
                file={file}
                setFile={setFile}
              />
            </>
          );
        }}
      </Form>
    </Modal>
  );
};
AddModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  file: PropTypes.object,
  setFile: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
};
export default AddModal;