import React, { useState } from "react";
import Datatable from "../../components/common/Datatable";
import moment from "moment";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import MyPermissionModal from "./MyPermissionModal";
import DeleteModal from "../../components/common/DeleteModal";
import { Button, message, Tag } from "antd";
import {
  useDeleteMyPermissionMutation,
  useGetMyPermissionQuery,
  usePutMyPermissionMutation,
} from "../../service/MyPermissionService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import IconButton from "../../components/common/IconButton";
import { statusText } from "../../constants/appConstants";
import PropTypes from "prop-types";

const MyPermissionTable = ({ balanceRefetch }) => {
  const { data, refetch, isFetching, isError } = useGetMyPermissionQuery();
  const [deleteMyPermission] = useDeleteMyPermissionMutation();
  const [putMyPermission] = usePutMyPermissionMutation();
  const sortedLeaves = data ? [...data].reverse() : [];
  const [permission, setPermission] = useState({
    isPermissionVisible: false,
    editModal: false,
    editModalData: null,
    deleteModalVisible: false,
    CancelModal: false,
  });

  const handleReload = () => {
    refetch();
    balanceRefetch();
  };
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_, record) => {
        const { date } = record;
        if (date) {
          return moment(date).format("DD-MM-YYYY");
        }
      },
    },
    {
      title: "No of Hours",
      dataIndex: "noOfHours",
      key: "noOfHours",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={
            record.status === 1 ? "green" : record.status === 0 ? "red" : "blue"
          }
        >
          {statusText[text]}
        </Tag>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (_, record) => (
        <div className="space-x-6 flex">
          <IconButton
            icon={
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={() => editButton(record)}
              />
            }
          />
          <IconButton
            icon={
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => deleteButton(record)}
              />
            }
          />
        </div>
      ),
    },
  ];
  const renderActions = (_id) => {
    return (
      <div className="flex space-x-3 items-center">
        <Button
          className="flex justify-center items-center bg-blue-600 !text-white"
          onClick={plus}
        >
          <PlusOutlined /> Add
        </Button>
        <Button
          className="flex items-center  bg-blue-600 !text-white"
          onClick={handleReload}
        >
          <ReloadOutlined className="active:rotate-180 delay-[5000ms] duration-300 ease-in-out" />
          Refresh
        </Button>
        {_id.length > 1 && (
          <Button className="flex items-center  bg-red-600 !text-white">
            <FontAwesomeIcon className="mr-2" icon={faTrashCan} />
            Delete
          </Button>
        )}
      </div>
    );
  };
  const handleOk = () => {
    setPermission((prevState) => ({
      ...prevState,
      isPermissionVisible: false,
      editModal: false,
      editModalData: null,
    }));
  };
  const handleCancel = () => {
    setPermission((prevState) => ({
      ...prevState,
      isPermissionVisible: false,
      deleteModalVisible: false,
      CancelModal: false,
      editModal: false,
      editModalData: null,
    }));
  };
  const onEdit = (values) => {
    const { editModalData } = permission;

    if (editModalData && editModalData._id) {
      putMyPermission({
        id: editModalData._id,
        ...values,
      })
        .then((response) => {
          if (response && response.data) {
            message.success("Permission Updated Successfully");
            handleOk();
          } else {
            message.error("Failed to update permission");
          }
        })
        .catch(() => {
          message.error("Error updating record");
        });
    }
  };

  const deleteButton = (record) => {
    if (record.status === 0) {
      setPermission((prevState) => ({
        ...prevState,
        deleteModalVisible: true,
        deleteRecordId: record._id,
      }));
    } else {
      message.error(
        `This Permission Request is already ${statusText[record.status]}`
      );
    }
  };
  const handleDelete = async () => {
    const { deleteRecordId } = permission;
    setPermission((prevState) => ({
      ...prevState,
      deleteModalVisible: false,
    }));
    if (deleteRecordId) {
      deleteMyPermission({ id: deleteRecordId })
        .then((response) => {
          if (response.success) {
            message.success("Permission deleted Successfully");
          }
        })
        .catch(() => {
          message.error("Error deleting record");
        });
    }
  };
  const editButton = (record) => {
    if (record.status === 0) {
      const editModData = data.find((item) => item._id === record._id);
      setPermission((prevState) => ({
        ...prevState,
        editModalData: editModData,
        isPermissionVisible: true,
        editModal: true,
      }));
    } else {
      message.error(
        `This Permission Request is already ${statusText[record.status]}`
      );
    }
  };

  const plus = () => {
    setPermission((prevState) => ({
      ...prevState,
      isPermissionVisible: true,
      editModal: false,
      editModalData: null,
    }));
  };

  return (
    <section className="customizedTable">
      <Datatable
        tableData={sortedLeaves}
        columns={columns}
        checkBox
        rowSelection
        title="My Permissions"
        renderActions={(selectedRowKeys) => renderActions(selectedRowKeys)}
        isError={isError}
        isLoading={isFetching}
      />
      <MyPermissionModal
        visible={permission.isPermissionVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        title={
          permission.editModal
            ? "Edit Permission Request"
            : "Create Permission request"
        }
        editModal={permission.editModal}
        editModalData={permission.editModalData}
        onEdit={onEdit}
      />
      <DeleteModal
        title="Are you sure want to delete the request ?"
        visible={permission.deleteModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        onConfirmDelete={handleDelete}
      />
    </section>
  );
};

MyPermissionTable.propTypes = {
  balanceRefetch: PropTypes.func.isRequired,
};

export default MyPermissionTable;
