import React, { useState } from "react";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import Datatable from "../../../components/common/Datatable";
import ModalForAddEdit from "./ModalForAddEdit";
import DeleteModal from "../../../components/common/DeleteModal";
import {
  faFilePdf,
  faTrashCan,
  faPenToSquare,
} from "@fortawesome/free-regular-svg-icons";
import {
  useGetHolidaysQuery,
  useDeleteHolidayMutation,
} from "../../../service/holidaysService";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Header from "../../../components/common/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "../../../components/common/IconButton";
import { useSelector } from "react-redux";

function Holidays() {
  const loginInfo = useSelector((state) => state.application.user);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currentState, setCurrentState] = useState({
    selectedData: null,
    isModalOpen: false,
  });

  const { data, isLoading, isError, refetch } = useGetHolidaysQuery();
  const holidays = data ? data : [];
  const [id, setId] = useState([]);
  const [deleteHoliday, { isLoading: deleteLoading }] =
    useDeleteHolidayMutation();
  const passingId = (id, reset) => {
    setDeleteModalVisible(true);
    setId(id);
    reset && reset();
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "title",
      ellipsis: true,
      render: (title) => (
        <Tooltip title={title}>
          <div className="truncate">{title}</div>
        </Tooltip>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      ellipsis: true,
      render: (date) => (
        <Tooltip title={date}>
          <div className="truncate">{date}</div>
        </Tooltip>
      ),
    },
    {
      title: "Type",
      dataIndex: "holidayType",
      ellipsis: true,
      render: (type) => (
        <Tooltip title={type}>
          <div className="truncate">{type}</div>
        </Tooltip>
      ),
    },
    {
      title: "Details",
      dataIndex: "description",
      width: "40%",
      ellipsis: true,
      render: (description) => (
        <Tooltip title={description}>
          <div className="truncate">{description}</div>
        </Tooltip>
      ),
    },
  ];

  if (loginInfo.role === "admin") {
    columns.push({
      title: "Actions",
      dataIndex: "actions",
      width: "120px",
      render: (_, record) => (
        <div className="flex flex-wrap gap-3">
          <IconButton
            title="Edit"
            icon={
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={() =>
                  setCurrentState({ selectedData: record, isModalOpen: true })
                }
              />
            }
          />
          <IconButton
            title="Delete"
            icon={
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => passingId([record._id])}
              />
            }
          />
        </div>
      ),
    });
  }

  const handleDelete = () => {
    deleteHoliday(id)
      .then(() => {
        message.success("Holiday deleted successfully");
        setDeleteModalVisible(false);
      })
      .catch((error) => {
        console.log(error); //eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        setDeleteModalVisible(false);
        setId("");
      });
  };

  const exportToPdf = () => {
    const pdf = new jsPDF();
    const printAbleColumn = columns.slice(0, -1);
    const headers = printAbleColumn.map((column) => ({
      title: column.title,
      dataIndex: column.dataIndex,
      ellipsis: column.ellipsis,
    }));
    const tableData = holidays.map((item) =>
      columns.map((column) => item[column.dataIndex])
    );
    const titleX =
      (pdf.internal.pageSize.width -
        (pdf.getStringUnitWidth("Holidays") * 20) / pdf.internal.scaleFactor) /
      2;
    pdf.setFontSize(20);
    pdf.text("Holidays", titleX, 10);
    pdf.setFontSize(12);
    pdf.autoTable({
      head: [headers.map((header) => header.title)],
      body: tableData,
    });
    pdf.save("HolidayList.pdf");
  };

  const renderActions = (keys, reset) => {
    if (loginInfo.role === "admin") {
      return (
        <div className="flex space-x-3 items-center">
          {keys.length < 1 && (
            <Button
              className="flex justify-center items-center bg-blue-600 !text-white"
              onClick={() =>
                setCurrentState({ selectedData: null, isModalOpen: true })
              }
            >
              <PlusOutlined /> Add
            </Button>
          )}
          {keys.length < 1 && (
            <Button
              className="flex items-center  bg-blue-600 !text-white"
              onClick={refetch}
            >
              <ReloadOutlined />
              Refresh
            </Button>
          )}
          {keys.length > 1 && (
            <Button
              className="flex items-center  bg-red-600 !text-white"
              onClick={() => passingId(keys, reset)}
            >
              <FontAwesomeIcon className="mr-2" icon={faTrashCan} />
              Delete
            </Button>
          )}
          {holidays.length > 0 && keys.length === 0 && (
            <Button
              className="flex items-center  bg-blue-600 !text-white"
              onClick={exportToPdf}
            >
              <FontAwesomeIcon icon={faFilePdf} className="mr-1" />
              Download
            </Button>
          )}
        </div>
      );
    }
  };

  return (
    <div>
      <Header title="All Holidays" />
      <div className="mt-4 customizedTable">
        <Datatable
          tableData={holidays.map((employee) => ({
            ...employee,
            key: employee._id,
          }))}
          columns={columns}
          checkBox={loginInfo.role === "admin" ? true : false}
          title="Holidays"
          renderActions={(selectedRowKeys, reset) =>
            renderActions(selectedRowKeys, reset)
          }
          isLoading={isLoading}
          isError={isError}
          pagination={{
            pageSize: 5,
          }}
        />
        <ModalForAddEdit
          currentState={currentState}
          setCurrentState={setCurrentState}
        />
        <DeleteModal
          title="Are you Sure"
          message="You want to delete this holiday"
          visible={deleteModalVisible}
          onOk={handleDelete}
          onCancel={() => setDeleteModalVisible(false)}
          isLoading={deleteLoading}
        />
      </div>
    </div>
  );
}

export default Holidays;
