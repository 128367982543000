import React, { useState } from "react";
import Datatable from "../../components/common/Datatable";
import Header from "../../components/common/Header";
import {
  HomeOutlined,
  EyeOutlined,
  EditOutlined,
  PlusOutlined,
  ReloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { useNavigate } from "react-router-dom";
import EmployeeModal from "./EmployeeModal";
import Icon from "../../components/common/Icon";
import DeleteModal from "../../components/common/DeleteModal";
import FilterPayroll from "./Filter";
const initialValues = {
  name: "",
  employeeID: "",
  email: "",
  department: "",
  role: "",
  salary: "",
};

const data = [];
const startDate = new Date("2022-01-01");
const endDate = new Date("2024-10-01");

let currentDate = new Date(startDate);
while (currentDate <= endDate) {
  const formattedDate = `${
    currentDate.getMonth() + 1
  }-${currentDate.getFullYear()}`;

  data.push({
    id: data.length + 1,
    date: formattedDate,
    image: `png${data.length + 1}`,
    name: `Jhon${data.length + 1}`,
    employeeID: `EMP-5287${data.length + 1}`,
    email: `test@gmail.com${data.length + 1}`,
    department: `java${data.length + 1}`,
    role: `Developer${data.length + 1}`,
    salary: `$2,574${data.length + 1}`,
  });

  currentDate.setMonth(currentDate.getMonth() + 1);
}

const breadCrumb = [
  {
    title: <HomeOutlined className="text-black !text-lg" />,
  },
  {
    title: "Home",
    href: "",
  },
  {
    title: "Payroll",
  },
];

function Payroll() {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [state, setState] = useState({
    employeeModalVisible: false,
    selectedId: null,
    selectedData: initialValues,
    deleteModalVisible: false,
    employeeData: data,
    filter: null,
  });

  const navigate = useNavigate();

  const openAddEmployeeModal = () => {
    setState({
      ...state,
      employeeModalVisible: true,
      selectedId: null,
      selectedData: initialValues,
    });
  };

  const openEditEmployeeModal = (id) => {
    const selectedData = data.find((item) => item.id === id);
    setState((prevState) => ({
      ...prevState,
      employeeModalVisible: true,
      selectedId: id,
      selectedData,
    }));
  };

  const closeEmployeeModal = () => {
    setState({
      ...state,
      employeeModalVisible: false,
      selectedId: null,
      selectedData: initialValues,
    });
    handleReload();
  };

  const handleEmployeeAction = (values) => {
    let updatedData;

    if (!state.selectedId) {
      updatedData = [...state.employeeData];
    } else {
      updatedData = state.employeeData.map((item) => {
        if (item.key === state.selectedId) {
          return { ...item, ...values };
        }
        return item;
      });
    }
    setState({
      ...state,
      employeeData: updatedData,
    });
    closeEmployeeModal();
  };

  const handleReload = () => {
    window.location.reload();
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Employee ID",
      dataIndex: "employeeID",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "Salary",
      dataIndex: "salary",
    },
    {
      title: "Payslip",
      dataIndex: "payslip",
      render: () => (
        <div className="text-xl">
          <EyeOutlined
            onClick={() => {
              navigate(ROUTE_CONSTANTS.PAYSLIP);
            }}
          />
        </div>
      ),
    },
    {
      title: "Actions",
      dataIndex: "id",
      render: (_, record) => (
        <div className="space-x-6 flex justify-around">
          <EditOutlined
            onClick={() => openEditEmployeeModal(record.id)}
            className="text-xl text-blue-600"
          />
          <DeleteOutlined
            className="text-xl text-red-600"
            onClick={() => setDeleteModalVisible(true)}
          />
        </div>
      ),
    },
  ];

  const renderActions = (keys) => {
    return (
      <div className="flex space-x-3">
        <FilterPayroll data={data} onFilter={handleFilterChange} />
        <Icon
          icon={<PlusOutlined onClick={openAddEmployeeModal} />}
          color="bg-blue-600"
        />
        <Icon
          icon={<ReloadOutlined onClick={handleReload} />}
          color="bg-blue-600"
        />
        {keys.length > 1 && (
          <Icon
            icon={<DeleteOutlined onClick={setDeleteModalVisible} />}
            color="bg-red-600"
          />
        )}
      </div>
    );
  };
  const handleFilterChange = (filteredData) => {
    setState({
      filter: filteredData,
    });
  };
  return (
    <div>
      <Header title="Payroll" crumbs={breadCrumb} />
      <section className="mt-10 customizedTable">
        <Datatable
          tableData={state.filter ? state.filter : []}
          columns={columns}
          checkBox
          rowSelection
          title="Payroll"
          renderActions={(selectedRowKeys) => renderActions(selectedRowKeys)}
        />
        <DeleteModal
          visible={deleteModalVisible}
          onOk={() => setDeleteModalVisible(false)}
          onCancel={() => setDeleteModalVisible(false)}
          title="Are you sure want to delete this data ?"
        />
      </section>
      <EmployeeModal
        visible={state.employeeModalVisible}
        onOk={handleEmployeeAction}
        onCancel={closeEmployeeModal}
        initialValues={state.selectedId ? state.selectedData : initialValues}
        title={
          state.selectedId ? "Edit Employee Salary" : "New Employee Salary"
        }
        onEdit={handleEmployeeAction}
      />
    </div>
  );
}

export default Payroll;
