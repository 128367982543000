import React, { useEffect, useState } from "react";
import { Layout, Menu } from "antd";
import Logo from "../assets/svg/Crud-Operation.png";
import PropTypes from "prop-types";
import layoutNavs from "./LayoutNavs";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getLocalStorage, setLocalStorage } from "../utils";
import { LOCAL_CONSTANTS } from "../constants/localConstants";

const { Sider } = Layout;

function MainLayoutSidebar({ collapsed, setCollapsed }) {
  const permission = useSelector((state) => state.application.permission);
  const [selectedNav, setSelectedNav] = useState(() => {
    return getLocalStorage(LOCAL_CONSTANTS.APP_NAV) || null;
  });
  const [appNav, setAppNav] = useState([]);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleSelect = (e) => {
    const key = e?.key;
    setSelectedNav(key);
    setLocalStorage(LOCAL_CONSTANTS.APP_NAV, key);
  };

  const updateIntialNav = () => {
    let selected;
    appNav.forEach((doc) => {
      if (!selected) {
        if (Array.isArray(doc.children)) {
          selected = doc.children.find((item) => item.path === pathname);
        } else if (doc.path === pathname) {
          selected = doc;
        }
      }
    });
    if (selected) setSelectedNav(selected?.key);
  };

  const handleNavigate = () => {
    let selected;
    appNav.forEach((doc) => {
      if (!selected) {
        if (Array.isArray(doc.children)) {
          selected = doc.children.find((doc) => doc.key === selectedNav);
        } else if (doc.key === selectedNav) {
          selected = doc;
        }
      }
    });
    if (selected) navigate(selected.path);
  };

  const updateAppNavBar = () => {
    const availableNavs = [];
    layoutNavs.forEach((appNav) => {
      const nav = { ...appNav };
      const isPermit = permission?.find(
        (doc) => doc.key === nav.key && !doc.isHide
      );
      if (isPermit) {
        if (nav.children) {
          const navChild = [];
          nav.children.forEach((doc) => {
            const isChildPermit = isPermit?.path?.includes(doc.path);
            if (isChildPermit) {
              navChild.push(doc);
            }
          });
          nav.children = navChild;
        }
        availableNavs.push(nav);
      }
    });
    setAppNav(availableNavs);
  };

  useEffect(() => {
    if (selectedNav) {
      handleNavigate();
    }
  }, [selectedNav]);

  useEffect(() => {
    if (appNav && appNav.length > 0) {
      updateIntialNav();
    }
  }, [appNav]);

  useEffect(() => {
    updateAppNavBar();
  }, [permission]);

  return (
    <Sider
      style={{
        overflow: "auto",
        height: "100vh",
        position: "fixed",
        left: 0,
        top: 0,
        bottom: 0,
      }}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="p-3 flex justify-center">
        <img src={Logo} alt="Logo" />
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedNav]}
        items={appNav}
        onClick={handleSelect}
      />
    </Sider>
  );
}

MainLayoutSidebar.propTypes = {
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
};

export default MainLayoutSidebar;