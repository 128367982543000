import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const myLeaveService = createApi({
  reducerPath: "myLeaveService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["LEAVES"],

  endpoints: (builder) => ({
    getMyLeaves: builder.query({
      query: () => ENDPOINTS_CONSTANTS.LEAVES,
      providesTags: ["LEAVES"],
      transformResponse: (res) =>
        res.data.map((values) => ({ ...values, key: values._id })),
    }),

    postMyLeaves: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.LEAVES,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["LEAVES"],
    }),
    putMyLeaves: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `${ENDPOINTS_CONSTANTS.LEAVES}/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["LEAVES"],
    }),
    deleteMyLeaves: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `${ENDPOINTS_CONSTANTS.LEAVES}/${id}`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["LEAVES"],
    }),
    getLeaveTypes: builder.query({
      query: () => ENDPOINTS_CONSTANTS.LEAVES_TYPE,
      transformResponse: (response) => response?.data,
    }),
    getLeaveBalance: builder.query({
      query: () => ENDPOINTS_CONSTANTS.LEAVE_BALANCE,
      transformResponse: (response) => response?.data,
    }),
    getPermissionBalance: builder.query({
      query: () => ENDPOINTS_CONSTANTS.PERMISSION_BALANCE,
      transformResponse: (response) => response?.data,
    }),
    getLeaveApprovals: builder.query({
      query: () => ENDPOINTS_CONSTANTS.LEAVE_APPROVALS,
      transformResponse: (response) =>
        Array.isArray(response?.data)
          ? response.data.map((doc) => ({ ...doc, key: doc._id }))
          : [],
      providesTags: ["LEAVE_APPROVAL"],
    }),
    putLeaveApprovals: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.UPDATE_LEAVE_APPROVALS,
        method: "PUT",
        body: payload,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["LEAVE_APPROVAL"],
    }),
    postCompOff: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.COMP_OFF,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["COMP_OFF"],
    }),
    getCompOff: builder.query({
      query: () => ENDPOINTS_CONSTANTS.COMP_OFF,
      providesTags: ["COMP_OFF"],
      transformResponse: (response) => response?.data,
    }),
    deleteCompOff: builder.mutation({
      query: ({ id }) => ({
        url: `${ENDPOINTS_CONSTANTS.COMP_OFF}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["COMP_OFF"],
    }),
    updateCompOff: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `${ENDPOINTS_CONSTANTS.COMP_OFF}/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["COMP_OFF"],
    }),
    getCompOffApprovals: builder.query({
      query: () => ENDPOINTS_CONSTANTS.COMPOFF_APPROVALS,
      transformResponse: (response) =>
        Array.isArray(response?.data)
          ? response.data.map((doc) => ({ ...doc, key: doc._id }))
          : [],
      providesTags: ["LEAVE_APPROVAL"],
    }),
    putCompOffApprovals: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.UPDATE_COMPOFF_APPROVALS,
        method: "PUT",
        body: payload,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["LEAVE_APPROVAL"],
    }),
  }),
});

export const {
  useGetMyLeavesQuery,
  usePostMyLeavesMutation,
  useDeleteMyLeavesMutation,
  usePutMyLeavesMutation,
  useGetLeaveTypesQuery,
  useGetLeaveBalanceQuery,
  useGetPermissionBalanceQuery,
  useGetLeaveApprovalsQuery,
  usePutLeaveApprovalsMutation,
  usePostCompOffMutation,
  useGetCompOffQuery,
  useDeleteCompOffMutation,
  useUpdateCompOffMutation,
  useGetCompOffApprovalsQuery,
  usePutCompOffApprovalsMutation,
} = myLeaveService;
