/* eslint react/display-name:0 */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Form, Input, Select } from "antd";
import PropTypes from "prop-types";
// import Upload from "../../components/common/Upload";

const EmpModalInfoPage = forwardRef(({ selectedData, form }, ref) => {
  const [profile] = useState(null);

  // const handleUploadProfile = (file) => {
  //   setProfile(file);
  // };

  useEffect(() => {
    form.setFieldsValue(selectedData);
  }, []);

  useImperativeHandle(ref, () => ({
    goNext: async () => {
      try {
        const result = await form.validateFields();
        return Promise.resolve({ ...result, profile });
      } catch (error) {
        return Promise.reject(error);
      }
    },
  }));

  return (
    <div>
      <Form
        form={form}
        layout="vertical"
        className="h-[350px]  overflow-y-auto"
      >
        <div className="flex mt-4 w-full h-20 justify-around">
          <Form.Item
            className="w-1/4 "
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: "Please enter your First Name",
              },
            ]}
            normalize={(value) =>
              value && value.charAt(0).toUpperCase() + value.slice(1)
            }
          >
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item
            className="w-1/4"
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                message: "Please enter your Last Name",
              },
            ]}
            normalize={(value) =>
              value && value.charAt(0).toUpperCase() + value.slice(1)
            }
          >
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            className="w-1/4 "
            name="gender"
            label="Gender"
            rules={[
              {
                required: true,
                message: "Please enter your Gender",
              },
            ]}
          >
            <Select
              placeholder="Gender"
              options={[
                {
                  value: "male",
                  label: "Male",
                },
                {
                  value: "female",
                  label: "Female",
                },
              ]}
            />
          </Form.Item>
        </div>
        <div className="flex w-full h-20 justify-around">
          {/* <Form.Item className="w-1/4" label="Profile" name="profile">
            <Upload handleChange={handleUploadProfile} />
          </Form.Item> */}
          <Form.Item
            className="w-1/4"
            name="dateOfBirth"
            label="Date of Birth"
            rules={[
              {
                required: true,
                message: "Please enter your Date of Birth",
              },
            ]}
          >
            <Input type="date" className="w-full" placeholder="Date of Birth" />
          </Form.Item>
          <Form.Item
            className="w-7/12"
            name="mobile"
            label="Mobile"
            rules={[
              {
                required: true,
                message: "Please enter your Mobile",
              },
              {
                pattern: /^[0-9]{10}$/,
                message: "Mobile number must be 10 digits",
              },
            ]}
          >
            <Input placeholder="Mobile Number" type="number" maxLength={10} />
          </Form.Item>
        </div>
        <div className="flex w-full h-20 justify-around">
          <Form.Item
            className="w-7/12"
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: "Please enter your Address",
              },
            ]}
          >
            <Input placeholder="Address" />
          </Form.Item>
          <Form.Item
            className="w-1/4"
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: "Please enter your City",
              },
            ]}
            normalize={(value) =>
              value && value.charAt(0).toUpperCase() + value.slice(1)
            }
          >
            <Input placeholder="City" />
          </Form.Item>
        </div>
        <div className="flex w-full h-10 px-10">
          <Form.Item className="w-full" name="about" label="About">
            <Input.TextArea className="!resize-none" maxlength="150" />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
});
EmpModalInfoPage.propTypes = {
  selectedData: PropTypes.array.isRequired,
  form: PropTypes.func,
  setUserInfo: PropTypes.func,
  userInfo: PropTypes.object,
};

export default EmpModalInfoPage;
