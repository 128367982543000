import React from "react";
import { Form, Input, Divider, Button, message, Alert } from "antd";
import {
  UserOutlined,
  GoogleOutlined,
  TwitterOutlined,
} from "@ant-design/icons";
import "../../assets/styles/main.css";
import { useLoginMutation, useMeMutation } from "../../service/baseService";
import { setLocalStorage } from "../../utils";
import { LOCAL_CONSTANTS } from "../../constants/localConstants";
import { useLocation, useNavigate } from "react-router-dom";
import CrudOperations from "../../assets/svg/Crud-Operation.png";
import loginPageImage from "../../assets/svg/login-page-bg.svg";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
const btnClass =
  "border border-black p-2 rounded-lg text-base hover:bg-[#1677FF] hover:text-white hover:border-[#1677FF]";

function Login() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [loginApiCall, { isLoading }] = useLoginMutation();
  const [getUserDetails, { isLoading: userLoading }] = useMeMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const reason = queryParams.get("reason");

  const handleLogin = async (values) => {
    loginApiCall(values)
      .unwrap()
      .then((response) => {
        const token = response?.token;
        const refreshToken = response?.refreshToken;
        setLocalStorage(LOCAL_CONSTANTS.ACCESS, token);
        setLocalStorage(LOCAL_CONSTANTS.REFRESH, refreshToken);
        getUserDetails()
          .unwrap()
          .then((response) => {
            const userData = { ...response.data };
            if (userData?.permission?.length > 0) {
              const path = Array.isArray(userData?.permission?.[0]?.path)
                ? userData.permission[0].path[0]
                : userData.permission[0].path;
              navigate(path);
            } else {
              navigate(ROUTE_CONSTANTS.ACCESS_DENIED);
            }
          })
          .catch((errorResponse) => {
            const message = errorResponse?.message
              ? errorResponse.message
              : "Something went wrong";
            messageApi.error(message);
          });
      })
      .catch((errorResponse) => {
        const message = errorResponse?.message
          ? errorResponse.message
          : "Please check your credentials";
        messageApi.error(message);
      });
  };

  return (
    <div>
      {contextHolder}
      <div className="flex">
        <div className="flex flex-1 h-screen justify-center items-cente max-md:hidden">
          <img
            src={loginPageImage}
            alt="logo"
            className="w-full mix-blend-multiply"
          />
        </div>
        <div className="flex flex-1 flex-col h-screen justify-center items-center loginForm">
          {reason && (reason === "expired" || reason === "password") && (
            <Alert
              message={
                reason === "expired"
                  ? "Your session has expired. Please log in again to continue."
                  : "Your password has been changed. For security reasons, we have logged you out from all devices. Please log in again with your new password to continue."
              }
              type="info"
              showIcon
              className="mb-4 w-7/12"
            />
          )}
          <img src={CrudOperations} alt="logo" className="w-44 mb-10" />
          <div className="w-7/12">
            <p className="font-semibold text-4xl mb-5">Welcome to CRUD</p>
          </div>
          <div className="w-7/12">
            <Form
              form={form}
              name="validateOnly"
              layout="vertical"
              autoComplete="off"
              className="w-full"
              onFinish={handleLogin}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your username",
                  },
                ]}
              >
                <Input
                  placeholder="Username*"
                  type="text"
                  suffix={<UserOutlined />}
                  className="px-4 h-12"
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password*"
                  type="password"
                  className="px-4 h-12"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className=" bg-blue-500 w-full mt-1"
                  loading={isLoading || userLoading}
                >
                  LOGIN
                </Button>
              </Form.Item>
            </Form>
            <Divider className="text-red-900 !hidden">OR</Divider>
            <div className="flex w-full justify-center gap-x-2 hidden">
              <GoogleOutlined className={btnClass} />
              <TwitterOutlined className={btnClass} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
