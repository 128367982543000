import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form, DatePicker, TimePicker, Input } from "antd";
import dayjs from "dayjs";

const MyPermissionForm = ({ form, customFormat }) => {
  const disabledDate = (current) => current && current < dayjs().startOf("day");
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);

  const onChangeFromTime = (time) => {
    setFromTime(time);
    form.setFieldsValue({ toTime: null });
  };

  const onChangeToTime = (time) => {
    setToTime(time);
    const timeDifference = time.diff(fromTime, "minutes");
    if (timeDifference > 120) {
      form.setFields([
        {
          name: "toTime",
          errors: ["To time should be within 2 hours from the From time."],
        },
      ]);
    } else {
      form.setFieldsValue({ toTime: time });
    }
  };

  const disabledHours = () => {
    if (fromTime !== null) {
      const currentHour = fromTime.hour();
      return Array.from({ length: currentHour }, (_, index) => index);
    } else {
      return [];
    }
  };

  return (
    <Form form={form} className="p-3" layout="vertical">
      <Form.Item
        className="w-full"
        label="Date"
        name="date"
        rules={[
          { required: true, message: "Please enter the date" },
          { type: "object", message: "Invalid date format" },
        ]}
      >
        <DatePicker
          className="w-full"
          disabledDate={disabledDate}
          format={customFormat}
        />
      </Form.Item>

      <div className="flex justify-between gap-2 w-full">
        <Form.Item
          className="w-2/4"
          label="From time"
          name="fromTime"
          rules={[{ required: true, message: "Please select the time" }]}
        >
          <TimePicker
            value={fromTime}
            onChange={onChangeFromTime}
            format="HH:mm"
          />
        </Form.Item>

        <Form.Item
          className="w-2/4"
          label="To time"
          name="toTime"
          rules={[
            { required: true, message: "Please select the time" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (
                  !value ||
                  value.diff(getFieldValue("fromTime"), "minutes") <= 120
                ) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "To time should be within 2 hours from the From time."
                );
              },
            }),
          ]}
        >
          <TimePicker
            value={toTime}
            onChange={onChangeToTime}
            format="HH:mm"
            disabled={fromTime ? false : true}
            disabledHours={disabledHours}
          />
        </Form.Item>
      </div>

      <Form.Item
        label="Reason"
        name="reason"
        rules={[{ required: true, message: "Please enter the reason" }]}
      >
        <Input.TextArea placeholder="Reason" />
      </Form.Item>
    </Form>
  );
};

MyPermissionForm.propTypes = {
  form: PropTypes.object.isRequired,
  customFormat: PropTypes.string.isRequired,
};

export default MyPermissionForm;
