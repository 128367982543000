import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import Datatable from "../../../components/common/Datatable";
import Header from "../../../components/common/Header";
import IconButton from "../../../components/common/IconButton";
import {
  useGetLeaveApprovalsQuery,
  usePutLeaveApprovalsMutation,
} from "../../../service/MyLeavesService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, message, Tag, Tooltip } from "antd";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../../components/common/DeleteModal";
import { ReloadOutlined } from "@ant-design/icons";
import { statusText } from "../../../constants/appConstants";
import LeaveFilters from "./LeaveFilters";

const initialConfirmModal = {
  flag: false,
  id: null,
  status: null,
};
const initialReason = {
  value: "",
  isError: false,
};
const { TextArea } = Input;
function LeaveApprovals() {
  const [confirmModal, setConfirmModal] = useState(initialConfirmModal);
  const { data, isError, isFetching, refetch } = useGetLeaveApprovalsQuery();
  const [updateLeave, { isLoading }] = usePutLeaveApprovalsMutation();
  const [reason, setReason] = useState(initialReason);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);

  const handleAccept = (id, status) => {
    if (status.status < 1) {
      setConfirmModal({
        id,
        status: 1,
        flag: true,
      });
    }
  };

  const handleReject = (id, status) => {
    if (status.status < 1) {
      setConfirmModal({
        id,
        status: 2,
        flag: true,
      });
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "fromDate",
      key: "fromDate",
      render: (_, record) => {
        const { fromDate, toDate } = record;
        const formattedfromDate = moment(fromDate).format("DD-MM-YYYY");
        const formattedtoDate = moment(toDate).format("DD-MM-YYYY");
        if (formattedfromDate != formattedtoDate) {
          return `${moment(fromDate).format("DD-MM-YYYY")} to ${moment(
            toDate
          ).format("DD-MM-YYYY")}`;
        } else {
          return moment(fromDate).format("DD-MM-YYYY");
        }
      },
    },
    {
      title: "No of Days",
      dataIndex: "noOfDays",
      key: "noOfDays",
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: ({ name }) => {
        return <div>{name}</div>;
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Applied On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => {
        return (
          <div>{moment(new Date(value)).format("DD-MM-YYYY hh:mm A")}</div>
        );
      },
    },
    {
      title: "Employee",
      dataIndex: "user",
      key: "user",
      render: ({ firstName, lastName }) => {
        return (
          <div>
            {firstName} {lastName}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        if (record.status === 2) {
          return (
            <Tooltip title={record.rejectReason}>
              <Tag color="red">{statusText[text]}</Tag>
            </Tooltip>
          );
        } else {
          return (
            <Tag color={record.status === 1 ? "green" : "blue"}>
              {statusText[text]}
            </Tag>
          );
        }
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (id, status) => (
        <div className="space-x-6 flex ">
          <IconButton
            icon={<FontAwesomeIcon icon={faCheck} />}
            title={status.status < 1 ? "Accept" : "No action required"}
            onClick={() => handleAccept([id], status)}
          />
          <IconButton
            icon={<FontAwesomeIcon icon={faXmark} />}
            title={status.status < 1 ? "Reject" : "No action required"}
            onClick={() => handleReject([id], status)}
          />
        </div>
      ),
    },
  ];

  const handleReasonChange = (e) => {
    const { value } = e.target;
    setReason({ value, isError: false });
  };

  const handleCancel = () => {
    setConfirmModal(initialConfirmModal);
    setReason(initialReason);
  };

  const handleConfirm = () => {
    const { status, id } = confirmModal;
    const { value } = reason;
    if (status === 2 && !value) {
      setReason((prev) => ({ ...prev, isError: true }));
      return;
    }
    updateLeave({ status, id, reason: value })
      .unwrap()
      .then(() => {
        message.success("Status Updated");
      })
      .catch(() => {
        message.error("Something went wrong");
      })
      .finally(() => {
        handleCancel();
      });
  };

  const renderActions = (_id) => {
    return (
      <div className="flex justify-between space-x-2">
        <LeaveFilters
          setSelectedEmployee={setSelectedEmployee}
          selectedEmployee={selectedEmployee}
          setSelectedMonth={setSelectedMonth}
          selectedMonth={selectedMonth}
          excelData={excelData}
        />
        <Button
          className="flex items-center  bg-blue-600 !text-white"
          onClick={refetch}
        >
          <ReloadOutlined />
          Refresh
        </Button>
        {_id.length > 1 && (
          <div className="flex space-x-3 items-center">
            <Button
              className="flex items-center  bg-green-600 !text-white"
              onClick={() => handleAccept(_id)}
            >
              <FontAwesomeIcon icon={faCheck} className="mr-2" />
              Accept
            </Button>

            <Button
              className="flex items-center  bg-red-600 !text-white"
              onClick={() => handleReject(_id)}
            >
              <FontAwesomeIcon className="mr-2" icon={faXmark} />
              Reject
            </Button>
          </div>
        )}
      </div>
    );
  };

  useEffect(() => {
    if(selectedEmployee){
      const [selectedFirstName, selectedLastName] = selectedEmployee.split(" ");
    
      const matchingEmployee = data.find(employee =>
          employee.user.firstName === selectedFirstName && employee.user.lastName === selectedLastName
      );
    
      const empId = matchingEmployee ? matchingEmployee.user.empId : null;
      setEmployeeId(empId);
    }
  }, [selectedEmployee, data]);

  const filteredData = useMemo(() => {
    if (!selectedMonth && !selectedEmployee) {
      return data;
    }
    return data?.filter((item) => {
      const employeeFullName = `${item.user.firstName} ${item.user.lastName}`;
      const fromDate = moment(item.fromDate);
      const selectedEmployeeMatches =
        !selectedEmployee || employeeFullName === selectedEmployee;
      const selectedMonthMatches =
        !selectedMonth || fromDate.format("MMMM YYYY") === selectedMonth;
      return selectedEmployeeMatches && selectedMonthMatches;
    });
  }, [data, selectedMonth, selectedEmployee]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

const excelData = filteredData?.map((item, index) => ({
  SlNo: index + 1,
  Name: `${item.user.firstName} ${item.user.lastName}`,
  EmpId: employeeId,
  FromDate: formatDate(item.fromDate),
  ToDate: formatDate(item.toDate),
  CreatedAt: formatDate(item.createdAt),
  Days: item.noOfDays,
  Reason: item.reason,
  LeaveType: item.type.name,
  Status: statusText[item.status]
}));

  return (
    <div className="customizedTable">
      <DeleteModal
        visible={confirmModal.flag}
        onOk={handleConfirm}
        onCancel={handleCancel}
        title="Request"
        message={
          confirmModal.status === 1
            ? "Are you sure want to accept?"
            : "Sure you want to reject? Please provide a reason."
        }
        confirmButtonText={confirmModal.status === 1 ? "Accept" : "Reject"}
        isLoading={isLoading}
        confirmButtonClass={
          confirmModal.status === 1 ? "bg-green-600 text-white" : undefined
        }
        renderBody={
          confirmModal.status === 2
            ? () => {
                return (
                  <div className="my-2">
                    <TextArea
                      placeholder="Type reason here.."
                      allowClear
                      onChange={handleReasonChange}
                      value={reason.value}
                      status={reason.isError && "error"}
                    />
                  </div>
                );
              }
            : null
        }
      />
      <div className="flex justify-between">
        <Header title="Leave" />
        <div className="mt-4">
          <p>
           Applied Filters{" "}
           {selectedEmployee ? <Tag color="blue">{`${selectedEmployee}`}</Tag> : ""}{" "}
           {selectedMonth ? <Tag color="blue">{`${selectedMonth}`}</Tag> : ""}
          </p>
        </div>
      </div>

      <section className="mt-4">
        <Datatable
          tableData={filteredData}
          columns={columns}
          checkBox
          rowSelection
          title="Leave Approvals"
          renderActions={renderActions}
          isError={isError}
          isLoading={isFetching}
        />
      </section>
    </div>
  );
}

export default LeaveApprovals;