import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const employeeService = createApi({
  reducerPath: "employeeApi",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Employee"],
  endpoints: (builder) => ({
    getAllEmployees: builder.query({
      query: () => ENDPOINTS_CONSTANTS.EMPLOYEE,
      providesTags: ["Employee"],
      transformResponse: (response) =>
        response && Array.isArray(response.employee)
          ? response.employee.map((data) => {
              const { User_Meta, ...otherDatas } = data;
              return { ...User_Meta, ...otherDatas };
            })
          : [],
    }),
    getOneEmployee: builder.query({
      query: (id) => `${ENDPOINTS_CONSTANTS.EMPLOYEE}/${id}`,
      providesTags: ["Employee"],
      transformResponse: (response) =>
        response &&
        response.employee &&
        response.employee.length > 0 &&
        response.employee.map((data) => {
          const { Info, ...otherDatas } = data;
          return { ...Info, ...otherDatas };
        }),
    }),
    addEmployee: builder.mutation({
      query: (data) => {
        return {
          url: ENDPOINTS_CONSTANTS.POSTEMPLOYEE,
          method: "POST",
          body: data,
          FormData: true,
        };
      },
      invalidatesTags: ["Employee"],
    }),
    updateEmployee: builder.mutation({
      query: (payload) => ({
        url: `${ENDPOINTS_CONSTANTS.EMPLOYEE}/${payload.id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Employee"],
    }),
    changePassword: builder.mutation({
      query: (payload) => ({
        url: `${ENDPOINTS_CONSTANTS.EMPLOYEE}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Employee"],
    }),

    deleteEmployee: builder.mutation({
      query: (payload) => ({
        url: `${ENDPOINTS_CONSTANTS.DELETEEMPLOYEE}`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["Employee"],
    }),
    getEmployeesByManager: builder.query({
      query: () => ENDPOINTS_CONSTANTS.EMPLOYEE_BYMANAGER,
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetAllEmployeesQuery,
  useGetOneEmployeeQuery,
  useAddEmployeeMutation,
  useUpdateEmployeeMutation,
  useDeleteEmployeeMutation,
  useGetEmployeesByManagerQuery,
  useChangePasswordMutation,
} = employeeService;
