import React from "react";
import { Popover, message, Avatar } from "antd";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAvatarLabel } from "../../utils/helpers";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const ContactPopover = ({ visible, onClose, email, phone, client }) => {
  const handleVisibleChange = (visible) => {
    if (!visible) {
      onClose();
    }
  };

  const handleCopy = (dataToCopy) => {
    navigator.clipboard.writeText(dataToCopy).then(() => {
      message.success("Contact copied");
    });
  };

  const handleIconClick = (e) => {
    e.stopPropagation();
    const contactInfo = `${email} ${phone}`;
    handleCopy(contactInfo);
  };

  const PopoverContent = () => {
    return (
      <div>
        <header className="flex gap-4 items-center border-b border-gray-300 p-3">
          <Avatar size={35} className="bg-pink-200 text-pink-800 font-bold">
            {getAvatarLabel(client)}
          </Avatar>
          <span className="capitalize">{client}</span>
        </header>
        <section className="p-3">
          <div className="flex gap-4 items-center mb-2">
            <FontAwesomeIcon icon={faEnvelope} className="mt-[4px]" />
            <span
              aria-hidden
              className="cursor-pointer"
              onClick={handleIconClick}
            >
              {email}
            </span>
          </div>
          <div className="flex gap-4 items-center mb-3">
            <FontAwesomeIcon icon={faPhone} className="mt-[4px]" />
            <span
              aria-hidden
              className="cursor-pointer"
              onClick={handleIconClick}
            >
              {phone}
            </span>
          </div>
        </section>
      </div>
    );
  };

  return (
    <Popover
      className="bg-gradient-to-right  from-yellow-200 to-white-400 w-60"
      content={<PopoverContent />}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    ></Popover>
  );
};

ContactPopover.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  client: PropTypes.string,
};

export default ContactPopover;
