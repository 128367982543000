import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const blogService = createApi({
  reducerPath: "blogService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getBlogUser: builder.query({
      query: () => ENDPOINTS_CONSTANTS.BLOG_USER,
      transformResponse: (response) =>
        Array.isArray(response?.data)
          ? response.data.map((doc) => ({
              label: `${doc.firstName} ${doc.lastName}`,
              value: doc._id,
            }))
          : [],
    }),
    postBlog: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.BLOG,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["BLOG"],
    }),
    getBlogs: builder.query({
      query: () => ENDPOINTS_CONSTANTS.BLOG,
      transformResponse: (response) => response?.data,
      providesTags: ["BLOG"],
    }),
    updateBlog: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.BLOG,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["BLOG"],
    }),
    deleteBlog: builder.mutation({
      query: (id) => ({
        url: `${ENDPOINTS_CONSTANTS.BLOG}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["BLOG"],
    }),
  }),
});

export const {
  useGetBlogUserQuery,
  usePostBlogMutation,
  useGetBlogsQuery,
  useUpdateBlogMutation,
  useDeleteBlogMutation
} = blogService;
