import React from "react";
import { Button, Form, Input, Modal, Select, Upload } from "antd";
import PropTypes from "prop-types";
import { useGetAllEmployeesQuery } from "../../service/employeeService";
import moment from "moment";
import { UploadOutlined } from "@ant-design/icons";

const EventModal = ({
  open,
  onSave,
  onCancel,
  title,
  onEdit,
  selectedData,
  selectedDate,
  isLoading,
}) => {
  const [form] = Form.useForm();
  const { data: employeeData = [] } = useGetAllEmployeesQuery();

  const options = employeeData?.map((employee) => ({
    label: employee.firstName,
    value: employee._id,
  }));

  if (selectedData) {
    form.setFieldsValue(selectedData);
  } else {
    form.setFieldsValue({ date: selectedDate });
  }

  const handleChange = () => {};

  const handleOk = () => {
    form
      .validateFields()
      .then(async (values) => {
        const membersDetails = employeeData.filter((obj) =>
          values.assignedTo.includes(obj._id)
        );
        const members = membersDetails.map((member) => ({
          firstName: member.firstName,
          lastName: member.lastName,
        }));
        values.members = members;

        if (selectedData) {
          onEdit(values, selectedData._id, form.resetFields);
        } else {
          onSave(values, form.resetFields);
        }
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
      });
  };
  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const footerContent = (
    <div className="flex">
      <Button className="w-1/2 border" size="large" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        className="bg-blue-500 text-white w-1/2"
        onClick={handleOk}
        loading={isLoading}
      >
        {selectedData ? "Update" : "Add"}
      </Button>
    </div>
  );
  return (
    <Modal
      centered
      title={title}
      open={open}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footerContent}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          className="w-full"
          label="Event Name"
          name="title"
          rules={[{ required: true, message: "Please enter Event name" }]}
        >
          <Input placeholder="Event Name" className="w-full" />
        </Form.Item>
        <div className="flex justify-between">
          <Form.Item
            className="w-1/2"
            label="Date"
            name="date"
            rules={[{ required: true, message: "Please select date" }]}
          >
            <Input
              type="date"
              className="w-11/12"
              min={moment().format("YYYY-MM-DD")}
            />
          </Form.Item>
          <Form.Item
            className="w-1/2"
            label="Time"
            name="startTime"
            rules={[{ required: true, message: "Please enter Time" }]}
          >
            <Input type="time" />
          </Form.Item>
        </div>
        <Form.Item
          className="w-full"
          label="Assigned To"
          name="assignedTo"
          rules={[{ required: true, message: "Please enter Event name" }]}
        >
          <Select
            mode="multiple"
            placeholder="Select Members"
            onChange={handleChange}
            optionLabelProp="label"
            options={options}
          />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[{ required: true, message: "Please enter Description " }]}
        >
          <Input.TextArea placeholder="Description" className="w-full" />
        </Form.Item>
        <Form.Item label="Attachment" name="attachment">
          <Upload>
            <Button>
              <UploadOutlined /> Upload{" "}
            </Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};

EventModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  selectedDate: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default EventModal;
