import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

function EmployeeTicketResolved() {
  const [chartedData] = useState({
    series: [
      {
        name: "Project 1",
        data: [44, 55, 41, 67, 22, 43],
      },
      {
        name: "Project 2",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "Project 3",
        data: [11, 17, 15, 15, 21, 14],
      },
      {
        name: "Project 4",
        data: [21, 7, 25, 13, 22, 8],
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: 20,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      },
      yaxis: {
        min: 0,
        max: 120,
      },
      legend: {
        show: false,
      },
      fill: {
        opacity: 1,
      },
    },
  });
  return (
    <div className="flex flex-col w-full lg:w-1/3 p-4 bg-white rounded-lg shadow-lg mb-4 lg:mb-0">
      <h1 className="text-lg text-gray-400 p-2 font-bold mb-4">
        Ticket Resolved
      </h1>
      <div id="chart">
        <ReactApexChart
          options={chartedData.options}
          series={chartedData.series}
          type="bar"
          height={350}
        />
        <div className="flex justify-around">
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">30%</p>
            <p className="text-gray-600">Last week</p>
          </div>
          <div className="flex flex-col items-center">
            <p className="text-lg font-bold">70%</p>
            <p className="text-gray-600">Last month</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeTicketResolved;
