import React, { useEffect, useState } from "react";
import {
  Modal,
  TimePicker,
  Input,
  Button,
  Form,
  message,
  Select,
  DatePicker,
} from "antd";
import PropTypes from "prop-types";
import "../../assets/styles/main.css";
import moment from "moment";
import dayjs from "dayjs";
import { useGetProjectsQuery } from "../../service/projectService";
const { Option } = Select;
function ModelForAddEdit({
  open,
  onSave,
  onCancel,
  onEdit,
  selectedData,
  inputDisable,
  setInputDisable,
  userObjectId,
}) {
  const { data: allProjects } = useGetProjectsQuery();
  const [userProjectList, setUserProjectList] = useState([]);
  const [timeRange, setTimeRange] = useState("");
  const [form] = Form.useForm();

  const handleOk = () => {
    if (!timeRange) {
      message.error("Please enter all the fields");
    } else {
      form
        .validateFields()
        .then((values) => {
          if (selectedData) {
            onEdit(values, timeRange[0], timeRange[1], selectedData._id);
          } else {
            onSave(values, timeRange);
          }
          setTimeRange(null);
          onCancel();
          form.resetFields();
        })
        .catch((error) => {
          console.log(error); //eslint-disable-line
          message.error("Please enter all the fields");
        });
    }
  };
  const handleCancel = () => {
    setInputDisable(false);
    form.resetFields();
    onCancel();
    setTimeRange(null);
  };
  const { TextArea } = Input;
  const config = {
    rules: [
      {
        required: true,
        message: "Please fill out this field",
      },
    ],
  };

  const handleTimeRangeChange = (time) => {
    setTimeRange(time);
  };

  const disabledTime = (now, type) => {
    const currentHour = now.hour();
    const currentDate = moment(new Date()).format("YYYY-MM-DD");
    const dateInput = form.getFieldValue("date");
    if (type === "start" && currentDate == dateInput) {
      return {
        disabledHours: () =>
          Array.from({ length: 24 }, (_, i) => (i > currentHour ? i : -1)),
      };
    }
    return {
      disabledHours: () => [],
    };
  };

  const disabledDate = (current) => {
    const today = moment();
    const currentDayOfWeek = today.day();
    let startOfCurrentWeek, endOfCurrentWeek;
    if (currentDayOfWeek === 0) {
      startOfCurrentWeek = today.clone().startOf("week").subtract(6, "days");
      endOfCurrentWeek = today.clone().endOf("week");
    } else {
      startOfCurrentWeek = today.clone().startOf("week").add(1, "day");
      endOfCurrentWeek = today.clone().endOf("week");
    }
    return currentDayOfWeek !== 0
      ? current.isBefore(startOfCurrentWeek) ||
          current.isAfter(today) ||
          current.isAfter(endOfCurrentWeek) ||
          current.day() > currentDayOfWeek
      : current.isBefore(startOfCurrentWeek) || current.isAfter(today);
  };

  const footerContent = (
    <div className="flex justify-end space-x-4">
      <Button
        key="cancel"
        size="large"
        className="w-1/2"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        key="add"
        size="large"
        className="bg-[#1677FF] text-base text-white hover:!text-white w-1/2"
        onClick={handleOk}
      >
        {selectedData ? `Update` : `Add`}
      </Button>
    </div>
  );

  const updateState = () => {
    const startingTimeHour = `${new Date(
      selectedData?.startTime
    ).getHours()}`.padStart(2, "0");
    const startingTimeMinute = `${new Date(
      selectedData?.startTime
    ).getMinutes()}`.padStart(2, "0");
    const endingTimeHour = `${new Date(
      selectedData?.endTime
    ).getHours()}`.padStart(2, "0");
    const endingTimeMinute = `${new Date(
      selectedData?.endTime
    ).getMinutes()}`.padStart(2, "0");
    let start = dayjs(
      new Date(
        `${moment(selectedData.startTime).format(
          "ddd MMM D YYYY"
        )} ${startingTimeHour}:${startingTimeMinute}:00`
      )
    );
    let end = dayjs(
      new Date(
        `${moment(selectedData.endTime).format(
          "ddd MMM D YYYY"
        )} ${endingTimeHour}:${endingTimeMinute}:00`
      )
    );
    setTimeRange([start, end]);
    setInputDisable(true);
    const { date, desc, title, startTime, endTime } = selectedData;
    form.setFieldsValue({
      date: dayjs(date),
      desc,
      title,
      startTime,
      endTime,
    });
  };

  useEffect(() => {
    const userProjects = allProjects?.filter((project) =>
      project.team.some((team) => team._id === userObjectId)
    );
    setUserProjectList(userProjects?.map((project) => project.title));
    if (selectedData && Object.keys(selectedData).length > 0) {
      updateState();
    }
  }, [allProjects, selectedData]);
  return (
    <Modal open={open} onCancel={handleCancel} footer={footerContent}>
      <Form form={form}>
        <div>
          <p className="text-lg font-semibold mb-9">
            {selectedData ? `Edit Time Entry` : `Add Time Entry`}
          </p>
          <p className="font-medium mb-2">Time and date</p>
          <div className="flex justify-between">
            <Form.Item name="date" {...config}>
              <DatePicker
                format="DD/MM/YYYY"
                className="w-48 p-2 text-base"
                placeholder="Select date"
                disabledDate={disabledDate}
                disabled={inputDisable}
              />
            </Form.Item>
            <Form.Item>
              <TimePicker.RangePicker
                className="text-base p-2"
                format="hh:mm a"
                value={timeRange}
                minuteStep={5}
                onChange={(e) => {
                  handleTimeRangeChange(e);
                }}
                disabledTime={(now, type) => disabledTime(now, type)}
                disabled={inputDisable}
              />
            </Form.Item>
          </div>
          <p className="font-medium mb-2">Title</p>
          <Form.Item name="title" {...config}>
            <Select placeholder="Select Project Title">
              {userProjectList &&
                userProjectList.length > 0 &&
                userProjectList?.map((projectTitle) => (
                  <Option key={projectTitle} value={projectTitle}>
                    {projectTitle}
                  </Option>
                ))}
              <Option value="Support">Support</Option>
              <Option value="Other">Other</Option>
            </Select>
          </Form.Item>
        </div>
        <p className="font-medium mb-2">Description</p>
        <Form.Item name="desc" {...config}>
          <TextArea placeholder="..." className="!h-[100px] !resize-none" />
        </Form.Item>
      </Form>
    </Modal>
  );
}

ModelForAddEdit.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
  inputDisable: PropTypes.bool.isRequired,
  setInputDisable: PropTypes.func.isRequired,
  userObjectId: PropTypes.string.isRequired,
};

export default ModelForAddEdit;