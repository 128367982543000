import React from "react";
import {
  HomeOutlined,
  UserOutlined,
  FormOutlined,
  TeamOutlined,
  FileTextOutlined,
  GlobalOutlined,
  SlackOutlined,
  ReadOutlined,
  MoneyCollectOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
  PayCircleOutlined,
  BookOutlined,
} from "@ant-design/icons";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";

const layoutNavs = [
  {
    key: "1",
    icon: <HomeOutlined />,
    label: "Dashboard",
    path: ROUTE_CONSTANTS.DASH,
  },
  {
    key: "2",
    icon: <UserOutlined />,
    label: "Employees",
    path: ROUTE_CONSTANTS.EMPLOYEES,
  },
  {
    key: "3",
    icon: <FormOutlined />,
    label: "Attendance",
    path: ROUTE_CONSTANTS.ATTENDANCE,
  },
  {
    key: "4",
    icon: <TeamOutlined />,
    label: "My Teams",
    path: ROUTE_CONSTANTS.MYTEAMS,
  },
  {
    key: "5",
    icon: <GlobalOutlined />,
    label: "Clients",
    path: ROUTE_CONSTANTS.CLIENTS,
  },
  {
    key: "7",
    icon: <SlackOutlined />,
    label: "My Tasks",
    path: ROUTE_CONSTANTS.MYTASKS,
  },
  {
    key: "8",
    icon: <MoneyCollectOutlined />,
    label: "Payroll",
    path: ROUTE_CONSTANTS.PAYROLL,
  },
  {
    key: "9",
    icon: <FileTextOutlined />,
    label: "Leave Management",
    children: [
      {
        key: "leave:1",
        label: "All Leave Requests",
        path: ROUTE_CONSTANTS.ALLLEAVEREQUEST,
      },
      {
        key: "leave:2",
        label: "Leave Balance",
        path: ROUTE_CONSTANTS.LEAVEBALANCE,
      },
      {
        key: "leave:3",
        label: "Leave Types",
        path: ROUTE_CONSTANTS.LEAVETYPES,
      },
    ],
  },
  {
    key: "10",
    icon: <ReadOutlined />,
    label: "Invoice",
    path: ROUTE_CONSTANTS.ALL_INVOICES,
  },
  {
    key: "15",
    icon: <ClockCircleOutlined />,
    label: "Time Sheet",
    path: ROUTE_CONSTANTS.TIMESHEET,
  },
  {
    key: "12",
    icon: <ClockCircleOutlined />,
    label: "Holidays",
    children: [
      {
        key: "12.1",
        label: "All Holidays",
        path: ROUTE_CONSTANTS.HOLIDAYS,
      },
      {
        key: "12.2",
        label: "Holidays Calendar",
        path: ROUTE_CONSTANTS.HOLIDAYS_CALENDAR,
      },
    ],
  },
  {
    key: "11",
    icon: <CalendarOutlined />,
    label: "Events",
    children: [
      {
        key: "event:1",
        label: "All Events",
        path: ROUTE_CONSTANTS.EVENTS,
      },
      {
        key: "event:2",
        label: "Events Calendar",
        path: ROUTE_CONSTANTS.EVENT_CALENDAR,
      },
    ],
  },
  {
    key: "16",
    icon: <PayCircleOutlined />,
    label: "Payslip",
    path: ROUTE_CONSTANTS.PAYSLIPEMP,
  },
  {
    key: "13",
    icon: <BookOutlined />,
    label: "Projects",
    path: ROUTE_CONSTANTS.ALLPROJECTS,
  },
  {
    key: "6",
    icon: <BookOutlined />,
    label: "Leave",
    children: [
      {
        key: "6:0",
        label: "My leaves",
        path: ROUTE_CONSTANTS.LEAVE,
      },
      {
        key: "6:1",
        label: "My Permissions",
        path: ROUTE_CONSTANTS.PERMISSION,
      },
      {
        key: "6:2",
        label: "Leave Calendar",
        path: ROUTE_CONSTANTS.LEAVE_CALENDAR,
      },
    ],
  },
  {
    key: "17",
    icon: <BookOutlined />,
    label: "Approvals",
    children: [
      {
        key: "17:0",
        label: "Leaves",
        path: ROUTE_CONSTANTS.APPROVALS_LEAVE,
      },
      {
        key: "17:1",
        label: "Permission",
        path: ROUTE_CONSTANTS.APPROVALS_PERMISSION,
      },
      {
        key: "17:2",
        label: "TimeSheet",
        path: ROUTE_CONSTANTS.APPROVALS_TIMESHEET,
      },
      {
        key: "17:3",
        label: "Comp Off",
        path: ROUTE_CONSTANTS.APPROVALS_COMPOFF,
      },
    ],
  },
  {
    key: "18",
    icon: <ClockCircleOutlined />,
    label: "Blogs",
    path: ROUTE_CONSTANTS.BLOG,
  },
  {
    key: "19",
    icon: <ClockCircleOutlined />,
    label: "Portfolio",
    path: ROUTE_CONSTANTS.PORTFOLIO,
  },
];

export default layoutNavs;
