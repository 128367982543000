import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const projectService = createApi({
  reducerPath: "projectService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Project"],
  endpoints: (builder) => ({
    getProjects: builder.query({
      providesTags: ["Project"],
      query: () => ({
        url: ENDPOINTS_CONSTANTS.GET_PROJECTS,
        method: "GET",
      }),
    }),
    updateProject: builder.mutation({
      providesTags: ["Project"],
      query: ({ id, ...payload }) => {
        return {
          url: `${ENDPOINTS_CONSTANTS.UPDATE_PROJECTS}/${id}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["Project"],
    }),
    deleteProject: builder.mutation({
      providesTags: ["Project"],
      query: (id) => {
        return {
          url: `${ENDPOINTS_CONSTANTS.DELETE_PROJECTS}/${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["Project"],
    }),
    addProject: builder.mutation({
      providesTags: ["Project"],
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.ADD_PROJECTS,
        method: "POST",
        body: payload,
        headers: {
          "content-type": "application/json",
        },
      }),
      invalidatesTags: ["Project"],
    }),
    deleteProjectAttachment: builder.mutation({
      query: ({ id, ...payload }) => {
        return {
          url: `${ENDPOINTS_CONSTANTS.DELETE_PROJECTS_ATTACH}/${id}`,
          method: "DELETE",
          body: payload,
        };
      },
      invalidatesTags: ["Project"],
    }),
  }),
});

export const {
  useGetProjectsQuery,
  useUpdateProjectMutation,
  useDeleteProjectMutation,
  useAddProjectMutation,
  useDeleteProjectAttachmentMutation
} = projectService;
