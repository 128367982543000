import React from "react";
import Datatable from "../../../components/common/Datatable";
import { useNavigate } from "react-router-dom";
import Header from "../../../components/common/Header";
import { useGetEmployeesByManagerQuery } from "../../../service/employeeService";
import { Tooltip } from "antd";
import IconButton from "../../../components/common/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { ROUTE_CONSTANTS } from "../../../constants/routeConstants";
function TimeSheetApprovals() {
  const navigate = useNavigate();
  const { data } = useGetEmployeesByManagerQuery();
  const employeeData = data ? data : [];
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      render: (firstName, record) => (
        <Tooltip title={`${firstName} ${record.lastName}`}>
          <div className="truncate">
            {firstName} {record.lastName}
          </div>
        </Tooltip>
      ),
    },
    {
      title: "Employee ID",
      dataIndex: "info",
      ellipsis: true,
      render: (info) => <div>{info?.empId}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      ellipsis: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      width: 120,
      render: (_, record) => (
        <IconButton
          title="View"
          icon={
            <FontAwesomeIcon
              icon={faEye}
              onClick={() =>
                navigate(
                  `${ROUTE_CONSTANTS.APPROVALS_TIMESHEET}/${record?._id}`,{state: {empId: record.info.empId}}
                )
              }
            />
          }
        />
      ),
    },
  ];
  return (
    <div>
      <Header title="Time Sheet" />
      <div className="mt-4 customizedTable">
        <Datatable
          columns={columns}
          tableData={employeeData}
          checkBox={false}
          title="Employees List"
        />
      </div>
    </div>
  );
}

export default TimeSheetApprovals;