import React, { useState } from "react";
import {
  useDeleteMyLeavesMutation,
  useGetMyLeavesQuery,
  usePutMyLeavesMutation,
} from "../../service/MyLeavesService";
import { message } from "antd";
import PropTypes from "prop-types";
import { statusText } from "../../constants/appConstants";

function LeaveHoc(Component) {
  const WrappedComponent = ({ leaveTypes, balanceRefetch }) => {
    const { data, refetch, isFetching, isError } = useGetMyLeavesQuery();
    const [deleteMyLeaves] = useDeleteMyLeavesMutation();
    const [putMyLeaves] = usePutMyLeavesMutation();
    const [leave, setLeave] = useState({
      isAddModelVisible: false,
      editModal: false,
      editModalData: null,
      deleteModalVisible: false,
      CancelModalVisible: false,
      deleteRecordId: null,
    });

    const sortedLeaves = data ? [...data].reverse() : [];

    const handleOk = () => {
      setLeave((prevState) => ({
        ...prevState,
        isAddModelVisible: false,
        editModal: false,
        editModalData: null,
      }));
    };

    const handleCancel = () => {
      setLeave((prevState) => ({
        ...prevState,
        isAddModelVisible: false,
        deleteModalVisible: false,
        CancelModalVisible: false,
        editModal: false,
        editModalData: null,
      }));
    };

    const onEdit = async (values) => {
      const { editModalData } = leave;

      try {
        if (editModalData && editModalData._id) {
          const response = await putMyLeaves({
            id: editModalData._id,
            ...values,
          });

          if (response && response.data) {
            message.success("Leave Updated Successfully");
            handleOk();
          } else {
            message.error("Failed to update leave");
          }
        }
      } catch (error) {
        message.error("Error updating record", error);
      }
    };

    const cancelModal = () => {
      setLeave((prevState) => ({
        ...prevState,
        CancelModalVisible: false,
      }));
    };

    const deleteButton = (record) => {
      if (record.status === 0) {
        setLeave((prevState) => ({
          ...prevState,
          deleteModalVisible: true,
          deleteRecordId: record._id,
        }));
      } else {
        message.error(
          `This leave Request is already ${statusText[record.status]}`
        );
      }
    };

    const handleDelete = async () => {
      const { deleteRecordId } = leave;
      setLeave((prevState) => ({
        ...prevState,
        deleteModalVisible: false,
      }));
      if (deleteRecordId) {
        try {
          const response = await deleteMyLeaves({ id: deleteRecordId });
          if (response) {
            message.success("Leave request deleted successfully");
          }
        } catch (error) {
          message.error("Error deleting record", error);
        }
      }
    };

    const editButton = (record) => {
      if (record.status === 0) {
        const editModData = data.find((item) => item._id === record._id);
        setLeave((prevState) => ({
          ...prevState,
          editModalData: editModData,
          isAddModelVisible: true,
          editModal: true,
        }));
      } else {
        message.error(
          `This leave Request is already ${statusText[record.status]}`
        );
      }
    };

    const cancelApprovedLeaveRequest = (record) => {
      if (record.status === 1) {
        setLeave((prevState) => ({
          ...prevState,
          CancelModalVisible: true,
        }));
      } else {
        message.error(`The Leave Request is ${statusText[record.status]}`);
      }
    };

    const plus = () => {
      setLeave((prevState) => ({
        ...prevState,
        isAddModelVisible: true,
        editModal: false,
        editModalData: null,
      }));
    };

    const handleReload = () => {
      refetch();
      balanceRefetch();
    };

    const props = {
      leave,
      sortedLeaves,
      handleOk,
      handleCancel,
      onEdit,
      cancelModal,
      deleteButton,
      handleDelete,
      editButton,
      cancelApprovedLeaveRequest,
      plus,
      handleReload,
      data,
      isFetching,
      isError,
      refetch,
      leaveTypes,
      balanceRefetch
    };

    return <Component {...props} />;
  };

  WrappedComponent.propTypes = {
    leaveTypes: PropTypes.any,
    balanceRefetch: PropTypes.func,
  };

  WrappedComponent.displayName = `LeaveHoc(${getDisplayName(Component)})`;

  return WrappedComponent;
}

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

export default LeaveHoc;
