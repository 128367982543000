import { Button, Input, Select, Upload } from "antd";
import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { initialFile } from "./BlogForm";
import RichEditor from "../../../components/common/RichEditor";
import { useGetBlogUserQuery } from "../../../service/blogQuery";
import MultiTag from "../../../components/common/MultiTag";

const validationSchema = (data) => {
  let error = {};
  if (data) {
    Object.keys(data).forEach((key) => {
      if (
        !data[key] ||
        (!Array.isArray(data[key]) &&
          typeof data[key] === "object" &&
          !data[key].file) ||
        (Array.isArray(data[key]) && data[key].length === 0)
      ) {
        error[key] = `${key} is the required field`;
      }
    });
  }
  return Object.keys(error).length > 0
    ? Promise.reject(error)
    : Promise.resolve("No error");
};

function BlogBasicForm({
  formData,
  nextStep,
  setFormData,
  isEdit,
  editorInitialValue,
}) {
  const [error, setError] = useState({});
  const { data: users } = useGetBlogUserQuery();

  const handleFinish = () => {
    validationSchema(formData)
      .then(() => {
        nextStep();
      })
      .catch((error) => {
        setError(error);
      });
  };
  const thumbnailUpload = ({ file }) => {
    setError({});
    if (file.status === "removed") {
      setFormData((prev) => ({
        ...prev,
        thumbnail: initialFile,
      }));
      return;
    }
    const url = URL.createObjectURL(file);
    const list = [
      {
        uid: "-1",
        name: file.name,
        status: "done",
        url,
      },
    ];
    setFormData((prev) => ({
      ...prev,
      thumbnail: {
        file,
        list,
      },
    }));
  };

  const handleChange = (value, key) => {
    setError({});
    setFormData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return (
    <div>
      <div className="mt-2">
        <p className="mb-1">Title</p>
        <Input
          placeholder="Title"
          className={`w-full ${error["title"] ? "border-red-500" : ""}`}
          onChange={(e) => handleChange(e.target.value, "title")}
          value={formData.title}
        />
      </div>
      <div className="mt-2">
        <p className="mb-1">Category</p>
        <Input
          placeholder="Category"
          className={`w-full ${error["category"] ? "border-red-500" : ""}`}
          onChange={(e) => handleChange(e.target.value, "category")}
          value={formData.category}
        />
      </div>
      <div className="mt-2">
        <p className="mb-1">Tags</p>
        <MultiTag
          value={formData.tags}
          onChange={(value) => handleChange(value, "tags")}
          isError={Boolean(error["tags"])}
        />
      </div>
      <div className="mt-2">
        <p className="mb-1">Author</p>
        <Select
          onChange={(value) => handleChange(value, "author")}
          options={users}
          className="w-full"
          status={error["author"] && "error"}
          value={formData.author}
        />
      </div>
      <div className="mt-2">
        <p className="mb-1">Description</p>
        <RichEditor
          onChange={(value) => handleChange(value, "description")}
          invalid={Boolean(error["description"])}
          initialValue={editorInitialValue}
        />
      </div>
      <div className="mt-2 flex flex-col">
        <p className="mb-1">Image</p>
        <Upload
          name="thumbnail"
          maxCount={1}
          fileList={formData.thumbnail.list}
          listType="picture"
          beforeUpload={() => false}
          onChange={thumbnailUpload}
          disabled={isEdit}
        >
          <Button
            icon={<PlusOutlined />}
            disabled={isEdit}
            className={error["thumbnail"] ? "border-red-500 text-red-500" : ""}
          >
            Click to Upload
          </Button>
          {error["thumbnail"] && (
            <span className="text-red-500 text-xs mx-2">
              Please select a thumbnail to upload.
            </span>
          )}
        </Upload>
      </div>
      <footer className="flex justify-end mt-3">
        <Button
          type="primary"
          className="bg-blue-500 !px-8"
          onClick={handleFinish}
        >
          Next
        </Button>
      </footer>
    </div>
  );
}

BlogBasicForm.propTypes = {
  formData: PropTypes.any.isRequired,
  nextStep: PropTypes.func.isRequired,
  setFormData: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
  editorInitialValue: PropTypes.string.isRequired,
};

export default BlogBasicForm;
