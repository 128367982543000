import React, { useEffect, useState } from "react";
import {Button, Form, Input, Modal, Select, message, Row, Col, DatePicker} from "antd";
import PropTypes from "prop-types";
import TextArea from "antd/es/input/TextArea";
import { useGetClientsQuery } from "../../service/clientService";
import { useGetProjectsQuery } from "../../service/projectService";
const { Option } = Select;

const InvoiceForm = ({ visible, onOk, onCancel, invoiceNumber }) => {
  const [form] = Form.useForm();
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedClient, setSelectedClient] = useState(null);
  const [associate, setAssociate] = useState(null);
  const [associateOption, setAssociateOption] = useState(null);
  const [projectOption, setProjectOption] = useState(null);
  const { data: clients } = useGetClientsQuery();
  const { data: project } = useGetProjectsQuery();
  const projectList = project?.map((proj) => proj.clientId);
  const clientsList = clients?.filter((client) =>
    projectList?.includes(client._id)
  );
  const clientsName = clientsList?.map((name) => ({
    value: name.name,
    label: name.name,
  }));
  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        invoiceNumber,
        clientName: selectedClient,
        businessAssociate: undefined,
        projectName: undefined,
        email: selectedClient
          ? clients.find((client) => client.name === selectedClient)?.email
          : "",
      });
    }
  }, [visible, invoiceNumber, form, selectedClient, clients]);
  const handleClientChange = (clientName) => {
    setSelectedClient(clientName);
    const selectedClient = clients.find((client) => client.name === clientName);
    const associate = selectedClient?.associates.map((associate) => ({
      value: associate.name,
      label: associate.name,
    }));
    setAssociateOption(associate);
    setAssociate(selectedClient.associates);
  };
  const handleAssociateChange = (associateName) => {
    const selectedAssociate = associate.find(
      (associate) => associate.name === associateName
    );
    const projectsForAssociate = project.filter(
      (proj) => proj.selectedAssociateId === selectedAssociate._id
    );
    const projectTitles = projectsForAssociate.map((proj) => ({
      value: proj.title,
      label: proj.title,
    }));
    form.setFieldsValue({ projectName: undefined });
    setProjectOption(projectTitles);
  };
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      onOk(
        { ...values, invoiceNumber, clientId: selectedClient },
        form.resetFields()
      );
      onCancel();
    } catch (err) {
      message.error("Validation failed. Please check the form entries.");
    }
  };
  const handleCancel = () => {
    onCancel();
    form.resetFields();
    setSelectedClient(null);
  };
  const handleChange = (value) => {
    setSelectedValue(value);
  };
  const footerContent = (
    <div className="flex">
      <Button className="w-1/2" size="large" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        className="bg-blue-500 w-1/2"
        onClick={handleOk}
      >
        Save
      </Button>
    </div>
  );
  const disabledEndDate = (endValue) => {
    const startValue = form.getFieldValue("startDate");
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.isBefore(startValue) || endValue.isSame(startValue, "day");
  };

  return (
    <Modal
      centered
      title="Add Invoice"
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footerContent}
      width={800}
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item label="Invoice Number" name="invoiceNumber">
              <Input
                type="text"
                value={`CRUD ${`${invoiceNumber}`.padStart(
                  3,
                  "0"
                )}/${new Date().getFullYear()}`}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="Client Name"
              name="clientName"
              rules={[
                { required: true, message: "Please Select the client name" },
              ]}
            >
              <Select
                placeholder="Select Client Name"
                options={clientsName}
                onChange={handleClientChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Business Associate"
              name="businessAssociate"
              rules={[
                {
                  required: true,
                  message: "Please Select the Business Associate",
                },
              ]}
            >
              <Select
                placeholder="Select Associate Name"
                options={associateOption}
                onChange={handleAssociateChange}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Project Name"
              name="projectName"
              rules={[
                { required: true, message: "Please Select the ProjectName" },
              ]}
            >
              <Select
                placeholder="Select project Name"
                options={projectOption}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please enter the email " }]}
            >
              <Input type="email" defaultValue="" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="Billing Period"
              name="billingPeriod"
              rules={[
                { required: true, message: "Please select the billing period" },
              ]}
            >
              <Select
                placeholder="Select billing period"
                onChange={handleChange}
              >
                <Option value="milestone">Milestone</Option>
                <Option value="date-range">Date Range</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Due Date"
              name="dueDate"
              rules={[{ required: true, message: "Please select the dueDate" }]}
            >
              <DatePicker format="YYYY-MM-DD" disabledDate={disabledEndDate} />
            </Form.Item>
          </Col>
          {selectedValue === "milestone" && (
            <Col span={12}>
              <Form.Item
                label="Milestone"
                name="milestone"
                rules={[
                  { required: true, message: "Please enter the milestone" },
                ]}
              >
                <TextArea />
              </Form.Item>
            </Col>
          )}
          {selectedValue === "date-range" && (
            <Col className="flex w-1/2">
              <Col className="w-1/2">
                <Form.Item
                  label="Start Date"
                  name="startDate"
                  rules={[
                    { required: true, message: "Please select the start date" },
                  ]}
                >
                  <DatePicker format="YYYY-MM-DD" />
                </Form.Item>
              </Col>
              <Col className="w-1/2">
                <Form.Item
                  label="End Date"
                  name="endDate"
                  rules={[
                    { required: true, message: "Please select the end date" },
                  ]}
                >
                  <DatePicker
                    format="YYYY-MM-DD"
                    disabledDate={disabledEndDate}
                  />
                </Form.Item>
              </Col>
            </Col>
          )}
        </Row>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              label="Currency"
              name="currency"
              rules={[
                { required: true, message: "Please select the Currency " },
              ]}
            >
              <Select placeholder="Select Currency">
                <Option value="$">$</Option>
                <Option value="€">€</Option>
                <Option value="$">$</Option>
                <Option value="₹">₹</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Amount (GST)"
              name="amount"
              rules={[{ required: true, message: "Please enter the amount" }]}
            >
              <Input type="number" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Transaction Mode"
              name="transaction"
              rules={[
                { required: true, message: "Please select the Transaction " },
              ]}
            >
              <Select placeholder="Select Transaction">
                <Option value="cash">Cash</Option>
                <Option value="onlinePayments">Online Payments</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Location"
              name="location"
              rules={[
                { required: true, message: "Please select the Location " },
              ]}
            >
              <Select placeholder="Select location">
                <Option
                  value="NO. 145, II FLOOR, G.S.T. ROAD,URAPAKKAM
                         Kanchipuram - 603210 TAMIL NADU
                         Email:info@crud-operations.com
                         Phone:7305305388
                        GSTIN:33AAJCC8289P1ZS"
                >
                  CRUD-CHENNAI,GST Road Urappkam,Chennai 603210 India.
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={14}>
          <Col span={12}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Please enter the description" },
              ]}
            >
              <TextArea />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Terms & Condition" name="terms">
              <TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

InvoiceForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  invoiceNumber: PropTypes.number.isRequired,
  initialValues: PropTypes.object,
};

export default InvoiceForm;
