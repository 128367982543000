import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "../../assets/styles/eventCalendar.css";
import { Popover, Row, Col, Divider, Tag } from "antd";
import moment from "moment";
import {
  useGetMyLeavesQuery,
  useGetLeaveTypesQuery,
} from "../../service/MyLeavesService";

function LeaveCalendar() {
  const { data } = useGetMyLeavesQuery();
  const { data: types } = useGetLeaveTypesQuery();
  const leave = data ? data : [];
  const leaveTypes = types ? types : [];

  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  function getDates(from, to) {
    const startDate = new Date(from);
    const stopDate = new Date(to);
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = currentDate?.addDays(1);
    }
    return dateArray;
  }

  const date = leave?.map((data) => {
    return {
      appliedDates: [...getDates(data.fromDate, data.toDate)],
      ...data,
    };
  });

  const allLeaves = date?.map((data) => {
    if (data) {
      return data.appliedDates.map((value) => {
        return {
          date: value,
          ...data,
        };
      });
    }
  });

  const leaveList = allLeaves?.flat();

  const content = (event) => {
    const extendedProp = event.event._def.extendedProps;
    const fromDate = moment(extendedProp.fromDate).format("YYYY-MM-DD");
    const toDate = moment(extendedProp.toDate).format("YYYY-MM-DD");
    return (
      <div className="w-56 space-y-2">
        <Row className="px-3 pt-3">
          <Col span={8} className="font-medium text-xs w-20 text-slate-500">
            TYPE :
          </Col>
          {leaveTypes.map((type) => {
            if (type._id === extendedProp.category) {
              return (
                <Col
                  span={16}
                  key={type._id}
                  className="font-medium text-xs text-right"
                >
                  {type.name}
                </Col>
              );
            }
          })}
        </Row>
        <Row className="px-3">
          <Col span={10} className="font-medium text-xs text-slate-500">
            FROM DATE :
          </Col>
          <Col span={14} className="font-medium text-xs text-right">
            {fromDate}
          </Col>
        </Row>
        <Row className="px-3">
          <Col span={10} className="font-medium text-xs text-slate-500">
            DAY PERIOD :
          </Col>
          <Col span={14} className="font-medium text-xs text-right">
            {extendedProp.fromDayPeriod}
          </Col>
        </Row>
        <Row className="px-3">
          <Col span={10} className="font-medium text-xs text-slate-500">
            TO DATE :
          </Col>
          <Col span={14} className="font-medium text-xs text-right">
            {toDate}
          </Col>
        </Row>
        {fromDate != toDate && (
          <Row className="px-3">
            <Col span={10} className="font-medium text-xs text-slate-500">
              DAY PERIOD :
            </Col>
            <Col span={14} className="font-medium text-xs text-right">
              {extendedProp.toDayPeriod}
            </Col>
          </Row>
        )}
        <Divider className="m-0 border" />
        <Row className="px-3 pb-3">
          <Col className="font-medium text-xs text-slate-500">REASON :</Col>
          <Col className="font-medium text-xs indent-2.5 text-justify">
            {extendedProp.reason}
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <div>
      <div className="flex w-96 justify-between  items-center h-10 mb-5">
        <p className="text-2xl">Leave Status</p>
        <Tag color="blue" className="mt-1">
          Pending
        </Tag>
        <Tag color="green" className="mt-1">
          Accept
        </Tag>
        <Tag color="red" className="mt-1">
          Reject
        </Tag>
      </div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={leaveList}
        eventContent={(eventInfo) => {
          return (
            <Popover arrow={false} content={content(eventInfo)}>
              <div
                className={`custom-event flex justify-between overflow-auto w-full ${
                  eventInfo.event._def.extendedProps.status === 0
                    ? "pending"
                    : eventInfo.event._def.extendedProps.status === 1
                    ? "accept"
                    : "reject"
                }`}
              >
                <div>
                  {leaveTypes.map((type) => {
                    if (
                      type._id === eventInfo.event._def.extendedProps.category
                    ) {
                      return (
                        <div key={type._id} className="text-sm pl-2">
                          {type.name}
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </Popover>
          );
        }}
      />
    </div>
  );
}

export default LeaveCalendar;
