import { Button, Card, Input, Upload } from "antd";
import React, { useState } from "react";
import PropTypes from "prop-types";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import RichEditor from "../../../components/common/RichEditor";
import { initialContent } from "..";

const errorClass = "border-red-500";

const validationSchema = (data) => {
  let error = {};
  if (data) {
    Object.keys(data).forEach((key) => {
      if (!data[key] || (Array.isArray(data[key]) && data[key].length === 0)) {
        error[key] = `${key} is the required field`;
      }
    });
  }
  return Object.keys(error).length > 0
    ? Promise.reject(error)
    : Promise.resolve("No error");
};

function BlogContentForm({
  formData,
  setFormData,
  goBack,
  onSubmit,
  isLoading,
  isEdit,
  editorInitialValue,
}) {
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (value, key, name) => {
    setFormErrors({});
    setFormData((prev) => {
      const prevState = [...prev];
      prevState[key][name] = value;
      return prevState;
    });
  };
  const handleFileChange = ({ fileList }, key) => {
    setFormErrors({});
    setFormData((prev) => {
      const prevState = [...prev];
      const existingValue = prevState[key].image;
      prevState[key].image = fileList.map((file, idx) => {
        const url = file.originFileObj
          ? URL.createObjectURL(file.originFileObj)
          : null;
        return file.status === "done"
          ? existingValue[idx]
          : {
              file: file.originFileObj,
              meta: {
                name: file.name,
                status: "done",
                url,
              },
            };
      });
      return prevState;
    });
  };

  // Actions
  const handleAdd = () => {
    const lastValue = formData.at(-1);
    validationSchema(lastValue)
      .then(() => {
        setFormData((prev) => [...prev, initialContent]);
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };
  const handleDelete = (index) => {
    setFormData((prev) => {
      const prevState = [...prev];
      prevState.splice(index, 1);
      return prevState;
    });
  };
  const handleSubmit = () => {
    const lastValue = formData.at(-1);
    validationSchema(lastValue)
      .then(() => {
        if (typeof onSubmit === "function") onSubmit();
      })
      .catch((error) => {
        setFormErrors(error);
      });
  };

  return (
    <div>
      {Array.isArray(formData) &&
        formData.map((field, index) => {
          const fileList = field.image
            .map((doc) => doc.meta)
            .filter((doc) => doc);

          const { isRead } = field;
          const isLast = index === formData.length - 1;
          return (
            <Card
              size="small"
              title={`Blog Content ${index + 1}`}
              extra={
                !isRead && <CloseOutlined onClick={() => handleDelete(index)} />
              }
              key={index}
            >
              <div className="mt-2">
                <p className="mb-1">Title</p>
                <Input
                  placeholder="Title"
                  value={field.title}
                  className={`w-full ${
                    isLast && formErrors["title"] ? errorClass : ""
                  }`}
                  onChange={(e) => handleChange(e.target.value, index, "title")}
                />
              </div>
              <div className="mt-2">
                <p className="mb-1">Description</p>
                <RichEditor
                  onChange={(value) =>
                    handleChange(value, index, "description")
                  }
                  invalid={Boolean(isLast && formErrors["description"])}
                  initialValue={editorInitialValue[index]}
                />
              </div>
              <div className="mt-2 flex flex-col">
                <p className="mb-1">Image</p>
                <Upload
                  maxCount={2}
                  fileList={fileList}
                  listType="picture"
                  onChange={(value) => {
                    handleFileChange(value, index);
                  }}
                  beforeUpload={() => false}
                  disabled={isRead}
                >
                  <Button
                    icon={<PlusOutlined />}
                    disabled={isRead}
                    className={`${
                      isLast && formErrors["image"] ? errorClass : ""
                    }`}
                  >
                    Click to Upload
                  </Button>
                </Upload>
              </div>
            </Card>
          );
        })}
      {!isEdit && (
        <Button type="dashed" onClick={handleAdd} block>
          + Add Item
        </Button>
      )}
      <footer className="flex justify-end mt-4 gap-4">
        <Button type="default" className=" !px-8" onClick={goBack}>
          Previous
        </Button>

        <Button
          type="primary"
          className="bg-blue-500 !px-8"
          htmlType="submit"
          onClick={handleSubmit}
          loading={isLoading}
        >
          {isEdit ? "Update" : "Submit"}
        </Button>
      </footer>
    </div>
  );
}

BlogContentForm.propTypes = {
  formData: PropTypes.any.isRequired,
  setFormData: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool.isRequired,
  editorInitialValue: PropTypes.any.isRequired,
};

export default BlogContentForm;
