import React from "react";
import PropTypes from "prop-types";
import { Modal, Button } from "antd";

const DeleteModal = ({
  visible,
  onOk,
  onCancel,
  message,
  isLoading,
  title,
  width = 300,
  confirmButtonText = "Delete",
  confirmButtonClass = "bg-red-600 text-white",
  renderBody,
}) => {
  const footerContent = (
    <div className="mt-5">
      <Button onClick={onCancel}>Cancel</Button>
      <Button
        type="button"
        className={confirmButtonClass}
        onClick={onOk}
        loading={isLoading}
      >
        {confirmButtonText}
      </Button>
    </div>
  );

  return (
    <Modal
      className="w-5"
      title={title}
      width={width}
      onOk={onOk}
      onCancel={onCancel}
      visible={visible}
      centered
      footer={footerContent}
    >
      {message && <p>{message}</p>}
      {typeof renderBody === "function" && renderBody()}
    </Modal>
  );
};

DeleteModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  message: PropTypes.string,
  isLoading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  width: PropTypes.number,
  confirmButtonText: PropTypes.string,
  renderBody: PropTypes.func,
  confirmButtonClass: PropTypes.string,
};
export default DeleteModal;
