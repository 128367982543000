import React from "react";
import { Table, Tag, Avatar } from "antd";

function EmployeeTeams() {
  const teamsData = [
    { id: 1, name: "Team A", status: "Active" },
    { id: 2, name: "Team B", status: "Inactive" },
  ];
  const teamsColumns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      render: (id) => <Avatar size="small">{id}</Avatar>,
    },
    {
      dataIndex: "name",
      key: "name",
      title: <span>Name</span>,
    },
    {
      dataIndex: "status",
      key: "status",
      title: <span>Status</span>,
      render: (status) => (
        <Tag color={status === "Active" ? "green" : "red"}>{status}</Tag>
      ),
    },
  ];
  const Pagination = {
    pageSize: 10,
  };
  return (
    <div className="flex flex-col w-full lg:w-1/3 p-4 bg-white rounded-lg shadow-lg mb-4 lg:mb-0">
      <h1 className="text-lg text-gray-400 p-2 font-bold mb-4">My Teams</h1>
      <div>
        <Table
          dataSource={teamsData}
          columns={teamsColumns}
          pagination={Pagination}
        />
      </div>
    </div>
  );
}

export default EmployeeTeams;
