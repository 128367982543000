import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import desktopViewImage from "../assets/svg/DesktopView-bg.svg";
function App() {
  const [shouldRenderRoutes, setShouldRenderRoutes] = useState(true);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setShouldRenderRoutes(screenWidth >= 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Router>
      {shouldRenderRoutes ? (
        <Routes />
      ) : (
        <div>
          <div className="flex h-screen flex-col justify-center items-center overflow-y-hidden">
            <p className="text-base text-center pt-8 px-5 font-bold">
              Please note that this website is best viewed on a desktop or
              laptop for an optimal experience. Kindly continue with a desktop
              or laptop
            </p>
            <img
              src={desktopViewImage}
              alt="Only Desktop View "
              className="w-full mix-blend-multiply"
            />
          </div>
        </div>
      )}
    </Router>
  );
}

export default App;
