import React from "react";
import Datatable from "../../components/common/Datatable";
import Header from "../../components/common/Header";

const data = [
  {
    key: "1",
    date: "11/02/2018",
    Checkin: "09:38 AM",
    break: "31 mins",
    Checkout: "17:33 PM",
    hours: "09h:11 mins",
    status: "Present",
  },
  {
    key: "2",
    date: "15/02/2018",
    Checkin: "19:38 AM",
    break: "3 mins",
    Checkout: "17:33 PM",
    hours: "09h:11 mins",
    status: "Absent",
  },
];


function Attendance() {
  const renderStatus = (_, { status }) => {
    if (status === "Present") {
      return (
        <p
          className="text-green-700  border w-14 
           border-emerald-600 rounded-md  text-center"
        >
          {status}
        </p>
      );
    } else {
      return (
        <p
          className="text-orange-700  border w-14 
          border-rose-600 rounded-md  text-center"
        >
          {status}
        </p>
      );
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Check-in",
      dataIndex: "Checkin",
    },
    {
      title: "Break",
      dataIndex: "break",
    },
    {
      title: "Check-out",
      dataIndex: "Checkout",
    },
    {
      title: "Hours",
      dataIndex: "hours",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: renderStatus,
    },
  ];

  return (
    <div>
      <Header title="Attendance" />
      <section className="mt-10 customizedTable">
        <Datatable tableData={data} columns={columns} title="Attendence" />
      </section>
    </div>
  );
}

export default Attendance;
