import React from "react";
import Datatable from "../../components/common/Datatable";
import IconButton from "../../components/common/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faTrashCan,
  faEye,
} from "@fortawesome/free-regular-svg-icons";
import EmpTableProfile from "./EmpTableProfile";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useGetRolesQuery } from "../../service/roleService";

function EmployeeDataTable({
  filteredEmployeeData,
  openEmployeeModal,
  renderActions,
  deleteID,
  employeeData,
  isError,
  isLoading,
}) {
  const { data: role } = useGetRolesQuery();
  const roleList = role && role ? role : [];
  const navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      render: (firstName, record) => {
        const profileImageUrl = record.profile?.url && record.profile.url;
        const name = `${firstName} ${record.lastName}`;
        return (
          <EmpTableProfile
            profileImageUrl={profileImageUrl}
            name={name}
            record={record}
          />
        );
      },
    },
    {
      title: "Employee Id",
      dataIndex: "empId",
    },
    {
      title: "Gender",
      dataIndex: "gender",
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Department",
      dataIndex: "department",
    },
    {
      title: "Designation",
      dataIndex: "role",
      render: (record) => {
        const data = roleList.find((data) => data.value == record);
        const name = data ? `${data?.label}` : "-";
        return name;
      },
    },
    {
      title: "ReportingManager",
      dataIndex: "reportingManager",
      render: (record) => {
        const data = employeeData.find((data) => data._id == record);
        const name = data ? `${data?.firstName} ${data?.lastName}` : "-";
        return name;
      },
    },
    {
      title: "Joining Date",
      dataIndex: "joiningDate",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <div className="flex space-x-3">
          <IconButton
            title="Edit"
            icon={
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={() => openEmployeeModal(record)}
              />
            }
          />
          <IconButton
            title="Delete"
            icon={
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => deleteID(record._id)}
              />
            }
          />
          <IconButton
            title="View"
            icon={
              <FontAwesomeIcon
                icon={faEye}
                onClick={() => navigate(`/employees/${[record._id]}`)}
              />
            }
          />
        </div>
      ),
    },
  ];
  return (
    <Datatable
      tableData={(filteredEmployeeData || employeeData).map((employee) => ({
        ...employee,
        key: employee._id,
      }))}
      columns={columns}
      checkBox
      title="Employees"
      renderActions={(selectedRowKeys, reset) =>
        renderActions(selectedRowKeys, reset)
      }
      isError={isError}
      isLoading={isLoading}
      pagination={{
          pageSize: 5,
        }}
    />
  );
}
EmployeeDataTable.propTypes = {
  filteredEmployeeData: PropTypes.array,
  openEmployeeModal: PropTypes.func,
  renderActions: PropTypes.func,
  deleteID: PropTypes.func,
  employeeData: PropTypes.array,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
};

export default EmployeeDataTable;
