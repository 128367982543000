import React, { useState, useEffect } from "react";
import TimeSheetTasks from "../../../components/common/TimeSheetTasks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  LeftOutlined,
  DownOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { useGetTasksQuery } from "../../../service/timeSheetService";
import DownloadExcel from "../../../components/common/DownloadExcel";
import moment from "moment";

function EmployeeTaskList() {
  const { id } = useParams();
  const { data } = useGetTasksQuery(id);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [group, setGroup] = useState(null);
  const [dates, setDates] = useState(null);
  const location = useLocation();
  const { empId } = location.state;

  const TaskData = data || [];
  const navigate = useNavigate();
  const uniqueMonths = new Set();
  let keyCounter = 1;

  const convertedArray = TaskData.reduce((accumulator, item) => {
    const dateObj = moment(item.date);
    const month = dateObj.format("MMMM");
    const year = dateObj.year();
    const label = `${month.toUpperCase()} ${year}`;

    const uniqueKey = `${month}-${year}`;

    if (!uniqueMonths.has(uniqueKey)) {
      uniqueMonths.add(uniqueKey);
      const key = `1-${keyCounter}`;
      accumulator.push({ key, label, month, year });
      keyCounter = keyCounter + 1;
    }
    return accumulator;
  }, []);

  const items = [
    {
      key: "1",
      type: "group",
      label: "By Month",
      children: convertedArray,
    },
  ];

  const handleMenuClick = (e) => {
    const { month, year } = convertedArray.find((item) => item.key === e.key);
    setSelectedMonth({ month, year });
  };

  function getDaysInMonth(month, year) {
    const currentDate = moment();
    const currentMonth = currentDate.month() + 1;
    const currentYear = currentDate.year();
    if (month === currentMonth && year === currentYear) {
      const yesterday = currentDate.clone().subtract(1, "day");
      return yesterday.date();
    } else {
      return moment(new Date(year, month, 0)).daysInMonth();
    }
  }

  function longMonthToNumeric(longMonth) {
    return moment(`2000 ${longMonth} 1`, "YYYY MMMM D").month() + 1;
  }

  useEffect(() => {
    if (filteredData) {
      const groupedData = filteredData.reduce((acc, obj) => {
        const dateKey = obj.date;
        const existingEntry = acc.find((entry) => entry.date === dateKey);
        const duration =
          (moment(obj.endTime).valueOf() - moment(obj.startTime).valueOf()) /
          (1000 * 60 * 60);

        if (existingEntry) {
          existingEntry.desc.push(obj.desc);
          existingEntry.duration += duration;
        } else {
          acc.push({
            date: dateKey,
            desc: [obj.desc],
            duration,
          });
        }

        return acc;
      }, []);
      groupedData.forEach((entry) => {
        entry.duration = parseFloat(entry.duration.toFixed(2));
      });
      setGroup(groupedData);
    }
    console.log(filteredData); //eslint-disable-line
  }, [filteredData]);

  function formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  const clearFilter = () => {
    setSelectedMonth(null);
  };

  useEffect(() => {
    if (selectedMonth) {
      const monthIndex = longMonthToNumeric(selectedMonth.month);
      const daysInMonth = getDaysInMonth(monthIndex, selectedMonth.year);

      const datesArray = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const dateString = `${selectedMonth.year}-${monthIndex}-${day}`;
        datesArray.push({
          date: formatDate(dateString),
        });
      }

      setDates(datesArray);
    }
  }, [selectedMonth]);

  const newArray = [];

  if (dates && group) {
    dates.forEach((dateObj, index) => {
      let matchingEntry = group.find((entry) => {
        return entry.date === dateObj.date;
      });

      let status = "Absent";
      let remark = "";

      if (matchingEntry) {
        status = "Present";

        if (matchingEntry.duration < 8) {
          remark = "Below 8 hours";
        } else {
          remark = "OK";
        }

        const taskWithNumbers = matchingEntry.desc
          .map((desc, descIndex) => {
            return `${descIndex + 1}.${desc}`;
          })
          .join(", ");

        newArray.push({
          SLNO: index + 1,
          Date: matchingEntry.date,
          Task: taskWithNumbers,
          Duration: matchingEntry.duration || 0,
          Status: status,
          Remark: remark,
        });
      } else {
        if (
          moment(dateObj.date).day() === 6 ||
          moment(dateObj.date).day() === 0
        ) {
          status = "Weekend";
        }

        newArray.push({
          SLNO: index + 1,
          Date: dateObj.date,
          Task: "-",
          Duration: "-",
          Status: status,
          Remark: remark,
        });
      }
    });
  }

  const title = `${empId}_TimeSheet_${selectedMonth?.month}_${selectedMonth?.year}.xlsx`;

  return (
    <div>
      <Button
        type="link"
        className="flex items-center p-0 text-black hover:!text-black"
        onClick={() => navigate(-1)}
      >
        <LeftOutlined /> <span className="text-base">Back</span>
      </Button>
      <div className="flex justify-between h-24 mt-4">
        <p className="text-base font-semibold mt-14 mb-4">Activities</p>
        <div className="flex justify-end space-x-2 w-2/6 mt-6">
          <Dropdown
            overlay={
              <Menu onClick={handleMenuClick}>
                {items[0].children.map((item) => (
                  <Menu.Item key={item.key}>{item.label}</Menu.Item>
                ))}
              </Menu>
            }
            className=" mt-4 bg-blue-600 !text-white"
          >
            <Button className="flex items-center h-8">
              {selectedMonth
                ? `${selectedMonth.month} ${selectedMonth.year}`
                : "All Entries"}{" "}
              <DownOutlined className="mt-1" />
            </Button>
          </Dropdown>
          {selectedMonth ? (
            <div className="flex space-x-2 ">
              <Button
                className="flex items-center mt-4 h-8 bg-red-600 !text-white"
                onClick={clearFilter}
              >
                <CloseCircleOutlined className="mt-1" />
                Clear Filter
              </Button>
              <div className="mt-4">
                <DownloadExcel excelData={newArray} title={title} />
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <TimeSheetTasks
        userId={id}
        isPermit
        selectedMonth={selectedMonth}
        setFilteredData={setFilteredData}
      />
    </div>
  );
}

export default EmployeeTaskList;