import React, { useState } from "react";
import { message, Tabs } from "antd";
import BlogBasicForm from "./BlogBasicForm";
import BlogContentForm from "./BlogContentForm";
import { useUploadFileMutation } from "../../../service/baseService";
import {
  usePostBlogMutation,
  useUpdateBlogMutation,
} from "../../../service/blogQuery";
import PropTypes from "prop-types";

export const initialFile = { file: null, list: [] };
function BlogForm({
  closeModal,
  editData,
  blog,
  setBlog,
  tableOfContent,
  setTableOfContent,
  activeKey,
  setActiveKey,
  resetForm,
  thumbnailEditor,
  contentEditor,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const nextStep = () => setActiveKey("2");
  const goBack = () => setActiveKey("1");
  // RTK Query
  const [uploadFile] = useUploadFileMutation();
  const [postBlog] = usePostBlogMutation();
  const [putBlog] = useUpdateBlogMutation();
  // condition
  const isEdit = Boolean(editData && typeof editData === "object");

  const addBlog = async () => {
    setIsLoading(true);
    try {
      const basicInformation = { ...blog };
      const content = [...tableOfContent];
      if (basicInformation.thumbnail.file) {
        const thumbnailFormData = new FormData();
        thumbnailFormData.append("file", basicInformation.thumbnail.file);
        const thumbnailResponse = await uploadFile(thumbnailFormData);
        basicInformation.thumbnail = thumbnailResponse.data;
      }
      for (const blogSection of content) {
        const images = [];
        for (const blogFile of blogSection.image) {
          const contentFileFormData = new FormData();
          contentFileFormData.append("file", blogFile.file);
          const fileResponse = await uploadFile(contentFileFormData);
          images.push(fileResponse.data);
        }
        blogSection.image = images;
      }
      const payload = {
        ...basicInformation,
        content,
      };
      const blogResponse = await postBlog(payload);
      message.success(blogResponse.data?.message || "Success");
      resetForm();
      if (typeof closeModal === "function") closeModal();
    } catch (error) {
      console.log(error); // eslint-disable-line
      message.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const updateBlog = async () => {
    setIsLoading(true);
    try {
      const basicInformation = { ...blog };
      const content = [...tableOfContent];
      const payload = {
        ...basicInformation,
        content,
      };
      const blogResponse = await putBlog(payload);
      message.success(blogResponse.data?.message || "Success");
      resetForm();
      if (typeof closeModal === "function") closeModal();
    } catch (error) {
      console.log(error); // eslint-disable-line
      message.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    if (!isEdit) {
      addBlog();
    } else {
      updateBlog();
    }
  };

  const items = [
    {
      key: "1",
      label: "Basic",
      children: (
        <BlogBasicForm
          nextStep={nextStep}
          formData={blog}
          setFormData={setBlog}
          isEdit={isEdit}
          editorInitialValue={thumbnailEditor}
        />
      ),
    },
    {
      key: "2",
      label: "Table of Content",
      children: (
        <BlogContentForm
          formData={tableOfContent}
          setFormData={setTableOfContent}
          goBack={goBack}
          onSubmit={onSubmit}
          isLoading={isLoading}
          isEdit={isEdit}
          editorInitialValue={contentEditor}
        />
      ),
    },
  ];

  return <Tabs activeKey={activeKey} items={items} />;
}

BlogForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  editData: PropTypes.any,
  blog: PropTypes.any.isRequired,
  setBlog: PropTypes.func.isRequired,
  tableOfContent: PropTypes.any.isRequired,
  setTableOfContent: PropTypes.func.isRequired,
  activeKey: PropTypes.string.isRequired,
  setActiveKey: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  thumbnailEditor: PropTypes.string.isRequired,
  contentEditor: PropTypes.string.isRequired,
};

export default BlogForm;
