import React from "react";
import { useSelector } from "react-redux";
import AdminDashboard from "./adminDashboard";
import EmployeeDashboard from "./employeeDashboard";

function Dashboard() {
  const user = useSelector((state) => state.application.user);
  const isAdmin = user?.role === "admin";
  return isAdmin ? <AdminDashboard /> : <EmployeeDashboard />;
}

export default Dashboard;
