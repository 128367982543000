import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { message, Tag, Tooltip } from "antd";
import moment from "moment";
import React, { useState } from "react";
import Datatable from "../../../components/common/Datatable";
import DeleteModal from "../../../components/common/DeleteModal";
import IconButton from "../../../components/common/IconButton";
import { statusText } from "../../../constants/appConstants";
import {
  useDeleteCompOffMutation,
  useGetCompOffQuery,
} from "../../../service/MyLeavesService";
import PropTypes from "prop-types";

function CompOffTable({ onEdit }) {
  const { data, isError, isFetching } = useGetCompOffQuery();
  const [deleteRequest, { isLoading }] = useDeleteCompOffMutation();
  const [deleteRequestId, setDeleteRequestId] = useState(null);

  const columns = [
    {
      title: "Date",
      dataIndex: "fromDate",
      key: "fromDate",
      render: (_, record) => {
        const { fromDate, toDate } = record;
        const formattedfromDate = moment(fromDate).format("DD-MM-YYYY");
        const formattedtoDate = moment(toDate).format("DD-MM-YYYY");
        if (formattedfromDate != formattedtoDate) {
          return `${moment(fromDate).format("DD-MM-YYYY")} to ${moment(
            toDate
          ).format("DD-MM-YYYY")}`;
        } else {
          return moment(fromDate).format("DD-MM-YYYY");
        }
      },
    },
    {
      title: "Duration",
      dataIndex: "compOffDuration",
      key: "compOffDuration",
      render: (value) => {
        return <div>{value} Days</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (value, record) => {
        const color = value === 0 ? "blue" : value === 1 ? "green" : "red";
        const badge = <Tag color={color}>{statusText[value]}</Tag>;
        return value === 2 && record?.rejectReason ? (
          <Tooltip placement="bottom" title={record?.rejectReason}>
            {badge}
          </Tooltip>
        ) : (
          badge
        );
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      render: (value) => {
        return <div className="w-[250px]">{value}</div>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (id, record) =>
        record.status === 0 ? (
          <div className="space-x-6 flex ">
            <IconButton
              icon={<FontAwesomeIcon icon={faPenToSquare} />}
              title="Edit"
              onClick={() => {
                if (typeof onEdit === "function") onEdit(record);
              }}
            />
            <IconButton
              icon={<FontAwesomeIcon icon={faTrashCan} />}
              title="Delete"
              onClick={() => setDeleteRequestId(id)}
            />
          </div>
        ) : (
          <div>-</div>
        ),
    },
  ];

  const handleDelete = () => {
    deleteRequest({ id: deleteRequestId })
      .unwrap()
      .then((response) => {
        const mes = response?.message ? response.message : "Success";
        message.success(mes);
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
      })
      .finally(() => {
        setDeleteRequestId(null);
      });
  };

  return (
    <div>
      <DeleteModal
        title="Are you Sure"
        message="You want to delete this request"
        visible={Boolean(deleteRequestId)}
        onOk={handleDelete}
        onCancel={() => setDeleteRequestId(null)}
        isLoading={isLoading}
      />
      <Datatable
        tableData={data}
        columns={columns}
        isError={isError}
        isLoading={isFetching}
        size="small"
        pagination={{
          pageSize: 5,
        }}
      />
    </div>
  );
}

CompOffTable.propTypes = {
  onEdit: PropTypes.func,
};

export default CompOffTable;
