import React, { useState } from "react";
import { Button, Form, Input, Modal, Upload, message } from "antd";
import PropTypes from "prop-types";
import {
  UserOutlined,
  MailOutlined,
  ContactsOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../assets/styles/main.css";
import {
  usePostAssociateMutation,
  useUpdateAssociateMutation,
} from "../../service/clientService";

const AssociateModal = ({
  visible,
  onOk,
  onCancel,
  title,
  clientData,
  editData,
}) => {
  const [form] = Form.useForm();
  const [phoneForm, setPhoneform] = useState();
  const [country, setCountry] = useState();
  const [addAssociate, { isLoading: addLoading }] = usePostAssociateMutation();
  const [updateAssociate, { isLoading: updateLoading }] =
    useUpdateAssociateMutation();

  if (editData) {
    const { name, email, countryCode, phone } = editData;
    form.setFieldsValue({
      name,
      email,
      phone: `${countryCode}${phone}`,
    });
  }

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const reducedPhone = phoneForm.replace(country, "");

      if (editData) {
        onOk(values, editData._id);
        const payload = {
          clientId: clientData._id,
          associateId: editData._id,
          values:{ ...values, countryCode: country, phone: reducedPhone },
        };
        updateAssociate(payload)
          .then(() => {
            message.success("Successfully Updated");
          })
          .catch((error) => {
            console.log(error); //eslint-disable-line
            message.error("Something went wrong");
          })
          .finally(() => {
            form.resetFields();
          });
      } else {
        onOk({ ...values, countryCode: country, phone: reducedPhone });
        const payload = {
          clientId: clientData._id,
          values: { ...values, countryCode: country, phone: reducedPhone },
        };
        addAssociate(payload)
          .then(() => {
            message.success("Successfully Added");
          })
          .catch((error) => {
            console.log(error); //eslint-disable-line
            message.error("Something went wrong");
          })
          .finally(() => {
            form.resetFields();
          });
      }
    } catch (error) {
      console.log(error); // eslint-disable-line
    }
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const footerContent = (
    <div className="flex">
      <Button className="w-1/2" size="large" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        className="bg-blue-500 w-1/2"
        onClick={handleOk}
        loading={addLoading || updateLoading}
      >
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footerContent}
    >
      <div className="overflow-x-auto">
        <Form form={form} className="p-3" layout="vertical">
          <Form.Item
            label="Associate_Name"
            name="name"
            rules={[
              { required: true, message: "Please enter the Associate_Name " },
            ]}
          >
            <Input
              placeholder="Associate Name"
              suffix={<UserOutlined />}
              className="w-full"
            />
          </Form.Item>

          <div className="flex justify-between gap-2">
            <Form.Item
              className="w-full"
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Please enter the email" },
                { type: "email", message: "Invalid email format" },
              ]}
            >
              <Input
                placeholder="Email"
                suffix={<MailOutlined />}
                type="email"
              />
            </Form.Item>
          </div>

          <div className="flex justify-between gap-2 ">
            <Form.Item
              label="Contact"
              name="phone"
              rules={[{ required: true, message: "Please enter POC_Contact" }]}
              className="w-full"
            >
              <PhoneInput
                country={"in"}
                suffix={<ContactsOutlined />}
                onChange={(phone, country) => {
                  setPhoneform(phone);
                  setCountry(country.dialCode);
                }}
              />
            </Form.Item>
          </div>

          <div className="flex justify-between gap-2">
            <Form.Item className="w-full hidden" label="Upload Image" name="image">
              <Upload name="file">
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

AssociateModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  clientData: PropTypes.object,
  editData: PropTypes.object,
};

export default AssociateModal;
