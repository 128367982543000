import React, { useState } from "react";
import { Layout } from "antd";
import MainLayoutSidebar from "./MainLayoutSidebar";
import PropTypes from "prop-types";
import MainLayoutHeader from "./MainLayoutHeader";
const { Content } = Layout;

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Layout hasSider>
      <MainLayoutSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      <Layout
        className="site-layout transition-all"
        style={{
          marginLeft: collapsed ? 80 : 200,
        }}
      >
        <MainLayoutHeader />
        <Content
          style={{
            margin: "24px 16px 0",
            overflow: "initial",
          }}
        >
          <div>{children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element,
};

export default MainLayout;
