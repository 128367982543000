import React from "react";
import DashboardCardTitle from "../../DashboardCardTitle";
import ReactApexChart from "react-apexcharts";
import { Card } from "antd";

function ClientSurvayChart() {
  const series = [
    {
      name: "New Clients",
      data: [30, 40, 25, 50, 49, 21, 70, 51],
    },
    {
      name: "Old Clients",
      data: [23, 12, 54, 61, 32, 56, 81, 19],
    },
  ];

  const options = {
    chart: {
      type: "area",
      height: 350,
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug"],
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
      },
    },
    markers: {
      size: 4,
    },
    legend: {
      position: "bottom",
      offsetY: 0,
    },
    colors: ["#FC8380", "#6973C6"],
  };

  return (
    <Card className="w-1/2 bg-white min-h-50px relative mb-5 border border-solid border-f2f4f9 rounded-10px">
      <DashboardCardTitle title="Client Survey" />
      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={350}
      />
    </Card>
  );
}

export default ClientSurvayChart;
