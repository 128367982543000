import React from "react";
import { Card } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import DashboardCardTitle from "../../DashboardCardTitle";
import Datatable from "../../../../components/common/Datatable";
import IconButton from "../../../../components/common/IconButton";

const columns = [
  { title: "Project Name", dataIndex: "projectName" },
  { title: "Employees Team", dataIndex: "employeesTeam" },
  {
    title: "Priority",
    dataIndex: "priority",
    render: (_, record) => {
      if (record.priority === "Low") {
        return (
          <p className="bg-gradient-to-r from-[#02AAB0] to-[#00CDAC] w-20 text-white rounded-lg text-center">
            {record.priority}
          </p>
        );
      } else if (record.priority === "High") {
        return (
          <p className="bg-gradient-to-r from-[#e65c00] to-[#F9D423] w-20 text-white rounded-lg text-center">
            {record.priority}
          </p>
        );
      } else {
        return (
          <p className="bg-gradient-to-r from-[#654ea3] to-[#c471ed] w-20 text-white rounded-lg text-center">
            {record.priority}
          </p>
        );
      }
    },
  },
  { title: "Open Task", dataIndex: "openTask" },
  { title: "Completed Task", dataIndex: "completedTask" },
  {
    title: "Action",
    dataIndex: "action",
    render: () => (
      <div className="flex space-x-2.5">
        <IconButton icon={<FontAwesomeIcon icon={faPenToSquare} />} />
        <IconButton icon={<FontAwesomeIcon icon={faTrashCan} />} />
      </div>
    ),
  },
];

const data = [
  {
    projectName: "Project A",
    employeesTeam: "img",
    priority: "Low",
    openTask: "19",
    completedTask: "39",
    action: "edit",
  },
  {
    projectName: "Project B",
    employeesTeam: "img",
    priority: "Medium",
    openTask: "10",
    completedTask: "29",
    action: "edit",
  },
  {
    projectName: "Project C",
    employeesTeam: "img",
    priority: "High",
    openTask: "9",
    completedTask: "10",
    action: "edit",
  },
  {
    projectName: "Project D",
    employeesTeam: "img",
    priority: "Low",
    openTask: "100",
    completedTask: "9",
    action: "edit",
  },
  {
    projectName: "Project D",
    employeesTeam: "img",
    priority: "Medium",
    openTask: "100",
    completedTask: "9",
    action: "edit",
  },
];

function Project() {
  return (
    <div className="gap-5">
      <Card className=" bg-white min-h-50px relative mb-5 border border-solid border-f2f4f9 rounded-10px">
        <DashboardCardTitle title="Projects" />
        <section className="mt-10 customizedTable">
          <Datatable
            tableData={data}
            columns={columns}
            title="Projects"
            pagination={false}
          />
        </section>
      </Card>
    </div>
  );
}

export default Project;
