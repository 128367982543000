import React from "react";
import { CheckSquareFilled } from "@ant-design/icons";

function EmployeeCards() {
  return (
    <div className="mt-4 flex flex-wrap -mx-2">
      <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 mb-4">
        <div className="bg-gradient-to-r from-purple-600 to-purple-400 bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-4 text-white">New Tickets</h2>
          <div className="flex items-center justify-between">
            <p className="text-lg font-bold text-white">23</p>
            <p className="text-lg bold text-white">
              <CheckSquareFilled />
            </p>
          </div>
          <div className="mt-3 text-white">
            <p>10% Higher Then Last Month.</p>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 mb-4">
        <div className="bg-gradient-to-r from-orange-600 to-orange-400 bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-4 text-white">
            Tickets Resolved
          </h2>
          <div className="flex items-center justify-between text-white">
            <p className="text-lg font-bold">23</p>
            <p className="text-lg bold text-white">
              <CheckSquareFilled />
            </p>
          </div>
          <div className="mt-3 text-white">
            <p>10% Higher Then Last Month.</p>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 mb-4">
        <div className="bg-gradient-to-r from-green-600 to-green-400 bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-4 text-white">
            Project Assigned
          </h2>
          <div className="flex items-center justify-between">
            <p className="text-lg font-bold text-white">23</p>
            <p className="text-lg bold text-white">
              <CheckSquareFilled />
            </p>
          </div>
          <div className="mt-3 text-white">
            <p>10% Higher Then Last Month.</p>
          </div>
        </div>
      </div>
      <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 px-2 mb-4">
        <div className="bg-gradient-to-r from-blue-600 to-blue-400 bg-white p-6 rounded-md shadow-md">
          <h2 className="text-xl font-bold mb-4 text-white">
            Available Leaves
          </h2>
          <div className="flex items-center justify-between text-white">
            <p className="text-lg font-bold">23</p>
            <p className="text-lg bold">
              <CheckSquareFilled />
            </p>
          </div>
          <div className="mt-3 text-white">
            <p>10% Higher Then Last Month.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeCards;
