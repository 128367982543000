import React, { useEffect, useMemo, useState } from "react";
import { useGetLeaveApprovalsQuery } from "../../../service/MyLeavesService";
import moment from "moment";
import { Button, Dropdown, Menu } from "antd";
import { DownOutlined, CloseCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import DownloadExcel from "../../../components/common/DownloadExcel";

function LeaveFilters({
  selectedMonth,
  selectedEmployee,
  setSelectedMonth,
  setSelectedEmployee,
  excelData
}) {
  const [employeeId, setEmployeeId] = useState(null);
  const { data } = useGetLeaveApprovalsQuery();

  const employeeChildren = useMemo(() => {
    return data?.map((employee, index) => ({
      key: `employee-${index + 1}`,
      value: `${employee.user.firstName} ${employee.user.lastName}`,
      label: `${employee.user.firstName} ${employee.user.lastName}`,
    }));
  }, [data]);

  const monthYear = useMemo(() => {
    const uniqueKeys = new Set();
    return data?.reduce((accumulator, item, index) => {
      const key = `month-${index + 1}`;
      const date = moment(item.fromDate);
      const label = date.format("MMMM YYYY");
      if (!uniqueKeys.has(label)) {
        uniqueKeys.add(label);
        accumulator.push({ key, value: label, label });
      }
      return accumulator;
    }, []);
  }, [data]);

  const handleMenuItemClick = (type, value) => {
    if (type === "employee") {
      setSelectedEmployee(value);
    } else if (type === "month") {
      setSelectedMonth(value);
    }
  };

  const renderSubMenuItems = (items, type) => {
    return items?.map((item) => (
      <Menu.Item key={item.key} onClick={() => handleMenuItemClick(type, item.value)}>
        {item.label}
      </Menu.Item>
    ));
  };

  const renderMenuItems = useMemo(() => {
    return (
      <Menu>
        <Menu.SubMenu key="employee" title="Employee">
          {renderSubMenuItems(employeeChildren, "employee")}
        </Menu.SubMenu>
        <Menu.SubMenu key="month" title="Month">
          {renderSubMenuItems(monthYear, "month")}
        </Menu.SubMenu>
      </Menu>
    );
  }, [employeeChildren, monthYear]);

  const handleClearFilter = () => {
    setSelectedEmployee(null);
    setSelectedMonth(null);
  };

  useEffect(() => {
    if (selectedEmployee && data) {
      const [selectedFirstName, selectedLastName] = selectedEmployee.split(" ");
      const matchingEmployee = data.find(employee =>
        employee.user.firstName === selectedFirstName && employee.user.lastName === selectedLastName
      );
      const empId = matchingEmployee ? matchingEmployee.user.empId : null;
      setEmployeeId(empId);
    }
  }, [selectedEmployee, data]);

  const title = `${employeeId}_Leave.xlsx`;

  return (
    <div className="flex space-x-2">
      <Dropdown overlay={renderMenuItems} className="bg-blue-600 text-white">
        <Button className="flex items-center h-8">
          Filter data <DownOutlined className="mt-1" />
        </Button>
      </Dropdown>
      {(selectedMonth !== null || selectedEmployee !== null) && (
        <Button className="flex items-center h-8 bg-red-600 text-white" onClick={handleClearFilter}>
          <CloseCircleOutlined />
          Clear Filter
        </Button>
      )}
      {selectedMonth !== null && selectedEmployee !== null && (
        <DownloadExcel excelData={excelData} title={title}/>
      )}
    </div>
  );
}

LeaveFilters.propTypes = {
  setSelectedEmployee: PropTypes.func.isRequired,
  setSelectedMonth: PropTypes.func.isRequired,
  selectedMonth: PropTypes.string,
  selectedEmployee: PropTypes.string,
  excelData: PropTypes.object
};

export default LeaveFilters;
