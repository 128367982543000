import baseReducer from "../reducers/baseReducer";
import { baseService } from "../service/baseService";
import { timeSheetService } from "../service/timeSheetService";
import { eventService } from "../service/eventService";
import { myLeaveService } from "../service/MyLeavesService";
import { employeeService } from "../service/employeeService";
import { myPermissionService } from "../service/MyPermissionService";
import { projectService } from "../service/projectService";
import { clientService } from "../service/clientService";
import { invoiceService } from "../service/invoiceService";
import { holidaysService } from "../service/holidaysService";
import { roleService } from "../service/roleService";
import { departmentService } from "../service/departmentService";
import { blogService } from "../service/blogQuery";

const rootReducer = {
  [baseService.reducerPath]: baseService.reducer,
  [eventService.reducerPath]: eventService.reducer,
  [timeSheetService.reducerPath]: timeSheetService.reducer,
  [projectService.reducerPath]: projectService.reducer,
  [employeeService.reducerPath]: employeeService.reducer,
  [myLeaveService.reducerPath]: myLeaveService.reducer,
  [myPermissionService.reducerPath]: myPermissionService.reducer,
  [clientService.reducerPath]: clientService.reducer,
  [invoiceService.reducerPath]: invoiceService.reducer,
  [holidaysService.reducerPath]: holidaysService.reducer,
  [roleService.reducerPath]: roleService.reducer,
  [departmentService.reducerPath]: departmentService.reducer,
  [blogService.reducerPath]: blogService.reducer,

  application: baseReducer,
};

export default rootReducer;
