import moment from "moment";
import React, { useState } from "react";
import Datatable from "../../../components/common/Datatable";
import Header from "../../../components/common/Header";
import IconButton from "../../../components/common/IconButton";
import {
  useGetCompOffApprovalsQuery,
  usePutCompOffApprovalsMutation,
} from "../../../service/MyLeavesService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Input, message } from "antd";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../../components/common/DeleteModal";
import { ReloadOutlined } from "@ant-design/icons";

const initialConfirmModal = {
  flag: false,
  id: null,
  status: null,
};
const initialReason = {
  value: "",
  isError: false,
};
const { TextArea } = Input;
function CompOffApprovals() {
  const [confirmModal, setConfirmModal] = useState(initialConfirmModal);
  const { data, isError, isFetching, refetch } = useGetCompOffApprovalsQuery();
  const [updateCompOff, { isLoading }] = usePutCompOffApprovalsMutation();
  const [reason, setReason] = useState(initialReason);

  const handleAccept = (id) => {
    setConfirmModal({
      id,
      status: 1,
      flag: true,
    });
  };

  const handleReject = (id) => {
    setConfirmModal({
      id,
      status: 2,
      flag: true,
    });
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "fromDate",
      key: "fromDate",
      render: (_, record) => {
        const { fromDate, toDate } = record;
        const formattedfromDate = moment(fromDate).format("DD-MM-YYYY");
        const formattedtoDate = moment(toDate).format("DD-MM-YYYY");
        if (formattedfromDate != formattedtoDate) {
          return `${moment(fromDate).format("DD-MM-YYYY")} to ${moment(
            toDate
          ).format("DD-MM-YYYY")}`;
        } else {
          return moment(fromDate).format("DD-MM-YYYY");
        }
      },
    },
    {
      title: "Duration",
      dataIndex: "compOffDuration",
      key: "compOffDuration",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Applied On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => {
        return (
          <div>{moment(new Date(value)).format("DD-MM-YYYY hh:mm A")}</div>
        );
      },
    },
    {
      title: "Employee",
      dataIndex: "user",
      key: "user",
      render: ({ firstName, lastName }) => {
        return (
          <div>
            {firstName} {lastName}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      key: "action",
      render: (id) => (
        <div className="space-x-6 flex ">
          <IconButton
            icon={<FontAwesomeIcon icon={faCheck} />}
            title="Accept"
            onClick={() => handleAccept([id])}
          />
          <IconButton
            icon={<FontAwesomeIcon icon={faXmark} />}
            title="Reject"
            onClick={() => handleReject([id])}
          />
        </div>
      ),
    },
  ];

  const handleReasonChange = (e) => {
    const { value } = e.target;
    setReason({ value, isError: false });
  };

  const handleCancel = () => {
    setConfirmModal(initialConfirmModal);
    setReason(initialReason);
  };

  const handleConfirm = () => {
    const { status, id } = confirmModal;
    const { value } = reason;
    if (status === 2 && !value) {
      setReason((prev) => ({ ...prev, isError: true }));
      return;
    }
    updateCompOff({ status, id, reason: value })
      .unwrap()
      .then(() => {
        message.success("Status Updated");
      })
      .catch(() => {
        message.error("Something went wrong");
      })
      .finally(() => {
        handleCancel();
      });
  };

  const renderActions = (_id) => {
    return (
      <div className="flex space-x-2 items-center">
        <Button
          className="flex items-center  bg-blue-600 !text-white"
          onClick={refetch}
        >
          <ReloadOutlined />
          Refresh
        </Button>
        {_id.length > 1 && (
          <div className="flex space-x-3 items-center">
            <Button
              className="flex items-center  bg-green-600 !text-white"
              onClick={() => handleAccept(_id)}
            >
              <FontAwesomeIcon icon={faCheck} className="mr-2" />
              Accept
            </Button>

            <Button
              className="flex items-center  bg-red-600 !text-white"
              onClick={() => handleReject(_id)}
            >
              <FontAwesomeIcon className="mr-2" icon={faXmark} />
              Reject
            </Button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="customizedTable">
      <DeleteModal
        visible={confirmModal.flag}
        onOk={handleConfirm}
        onCancel={handleCancel}
        title="Request"
        message={
          confirmModal.status === 1
            ? "Are you sure want to accept?"
            : "Sure you want to reject? Please provide a reason."
        }
        confirmButtonText={confirmModal.status === 1 ? "Accept" : "Reject"}
        isLoading={isLoading}
        confirmButtonClass={
          confirmModal.status === 1 ? "bg-green-600 text-white" : undefined
        }
        renderBody={
          confirmModal.status === 2
            ? () => {
                return (
                  <div className="my-2">
                    <TextArea
                      placeholder="Type reason here.."
                      allowClear
                      onChange={handleReasonChange}
                      value={reason.value}
                      status={reason.isError && "error"}
                    />
                  </div>
                );
              }
            : null
        }
      />
      <Header title="CompOff" />
      <section className="mt-4">
        <Datatable
          tableData={data}
          columns={columns}
          checkBox
          rowSelection
          title="CompOff Approvals"
          renderActions={renderActions}
          isError={isError}
          isLoading={isFetching}
        />
      </section>
    </div>
  );
}

export default CompOffApprovals;
