import React, { useState,useEffect } from "react";
import { Card, Select, Button } from "antd";
import Header from "../../components/common/Header";
import { DatePicker } from "antd";

const { RangePicker } = DatePicker;
const { Option } = Select;

const Payslipemp = () => {
  const [selectedOption, setSelectedOption] = useState("lastMonth");
  const [customDateRange, setCustomDateRange] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(filterData());
  }, [selectedOption, customDateRange]);

  const generateCardData = () => {
    const startDate = new Date("2022-01-01");
    const endDate = new Date("2024-10-01");
    const cardData = [];

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const formattedDate = `${
        currentDate.getMonth() + 1
      }-${currentDate.getFullYear()}`;

      cardData.push({
        id: cardData.length + 1,
        date: formattedDate,
      });

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return cardData;
  };

  const cardData = generateCardData();

  const handleDropdownChange = (value) => {
    setSelectedOption(value);
  };

  const handleCustomDateChange = (dates) => {
    setCustomDateRange(dates);
  };

  const filterDataByDateRange = (startDate, endDate) => {
    const filteredMonths = [];

    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      const formattedDate = `${
        currentDate.getMonth() + 1
      }-${currentDate.getFullYear()}`;
      filteredMonths.push(formattedDate);
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return cardData.filter((data) => filteredMonths.includes(data.date));
  };

  const getLastMonthsData = (months) => {
    const lastMonthsData = [];
    for (let i = 1; i <= months; i++) {
      const monthDate = new Date();
      monthDate.setMonth(monthDate.getMonth() - i);
      const formattedMonth = `${
        monthDate.getMonth() + 1
      }-${monthDate.getFullYear()}`;
      const monthData = cardData.find((data) => data.date === formattedMonth);
      if (monthData) {
        lastMonthsData.push(monthData);
      }
    }
    return lastMonthsData;
  };

  const filterData = () => {
    switch (selectedOption) {
      case "lastMonth":
        return getLastMonthsData(1);

      case "lastThreeMonth":
        return getLastMonthsData(3);

      case "lastSixMonth":
        return getLastMonthsData(6);

      case "CustomMonth":
        if (customDateRange) {
          const [startDate, endDate] = customDateRange.map((date) =>
            date.toDate()
          );
          return filterDataByDateRange(startDate, endDate);
        } else {
          return [];
        }

      default:
        return [];
    }
  };

  return (
    <div>
      <Header title="Payslip" />
      <div className="flex bg-[#DAE1F3] items-center text-[#575a5e] p-3 mt-10">
        <p className="text-lg font-normal">Payslip</p>

        <Select
          className="w-48 pl-5"
          placeholder="Select an option"
          onChange={handleDropdownChange}
          defaultValue={selectedOption}
        >
          <Option value="lastMonth">Latest</Option>
          <Option value="lastThreeMonth">Last Three Months</Option>
          <Option value="lastSixMonth">Last Six Months</Option>
          <Option value="CustomMonth">Custom Year</Option>
        </Select>
        {selectedOption === "CustomMonth" && (
          <div className="pl-3">
            <RangePicker
              picker="month"
              format={"MM-YYYY"}
              onChange={handleCustomDateChange}
            />
          </div>
        )}
      </div>

      <div className="p-10 grid grid-cols-3 gap-5">
        {filteredData.map((data) => (
          <Card
            key={data.id}
            title={data.date}
            style={{ marginBottom: 16 }}
            className="p-7 w-full flex flex-col items-center"
          >
            <div>{data.date}</div>
            <div className="mt-auto">
              <Button type="primary" shape="round" className="mt-4 bg-blue-500">
                VIEW PAYSLIP
              </Button>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default Payslipemp;
