import React from "react";
import { useRoutes } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import Login from "../pages/Login";
import PrivateRoute from "../utils/PrivateRoute";
import Attendance from "../pages/Attendance";
import { ROUTE_CONSTANTS } from "../constants/routeConstants";
import Payroll from "../pages/Payroll";
import Payslip from "../pages/Payroll/Payslip";
import AllLeaveRequest from "../pages/LeaveManagement/AllLeaveRequest";
import LeaveTypes from "../pages/LeaveManagement/LeaveTypes";
import MyTeam from "../pages/MyTeams";
import Clients from "../pages/Clients";
import MyLeaves from "../pages/MyLeaves";
import Project from "../pages/Projects/Project";
import MyTasks from "../pages/MyTasks";
import Employees from "../pages/Employees";
import EmployeeProfile from "../pages/Employees/EmployeeProfile";
import Invoices from "../pages/Invoices";
import Invoice from "../pages/Invoices/Invoice";
import ErrorRoute from "../components/common/ErrorRoute";
import TimeSheet from "../pages/TimeSheet";
import Holidays from "../pages/Holidays/AllHolidays";
import Payslipemp from "../pages/PaySlipEmp";
import Profile from "../pages/Clients/profile";
import Event from "../pages/Events";
import Dashboard from "../pages/Dashboard";
import LeaveCalendar from "../pages/LeaveCalendar";
import Permission from "../pages/Permission";
import LeaveApprovals from "../pages/Approvals/LeaveApprovals";
import PermissionApprovals from "../pages/Approvals/PermissionApprovals";
import TimeSheetApprovals from "../pages/Approvals/TimeSheetApprovals";
import EmployeeTaskList from "../pages/Approvals/TimeSheetApprovals/EmployeeTaskList";
import Calendar from "../pages/Events/EventCalendar";
import HolidaysCalendar from "../pages/Holidays/Calendar";
import PermissionDenied from "../pages/PermissionDenied";
import CompOffApprovals from "../pages/Approvals/CompOffApprovals";
import Blogs from "../pages/Blogs";
import Portfolio from "../pages/Portfolio";

const routes = [
  {
    path: ROUTE_CONSTANTS.HOME,
    element: <LandingPage />,
  },
  {
    path: ROUTE_CONSTANTS.LOGIN,
    element: <Login />,
  },
  {
    path: ROUTE_CONSTANTS.ACCESS_DENIED,
    element: <PermissionDenied />,
  },
  {
    path: ROUTE_CONSTANTS.HOME,
    element: <PrivateRoute />,
    children: [
      {
        path: ROUTE_CONSTANTS.DASH,
        element: <Dashboard />,
      },
      {
        path: ROUTE_CONSTANTS.ALLPROJECTS,
        element: <Project />,
      },
      {
        path: ROUTE_CONSTANTS.EMPLOYEES,
        element: <Employees />,
      },
      {
        path: ROUTE_CONSTANTS.ATTENDANCE,
        element: <Attendance />,
      },
      {
        path: ROUTE_CONSTANTS.PAYROLL,
        element: <Payroll />,
      },
      {
        path: ROUTE_CONSTANTS.PAYSLIP,
        element: <Payslip />,
      },
      {
        path: ROUTE_CONSTANTS.ALLLEAVEREQUEST,
        element: <AllLeaveRequest />,
      },
      {
        path: ROUTE_CONSTANTS.LEAVETYPES,
        element: <LeaveTypes />,
      },
      {
        path: ROUTE_CONSTANTS.CLIENTS,
        element: <Clients />,
      },
      {
        path: ROUTE_CONSTANTS.MYTEAMS,
        element: <MyTeam />,
      },
      {
        path: ROUTE_CONSTANTS.MYTASKS,
        element: <MyTasks />,
      },
      {
        path: ROUTE_CONSTANTS.EMPLOYEES,
        element: <Employees />,
      },
      {
        path: ROUTE_CONSTANTS.EMPLOYEEPROFILE,
        element: <EmployeeProfile readOnly />,
      },
      {
        path: ROUTE_CONSTANTS.INVOICE,
        element: <Invoice />,
      },
      {
        path: ROUTE_CONSTANTS.ALL_INVOICES,
        element: <Invoices />,
      },

      {
        path: ROUTE_CONSTANTS.TIMESHEET,
        element: <TimeSheet />,
      },
      {
        path: ROUTE_CONSTANTS.HOLIDAYS,
        element: <Holidays />,
      },
      {
        path: ROUTE_CONSTANTS.HOLIDAYS_CALENDAR,
        element: <HolidaysCalendar />,
      },
      {
        path: ROUTE_CONSTANTS.PAYSLIPEMP,
        element: <Payslipemp />,
      },
      {
        path: ROUTE_CONSTANTS.PROFILE,
        element: <Profile />,
      },
      {
        path: ROUTE_CONSTANTS.EVENTS,
        element: <Event />,
      },
      {
        path: ROUTE_CONSTANTS.MYACCOUNT,
        element: <EmployeeProfile />,
      },
      {
        path: ROUTE_CONSTANTS.LEAVE,
        element: <MyLeaves />,
      },
      {
        path: ROUTE_CONSTANTS.LEAVE_CALENDAR,
        element: <LeaveCalendar />,
      },
      {
        path: ROUTE_CONSTANTS.PERMISSION,
        element: <Permission />,
      },
      {
        path: ROUTE_CONSTANTS.APPROVALS_LEAVE,
        element: <LeaveApprovals />,
      },
      {
        path: ROUTE_CONSTANTS.APPROVALS_COMPOFF,
        element: <CompOffApprovals />,
      },
      {
        path: ROUTE_CONSTANTS.APPROVALS_PERMISSION,
        element: <PermissionApprovals />,
      },
      {
        path: ROUTE_CONSTANTS.APPROVALS_TIMESHEET,
        element: <TimeSheetApprovals />,
      },
      {
        path: ROUTE_CONSTANTS.APPROVALS_TIMESHEET_EMP,
        element: <EmployeeTaskList />,
      },
      {
        path: ROUTE_CONSTANTS.EVENT_CALENDAR,
        element: <Calendar />,
      },
      {
        path: ROUTE_CONSTANTS.BLOG,
        element: <Blogs />,
      },
      {
        path: ROUTE_CONSTANTS.PORTFOLIO,
        element: <Portfolio />,
      },
    ],
  },
  {
    path: "*",
    element: <ErrorRoute />,
  },
];

function Routes() {
  const content = useRoutes(routes);
  return content;
}

export default Routes;
