import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal, Button, Steps, message, Form } from "antd";
import EmpModalInfoPage from "./EmpModalInfoPage";
import EmpModalEduPage from "./EmpModalEduPage";
import EmpModalSkillPage from "./EmpModalSkillPage";
import EmpModalExpPage from "./EmpModalExpPage";
import { useRef } from "react";
const EmployeeModal = ({
  visible,
  onSave,
  onCancel,
  selectedData,
  isLoading,
}) => {
  const [employee, setEmployee] = useState(null);
  const [form] = Form.useForm();
  const empDetailsPageReferance = useRef(null);
  const empEducationDetailsPageReferance = useRef(null);
  const empSkillDetailsPageReferance = useRef(null);
  const empExpDetailsPageReferance = useRef(null);
  const resetView = () => {
    setCurrent(0);
  };

  const handleOk = () => {
    const referance = current === 3 ? empExpDetailsPageReferance : null;
    referance?.current
      ?.goNext()
      .then((values) => {
        onSave(values, employee, resetView);
        setEmployee((prev) => ({ ...prev, ...values }));
      })
      .catch(() => {
        message.error("Field should not be empty");
      });
  };
  if (selectedData) {
    form.setFieldsValue(selectedData);
  }
  const handleCancel = () => {
    onCancel();
    setCurrent(0);
    form.resetFields();
  };
  const [current, setCurrent] = useState(0);
  const next = () => {
    const referance =
      current === 0
        ? empDetailsPageReferance
        : current === 1
        ? empEducationDetailsPageReferance
        : current === 2
        ? empSkillDetailsPageReferance
        : empExpDetailsPageReferance;

    referance?.current
      ?.goNext()
      .then((values) => {
        setEmployee((prev) => ({ ...prev, ...values }));
        setCurrent(current + 1);
      })
      .catch(() => {
        message.error("Field should not be empty");
      });
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const steps = [
    {
      title: "User Detail",
      content: (
        <EmpModalInfoPage
          selectedData={employee}
          ref={empDetailsPageReferance}
          form={form}
        />
      ),
    },
    {
      title: "Education",
      content: (
        <EmpModalEduPage
          employee={employee}
          ref={empEducationDetailsPageReferance}
        />
      ),
    },
    {
      title: "Skills",
      content: (
        <EmpModalSkillPage
          employee={employee}
          ref={empSkillDetailsPageReferance}
        />
      ),
    },
    {
      title: "Experience",
      content: (
        <EmpModalExpPage employee={employee} ref={empExpDetailsPageReferance} />
      ),
    },
  ];
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const footerContent = (
    <div className="mt-5 pr-10 w-full flex justify-end">
      {current > 0 && (
        <Button className="mx-3 my-0" onClick={() => prev()}>
          Previous
        </Button>
      )}
      {current < steps.length - 1 && (
        <Button className="bg-blue-600 text-white" onClick={() => next()}>
          Next
        </Button>
      )}
      {current === steps.length - 1 && (
        <Button
          type="primary"
          className="text-white bg-blue-600"
          onClick={handleOk}
          loading={isLoading}
        >
          Save
        </Button>
      )}
    </div>
  );
  useEffect(() => {
    if (selectedData && Object.keys(selectedData).length > 0 && !employee) {
      setEmployee(selectedData);
    }
  }, [selectedData]);

  return (
    <Modal
      title="Employee"
      open={visible}
      onCancel={handleCancel}
      width={1000}
      footer={footerContent}
    >
      <Steps current={current} items={items} />
      <div>{steps[current].content}</div>
    </Modal>
  );
};
EmployeeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onEdit: PropTypes.func,
  selectedData: PropTypes.object,
  isLoading: PropTypes.bool,
};
export default EmployeeModal;
