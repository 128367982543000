import React, { useState, useEffect } from "react";
import Datatable from "../../components/common/Datatable";
import { PlusOutlined, ReloadOutlined, MoreOutlined } from "@ant-design/icons";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { useNavigate } from "react-router-dom";
import InvoiceForm from "./InvoiceForm";
import { Button, Modal, message, Tag, Dropdown, Menu } from "antd";
import Header from "../../components/common/Header";
import DeleteModal from "../../components/common/DeleteModal";
import {
  useGetInvoicesQuery,
  usePostInvoiceMutation,
  useSendReminderEmailMutation,
  useUpdatePaymentStatusMutation,
  useDeleteInvoiceMutation,
} from "../../service/invoiceService";
import moment from "moment/moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faEye,
  faSquareCheck,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
const initialState = {
  accountModalVisible: false,
  paymentData: null,
  modalVisible: false,
  emailModalVisible: false,
  confirmationModalVisible: false,
  selectedRecord: null,
  deleteModalVisible: false,
};

function Invoice() {
  const navigate = useNavigate();
  const { data, refetch } = useGetInvoicesQuery();
  const [addInvoice, { isLoading: addLoading }] = usePostInvoiceMutation();
  const [sendReminderEmail] = useSendReminderEmailMutation();
  const [updateInvoice, { isLoading: updateLoading }] =
    useUpdatePaymentStatusMutation();

  const payment = data ? data : [];

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (payment.length > 0) {
      setState((prevState) => ({ ...prevState, paymentData: payment }));
    }
  }, [payment]);

  const openAddAccountModal = () =>
    setState({ ...state, accountModalVisible: true });
  const closeAccountModal = () =>
    setState({ ...state, accountModalVisible: false });

  const handleAccountAction = (values) => {
    addInvoice(values)
      .unwrap()
      .then(() => message.success("Successfully added"))
      .catch(() => message.error("Something went wrong"))
      .finally(() => {
        closeAccountModal();
        setState(initialState);
      });
  };

  const handleCheckboxChange = (record) => {
    if (record.status !== "Completed") {
      setState({
        ...state,
        confirmationModalVisible: true,
        selectedRecord: record,
      });
    }
  };

  const handleConfirmationOk = () => {
    updateInvoice(state.selectedRecord._id)
      .unwrap()
      .then(() => {
        message.success("Your Invoice Paid and completed");
        setState({ confirmationModalVisible: false, selectedRecord: null });
      })
      .catch(() => {
        message.error("Something went wrong");
      });
  };

  const handleConfirmationCancel = () =>
    setState({
      ...state,
      confirmationModalVisible: false,
      selectedRecord: null,
    });

  const handleEmailReminder = (email) => {
    setState({ ...state, emailModalVisible: true, selectedEmail: email.email });
    sendReminderEmail(email);
  };

  const handleEmailReminderOk = () => {
    setState({ ...state, emailModalVisible: false });
    message.success("Email Reminder send!");
  };

  const handleEmailReminderCancel = () =>
    setState({ ...state, emailModalVisible: false });

  const handleReload = () => {
    refetch();
  };

  const [deleteInvoice, { isLoading: deleteLoading }] =
    useDeleteInvoiceMutation();
  const openDeleteModal = (id) => {
    setState({ ...state, id, deleteModalVisible: true });
  };
  const footerContentEmailReminder = (
    <div className="mt-5">
      <Button
        type="primary"
        className="bg-blue-500"
        onClick={handleEmailReminderOk}
      >
        Ok
      </Button>
      <Button className="ml-2" onClick={handleEmailReminderCancel}>
        Cancel
      </Button>
    </div>
  );
  const footerContentCheckboxPaidReminder = (
    <div className="mt-5">
      <Button
        type="primary"
        className="bg-blue-500"
        onClick={handleConfirmationOk}
      >
        Confirm
      </Button>
      <Button className="ml-2" onClick={handleConfirmationCancel}>
        Cancel
      </Button>
    </div>
  );
  const handleDelete = () => {
    const payload = { invoiceId: state.id };
    deleteInvoice(payload)
      .unwrap()
      .then(() => {
        message.success("Successfully Deleted");
        setState({ ...state, deleteModalVisible: false });
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        setState({ ...state, deleteModalVisible: false });
      });
  };
  const deleteCancel = () => {
    setState({ ...state, deleteModalVisible: false });
  };

  //
  const menu = (record) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleViewClick(record)}>
        <FontAwesomeIcon icon={faEye} className="mr-2" />
        View
      </Menu.Item>
      {record.status === "Pending" && (
        <Menu.Item key="2" onClick={() => handleDeleteClick(record)}>
          <FontAwesomeIcon icon={faTrashCan} className="mr-2" />
          Delete
        </Menu.Item>
      )}
      <Menu.Item key="3" onClick={() => handleSendClick(record)}>
        <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
        Send
      </Menu.Item>
      <Menu.Item key="4" onClick={() => handleCheckboxClick(record)}>
        <FontAwesomeIcon icon={faSquareCheck} className="mr-2" />
        {record.status === "Completed" ? "Completed" : "Mark as Paid"}
      </Menu.Item>
    </Menu>
  );

  const handleViewClick = (record) => {
    navigate(ROUTE_CONSTANTS.INVOICE, {
      state: {
        record,
      },
    });
  };

  const handleDeleteClick = (record) => {
    openDeleteModal(record._id);
  };

  const handleSendClick = (record) => {
    handleEmailReminder(record);
  };

  const handleCheckboxClick = (record) => {
    if (record.status === "Pending") {
      handleCheckboxChange(record);
    }
  };
  const columns = [
    { title: "Client Name", dataIndex: "clientName" },
    { title: "Business Associate", dataIndex: "businessAssociate" },
    { title: "Email", dataIndex: "email" },
    { title: "Project Name", dataIndex: "projectName" },
    {
      title: "Billing Period",
      dataIndex: "billingPeriod",
      render: (_, record) =>
        record.billingPeriod === "milestone" ? (
          <p className="text-left overflow-hidden overflow-ellipsis whitespace-nowrap max-w-[170px]">
            {record?.milestone}
          </p>
        ) : (
          <p className="text-left">
            {record.startDate?.split("T")[0] +
              " to " +
              record.endDate?.split("T")[0]}
          </p>
        ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (_, record) => (
        <p className="w-21 text-left">
          {record?.currency + " " + parseFloat(record.amount).toLocaleString()}
        </p>
      ),
    },
    {
      title: "Payment Status",
      dataIndex: "status",
      render: (_, record) =>
        record.status === "Completed" ? (
          <Tag color="green">{record.status}</Tag>
        ) : (
          <Tag color="red">{record.status}</Tag>
        ),
    },
    {
      title: "Paid On",
      dataIndex: "paidOn",
      render: (_, record) => (
        <p className="text-left">
          {record.paidOn ? moment(record.paidOn).format("YYYY-MM-DD") : "-"}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <div className=" space-x-1 flex justify-around">
          <Dropdown overlay={menu(record)} trigger={["click"]}>
            <MoreOutlined onClick={(e) => e.preventDefault()} />
          </Dropdown>
        </div>
      ),
    },
  ];

  const renderActions = () => {
    return (
      <div className="flex space-x-3 items-center">
        <Button
          className="flex justify-center items-center bg-blue-600 !text-white"
          onClick={openAddAccountModal}
        >
          <PlusOutlined /> Add
        </Button>
        <Button
          className="flex items-center  bg-blue-600 !text-white"
          onClick={handleReload}
        >
          <ReloadOutlined className="active:rotate-180 delay-[5000ms] duration-300 ease-in-out" />
          Refresh
        </Button>
      </div>
    );
  };

  return (
    <div>
      <Header title="Invoices" />
      <section className="mt-4 customizedTable">
        <Datatable
          tableData={payment}
          columns={columns}
          title="All Invoices"
          renderActions={(selectedRowKeys) => renderActions(selectedRowKeys)}
          pagination={{
            pageSize: 5,
          }}
        />
      </section>
      <InvoiceForm
        visible={state.accountModalVisible}
        onOk={handleAccountAction}
        onCancel={closeAccountModal}
        initialValues={state.selectedData}
        title="Add New Invoice"
        invoiceNumber={`CRUD ${`${payment.length}`.padStart(
          3,
          "0"
        )}/${new Date().getFullYear()}`}
        isLoading={addLoading}
      />
      <Modal
        title="Confirmation"
        open={state.confirmationModalVisible}
        onOk={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
        footer={footerContentCheckboxPaidReminder}
        isLoading={updateLoading}
      >
        <p>Are you sure you want to mark this invoice as paid?</p>
      </Modal>
      <Modal
        title="Email Reminder"
        open={state.emailModalVisible}
        onOk={handleEmailReminderOk}
        onCancel={handleEmailReminderCancel}
        footer={footerContentEmailReminder}
      >
        <p>Send email to: {state.selectedEmail}</p>
      </Modal>
      <DeleteModal
        visible={state.deleteModalVisible}
        onOk={() => handleDelete()}
        onCancel={() => deleteCancel()}
        title="Delete Invoice"
        message="Are you sure to delete this Invoice?"
        isLoading={deleteLoading}
      />
    </div>
  );
}

export default Invoice;
