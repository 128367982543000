import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const eventService = createApi({
  reducerPath: "eventService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["EVENTS"],

  endpoints: (builder) => ({
    getEvents: builder.query({
      query: () => ENDPOINTS_CONSTANTS.ALL_EVENTS,
      providesTags: ["EVENTS"],
      transformResponse: (res) =>
        res.event.map((values) => ({ ...values, key: values._id })),
    }),

    postEvent: builder.mutation({
      query: (payload) => {
        return {
          url: ENDPOINTS_CONSTANTS.ADD_EVENT,
          method: "POST",
          body: payload,
          headers: {
            "content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["EVENTS"],
    }),

    updateEvent: builder.mutation({
      query: (payload) => {
        return {
          url: `${ENDPOINTS_CONSTANTS.UPDATE_EVENT}${payload.id}`,
          method: "PUT",
          body: payload,
          headers: {
            "content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["EVENTS"],
    }),

    deleteEvents: builder.mutation({
      query: (payload) => {

        return {
          url: ENDPOINTS_CONSTANTS.DELETE_EVENTS,
          method: "DELETE",
          body: { id: payload },
        };
      },
      invalidatesTags: ["EVENTS"],
    }),
  }),
});

export const {
  useGetEventsQuery,
  usePostEventMutation,
  useUpdateEventMutation,
  useDeleteEventsMutation,
} = eventService;
