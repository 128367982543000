import { Button, Dropdown, Menu } from "antd";
import React from "react";
import { DownOutlined, CloseCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { useGetRolesQuery } from "../../service/roleService";

function EmployeeFilter({
  handleMenuItemClick,
  filteredEmployeeData,
  setFilteredEmployeeData,
}) {
  const { data: roles } = useGetRolesQuery();
  const items = [
    {
      key: "1",
      label: "Role",
      children: roles?.map((role) => ({
        key: role._id,
        label: role.label,
        value: role.value,
      })),
    },
  ];

  const menu = (
    <Menu>
      {items.map((item) => (
        <Menu.Item key={item.key} className="role">
          {item.children ? (
            <Menu.SubMenu title={item.label}>
              {item.children.map((child) => (
                <Menu.Item
                  key={child.key}
                  onClick={() => handleMenuItemClick(child.value)}
                >
                  {child.label}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ) : (
            ""
          )}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="flex">
      <Dropdown
        overlay={menu}
        trigger={["click"]}
        className="bg-blue-600 !text-white"
      >
        <Button className="flex items-center h-8">
          Filter data
          <DownOutlined className="mt-1" />
        </Button>
      </Dropdown>
      {filteredEmployeeData ? (
        <Button
          className=" bg-red-600 !text-white ml-2 text-center h-8 flex justify-center items-center"
          onClick={() => setFilteredEmployeeData(null)}
        >
          <CloseCircleOutlined />
          Clear Filter
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}

EmployeeFilter.propTypes = {
  handleMenuItemClick: PropTypes.func.isRequired,
  filteredEmployeeData: PropTypes.array,
  setFilteredEmployeeData: PropTypes.func,
};

export default EmployeeFilter;
