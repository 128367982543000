import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyCheck,
  faSheetPlastic,
  faUser,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";

function DashBoardCards() {
  return (
    <div className="grid grid-cols-2 font-semibold mt-5 gap-5 w-1/3">
      <div className="bg-gradient-to-r from-[#16A085] to-[#F4D03F] h-full rounded-lg text-right text-black flex flex-col p-4">
        <span>Earning</span>
        <span className="text-lg font-bold">$20,457</span>
        <FontAwesomeIcon icon={faMoneyCheck} className="w-10 h-10 text-white" />
      </div>
      <div className="bg-gradient-to-r from-[#c471ed] to-[#f64f59] h-full rounded-lg text-right text-black flex flex-col p-4">
        <span>New Clients</span> <span className="text-lg font-bold">100</span>
        <FontAwesomeIcon icon={faUsers} className="w-10 h-10 text-white" />
      </div>
      <div className="bg-gradient-to-r from-[#314755] to-[#26a0da] h-full rounded-lg text-right text-black flex flex-col p-4">
        <span>Projects</span> <span className="text-lg font-bold">160</span>
        <FontAwesomeIcon
          icon={faSheetPlastic}
          className="w-10 h-10 text-white"
        />
      </div>
      <div className="bg-gradient-to-r from-[#e65c00] to-[#F9D423] h-full rounded-lg text-right text-black flex flex-col p-4">
        <span>Employee</span> <span className="text-lg font-bold">500</span>
        <FontAwesomeIcon icon={faUser} className="w-10 h-10 text-white" />
      </div>
      <div className="grid grid-cols-1">
        <div className="bg-white rounded-lg text-right text-black flex flex-col p-4">
          <span className="text-base">Download Reports</span>
          <p>Download</p>
          <button className="text-blue-500 mt-5 text-right">Download</button>
        </div>
      </div>
    </div>
  );
}

export default DashBoardCards;
