import React, { useEffect, useState } from "react";
import { Button, Modal, message } from "antd";
import Header from "../../components/common/Header";
import AddModal from "./AddModal";
import DeleteModal from "../../components/common/DeleteModal";
import {
  useGetAllEmployeesQuery,
  useAddEmployeeMutation,
  useDeleteEmployeeMutation,
  useUpdateEmployeeMutation,
} from "../../service/employeeService";
import EmployeeDataTable from "./EmployeeDataTable";
import EmpTableBtn from "./EmpTableBtn";
function Employees() {
  const { data, isError, isLoading, refetch } = useGetAllEmployeesQuery();
  const [postEmployeeData, { isLoading: postLoading }] =
    useAddEmployeeMutation();
  const [updateEmployeeData, { isLoading: updateLoading }] =
    useUpdateEmployeeMutation();
  const [deleteEmployee, { isLoading: deleteLoading }] =
    useDeleteEmployeeMutation();
  const [file, setFile] = useState({});
  const [employee, setEmployee] = useState({
    isModalOpen: false,
    selectedEmployeeData: null,
  });
  const employeeData = data ? data : [];
  const [filteredEmployeeData, setFilteredEmployeeData] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [id, setID] = useState([]);
  const [jsonData, setJsonData] = useState(null);
  const [emptyDataModal, setEmptyDataModal] = useState(false);
  const [filterExcelData, setFilterExcelData] = useState(null);

  const handleConvert = (data) => {
    setJsonData(data);
  };
  const handleAdd = (formData) => {
    postEmployeeData({ employeeDetails: [formData] })
      .unwrap()
      .then(() => {
        message.success("Successfully added");
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        closeModal();
      });
  };
  const handleEdit = (updatedData) => {
    updateEmployeeData(updatedData)
      .then(() => {
        message.success("Successfully updated");
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        closeModal();
      });
  };
  const deleteID = (id, reset) => {
    setDeleteModalVisible(true);
    setID(id);
    reset && reset();
  };
  const handleDelete = () => {
    deleteEmployee(typeof id === "string" ? [id] : id)
      .then(() => {
        message.success("Success");
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        setDeleteModalVisible(false);
        setID([]);
      });
  };
  const handleReload = () => {
    refetch();
  };
  const openModal = () => {
    setEmployee({ ...employee, isModalOpen: true });
  };
  const closeModal = () => {
    setEmployee({
      isModalOpen: false,
      selectedEmployeeData: null,
    });
    setFile({});
  };
  const openEmployeeModal = (data) => {
    const selected = data;
    setEmployee({
      selectedEmployeeData: selected,
      isModalOpen: true,
    });
  };
  const handleMenuItemClick = (value) => {
    const filteredData = employeeData.filter((data) => data.role === value);
    setFilteredEmployeeData(filteredData);
  };

  const renderActions = (keys, reset) => {
    return (
      <EmpTableBtn
        keys={keys}
        reset={reset}
        handleMenuItemClick={handleMenuItemClick}
        handleConvert={handleConvert}
        handleReload={handleReload}
        openModal={openModal}
        deleteID={deleteID}
        filteredEmployeeData={filteredEmployeeData}
        setFilteredEmployeeData={setFilteredEmployeeData}
      />
    );
  };
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  useEffect(() => {
    if (jsonData !== null) {
      let value = true;
      const convertedData = jsonData.map((item) => {
        const convertedItem = {};
        for (const key in item) {
          if (Object.prototype.hasOwnProperty.call(item, key)) {
            const convertedKey = key.replace(
              /(?:^\w|[A-Z]|\b\w|\s+)/g,
              (match, index) => {
                if (+match === 0) return "";
                return index === 0 ? match.toLowerCase() : match.toUpperCase();
              }
            );
            convertedItem[convertedKey] = item[key];
          }
        }
        return convertedItem;
      });
      const changeJsonData = convertedData.map((data) => {
        if (data?.firstName) {
          data.firstName = capitalizeFirstLetter(data.firstName);
        }
        if (data?.lastName) {
          data.lastName = capitalizeFirstLetter(data.lastName);
        }
        if (data?.reportingManager) {
          data.reportingManager = data.reportingManager
            .split(" ")
            .map((word) => capitalizeFirstLetter(word))
            .join(" ");
        }
        return data;
      });
      const updatedJsonData = changeJsonData.map((data) => {
        const empty = employeeData.find(
          (value) =>
            `${value.firstName} ${value.lastName}` === data?.reportingManager
        );
        return {
          ...data,
          reportingManager: empty ? empty._id : null,
        };
      });
      const emptyData = updatedJsonData.filter((data) => {
        if (
          ![
            "firstName",
            "lastName",
            "gender",
            "email",
            "designation",
            "department",
            "joiningDate",
          ].every((prop) => data[prop] !== undefined && data[prop] !== " - ")
        ) {
          value = false;
          return data;
        }
      });
      if (value) {
        postEmployeeData({ employeeDetails: updatedJsonData })
          .then((response) => {
            if (response.data) {
              message.success("Successfully added");
            } else {
              message.error("Please check ReportingManager Name");
            }
          })
          .catch((error) => {
            console.log(error); // eslint-disable-line
            message.error("Something went wrong");
          });
      } else {
        setEmptyDataModal(true);
        setFilterExcelData(emptyData);
      }
    }
  }, [jsonData]);
  return (
    <div>
      <Header title="All Employee" />
      <section className="mt-4 customizedTable">
        <EmployeeDataTable
          filteredEmployeeData={filteredEmployeeData}
          openEmployeeModal={openEmployeeModal}
          renderActions={renderActions}
          deleteID={deleteID}
          employeeData={employeeData}
          isLoading={isLoading}
          isError={isError}
        />
        <AddModal
          title={
            employee.selectedEmployeeData ? "Edit Employee" : "Add Employee"
          }
          visible={employee.isModalOpen}
          onSave={handleAdd}
          onCancel={closeModal}
          onEdit={handleEdit}
          selectedData={employee.selectedEmployeeData}
          file={file}
          setFile={setFile}
          isLoading={updateLoading || postLoading}
        />
        <DeleteModal
          visible={deleteModalVisible}
          onOk={handleDelete}
          onCancel={() => setDeleteModalVisible(false)}
          title="Are you sure want to delete this data ?"
          isLoading={deleteLoading}
        />
        {filterExcelData?.length > 0 && (
          <Modal
            title="Please Check All The Fields For The Below Name"
            open={emptyDataModal}
            onCancel={() => setEmptyDataModal(false)}
            footer={
              <div className="flex w-full  mt-5 justify-end">
                <Button
                  className="bg-blue-600 text-white"
                  onClick={() => setEmptyDataModal(false)}
                >
                  Ok
                </Button>
              </div>
            }
          >
            <div className="max-h-[200px] overflow-y-auto">
              {filterExcelData?.map((data, index) => (
                <p key={index}>{` ${data.firstName} ${data.lastName} `}</p>
              ))}
            </div>
          </Modal>
        )}
      </section>
    </div>
  );
}
export default Employees;
