import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  Avatar,
  Tooltip,
  Button,
  Input,
  message,
  Result,
  Spin,
  Select,
} from "antd";
import {
  UserOutlined,
  MailOutlined,
  SearchOutlined,
  PlusOutlined,
  CheckOutlined,
} from "@ant-design/icons";
import ContactPopover from "./contact";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import { useNavigate } from "react-router-dom";
import "../../assets/styles/main.css";
import ClientModal from "./clientModal";
import DeleteModal from "../../components/common/DeleteModal";
import {
  useActivateClientMutation,
  useDeactivateClientMutation,
  useLazyGetClientsQuery,
} from "../../service/clientService";
import Header from "../../components/common/Header";
import "../../assets/styles/main.css";
import { getAvatarLabel } from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";

function Clients() {
  const navigate = useNavigate();
  const [actionData, setActionData] = useState([]);
  const [openPopoverIndex, setOpenPopoverIndex] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState("active");
  const [editData, setEditData] = useState(null);
  const [selected, setSelected] = useState([]);
  const [getClient,{ data: clients, isError, isFetching  }] = useLazyGetClientsQuery();
  const [activateClient, { isLoading: activateLoading }] =
    useActivateClientMutation();
  const [deactivateClient, { isLoading: deactivateLoading }] =
    useDeactivateClientMutation();
  const isActive = selectedStatus === "active";
  const updateClient = (data) => {
    setAddModalVisible(true);
    setEditData(data);
  };
  const handleCardClick = (clientId) => {
    const index = selected.indexOf(clientId);
    if (index === -1) {
      setSelected([...selected, clientId]);
    } else {
      const updatedIds = [...selected];
      updatedIds.splice(index, 1);
      setSelected(updatedIds);
    }
  };
  const handleDeleteClient = (data) => {
    const clientId = data.map((d) => d._id);
    const isActive = !data[0]?.isActive;
    const payload = {
      clientId,
      isActive,
    };
    const clientStatus = isActive
      ? activateClient(payload)
      : deactivateClient(payload);
    clientStatus
      .then(() => {
        const successMessage = isActive
          ? "Client activated successfully"
          : "Client deactivated successfully";
        setSelected([]);
        message.success(successMessage);
      })
      .catch((error) => {
        message.error("Something went wrong", error);
      })
      .finally(() => {
        setActionData([]);
        setDeleteModalVisible(false);
      });
  };
  const deleteCancel = () => {
    setDeleteModalVisible(false);
  };
  const handleSelect = (value) => {
    setSelectedStatus(value);
    setSelected([]);
  };
  const renderActions = () => {
    return (
      <div className="flex items-center mt-0 ml-auto mb-0 space-x-3">
        <Input
          size="large"
          className=" mx-5"
          placeholder="Search"
          prefix={<SearchOutlined />}
          onChange={(e) => setInputValue(e.target.value)}
        />
        {selected.length === 0 && isActive && (
          <Button
            className="bg-blue-600 text-white hover:!text-white border-none"
            icon={<PlusOutlined />}
            onClick={() => setAddModalVisible(true)}
          >
            Add
          </Button>
        )}
        {selected.length === 1 && (
          <Button
            className="bg-blue-600 text-white hover:!text-white border-none"
            onClick={() => updateClient(selected[0])}>
            <FontAwesomeIcon className="mr-2" icon={faPenToSquare} />
            Edit
          </Button>
        )}
        {selected.length > 0 && (
          <Button
            className="bg-red-600 text-white hover:!text-white border-none"
            onClick={() => setDeleteModalVisible(true)}>
            <FontAwesomeIcon className="mr-2" icon={faTrashCan} />
            {selected[0].isActive == true ? "Deactivate" : "Activate"}
          </Button>
        )}
      </div>
    );
  };
  const filteredData = useMemo(() => {
    let data = [];
    if (Array.isArray(clients)) {
      data = clients.filter(
        (client) =>
          client.isActive === isActive &&
          Object.values(client).some((value) =>
            value.toString().toLowerCase().includes(inputValue.toLowerCase())
          )
      );
    }
    return data;
  }, [selectedStatus, clients, inputValue]);

  useEffect(()=>{
    getClient();
  },[]);

  const { Option } = Select;
  return (
    <div className="min-h-screen flex flex-col">
      <Header title="All Clients" />
      <div className="flex items-center w-full mt-4">
        <Select
          id="clientStatus"
          name="clientStatus"
          value={selectedStatus}
          onChange={handleSelect}
          className="w-36 focus:outline-none bg-transparent text-sm cursor-pointer"
        >
          <Option value="active">Active Clients</Option>
          <Option value="inactive">Inactive Clients</Option>
        </Select>
        <div className=" h-8 w-36 rounded-md text-center leading-[28px] bg-white ml-5 border-2">
          {selectedStatus &&
            `${filteredData?.length > 0 ? filteredData.length : "No"} ${
              isActive ? "Active" : "Inactive"
            } Clients`}
        </div>
        {actionData && renderActions(actionData)}
      </div>
      {isFetching ? (
        <div className="mt-10 flex justify-center">
          <Spin size="large" />
        </div>
      ) : isError ? (
        <Result
          status="404"
          title="There are some problems with your operation."/>
      ) : filteredData && filteredData.length > 0 ? (
        <div className="clients-container mt-8 gap-8">
          {filteredData.map((client, index) => {
            const isSelected = selected.includes(client);
            const border = isSelected ? "border-violet-300 border-4" : "";
            return (
              <div key={index}>
                <div className="min-w-[300px] min-h-[300px]">
                  <Card
                    className={`${border} shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] min-h-[350px]`}
                    onClick={() => handleCardClick(client)}>
                    <div className="absolute top-0 right-0 overflow-hidden h-16 w-32 custom-clip-path">
                      {isSelected ? (
                        <div className="bg-violet-400 h-full flex items-center justify-center">
                          <CheckOutlined className="text-xl text-white absolute top-3 right-3" />
                        </div>
                      ) : null}
                    </div>
                    <div className="text-center">
                      {client.image ? (
                        <Avatar
                          src={client.image}
                          alt="Client"
                          size={70}
                          className={`mb-4 mx-auto ${
                            client.isActive ? "bg-pink-200" : "bg-gray-300"
                          }`}/>
                      ) : (
                        <Avatar
                          size={70}
                          className={`mb-4 mx-auto ${
                            client.isActive ? "bg-pink-200" : "bg-gray-300"
                          }`}>
                          <span className="text-2xl text-pink-800 font-bold">
                            {`${getAvatarLabel(client?.name)}`}
                          </span>
                        </Avatar>
                      )}
                      <h2 className="text-lg font-bold ">{client.name}</h2>
                      <p className="text-lg text-blue-500">{client.email}</p>
                    </div>
                    <div className="flex justify-center items-center mt-4">
                      {client.associates.length > 0 ? (
                        client.associates
                          .slice(0, 3)
                          .map((associate, index) => (
                            <Tooltip
                              key={index}
                              title={associate.name}
                              className="cursor-pointer"
                            >
                              <div className="flex flex-col items-center mr-1">
                                <Avatar
                                  size={35}
                                  className="bg-violet-300 mb-3">
                                  {getAvatarLabel(associate.name)}
                                </Avatar>
                              </div>
                            </Tooltip>
                          ))
                      ) : (
                        <Tooltip title="Add" placement="top">
                          <FontAwesomeIcon
                            className="mb-3 cursor-pointer"
                            icon={faCirclePlus}
                            style={{ fontSize: "35px", color: "#C4B5FD" }}
                            onClick={() => {
                              navigate(ROUTE_CONSTANTS.PROFILE, {
                                state: {
                                  clientData: client,
                                },
                              });
                            }}
                          />
                        </Tooltip>
                      )}
                      {client.associates.length > 3 && (
                        <Tooltip
                          title={client.associates
                            .slice(3)
                            .map((associate) => associate.name)
                            .join(", ")}
                          className="cursor-pointer">
                          <div className="flex flex-col items-center mr-1">
                            <Avatar
                              size={35}
                              className="bg-gray-400 text-white mb-3 bg-opacity-30"
                            >
                              {" "}
                              +{client.associates.length - 3}
                            </Avatar>
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <div className="flex justify-center mt-4">
                      <div>
                        <Button
                          size="large"
                          className="mr-2"
                          icon={<UserOutlined />}
                          onClick={() => {
                            navigate(ROUTE_CONSTANTS.PROFILE, {
                              state: {
                                clientData: client,
                              },
                            });
                          }}
                        >
                          <span>Profile</span>
                        </Button>
                        <Button
                          type="default"
                          size="large"
                          icon={<MailOutlined />}
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenPopoverIndex(index);
                          }}>
                          <span>Contact</span>
                        </Button>
                        <ContactPopover
                          visible={openPopoverIndex === index}
                          onClose={() => setOpenPopoverIndex(null)}
                          email={client.email}
                          phone={client.phone}
                          client={client?.name}/>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <Result status="403" title="No clients found." />
      )}
      <ClientModal
        title={editData ? "Edit Client" : "Add Client"}
        visible={addModalVisible}
        onOk={() => setAddModalVisible(false)}
        onCancel={() => setAddModalVisible(false)}
        editData={editData}
        setEditData={setEditData}
        setSelected={setSelected}/>
      <DeleteModal
        visible={deleteModalVisible}
        onOk={() => handleDeleteClient(selected)}
        onCancel={() => deleteCancel()}
        title={selected[0]?.isActive ? "Deactivate Client" : "Activate Client"}
        message={
          selected[0]?.isActive
            ? "Are you sure to deactivate this Client?"
            : "Are you sure to activate this Client?"
        }
        confirmButtonText={selected[0]?.isActive ? "Deactivate" : "Activate"}
        isLoading={activateLoading || deactivateLoading}
      />
    </div>
  );
}
export default Clients;
