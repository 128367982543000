import React, { useState } from "react";
import "../../assets/styles/main.css";
import { Tabs, message, Empty, Table, Result, Button, Avatar } from "antd";
import { SettingOutlined, PhoneFilled, LeftOutlined } from "@ant-design/icons";
import Loader from "../../components/common/Loader";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetOneEmployeeQuery,
  useUpdateEmployeeMutation,
} from "../../service/employeeService";
import EmployeeModal from "./EmployeeModal";
import {
  useUpdateFileMutation,
  useUploadFileMutation,
} from "../../service/baseService";
import { useMeMutation } from "../../service/baseService";
// import ImageViewer from "../../components/common/ImageViewer";
import EmployeeProfileAbotPage from "./EmployeeProfileAbotPage";
import { useGetRolesQuery } from "../../service/roleService";
import { useSelector } from "react-redux";
import AdminModal from "./AdminModal";
import AdminProfile from "./AdminProfile";
import PropTypes from "prop-types";

function EmployeeProfile({ readOnly }) {
  const { id: individualUserId } = useParams();
  const loginData = useSelector((state) => state.application.user);
  const { data, isLoading, isError } = useGetOneEmployeeQuery(
    readOnly ? individualUserId : loginData?._id
  );
  const navigate = useNavigate();
  const [updateEmployeeData] = useUpdateEmployeeMutation();
  const [me] = useMeMutation();
  const [uploadFile, { isLoading: uploadLoading }] = useUploadFileMutation();
  const [updateFile, { isLoading: updateLoading }] = useUpdateFileMutation();
  const { data: role } = useGetRolesQuery();
  const roleList = role && role ? role : [];
  const oneEmployeeData = data ? data : [];
  const [modalOpen, setModalOpen] = useState(false);
  const [passChange, setPassChange] = useState(false);
  const formattedRole = roleList.find(
    (role) => role?.value === oneEmployeeData[0]?.role
  );

  const updateEmployee = (value, reset) => {
    updateEmployeeData(value)
      .then(() => {
        message.success("Successfully updated");
        value.profile ? me() : "";
      })
      .catch((error) => {
        message.error("Something went wrong", error);
      })
      .finally(() => {
        setModalOpen(false);
        reset();
      });
  };

  const handleEdit = async (modifiedValues, empValues, reset) => {
    const empProfile = oneEmployeeData[0]?.profile;
    const updateValue = {
      ...empValues,
      ...modifiedValues,
      id: oneEmployeeData[0]?._id,
    };
    if (updateValue.profile) {
      try {
        let response;
        if (empProfile?.key) {
          response = await updateFile({
            data: updateValue.profile,
            key: empProfile.key,
          });
        } else {
          response = await uploadFile(updateValue.profile);
        }
        updateValue.profile = response.data;
        updateEmployee(updateValue, reset);
      } catch (error) {
        message.error("Something went wrong", error);
      }
    } else {
      if (
        empProfile &&
        Object.prototype.hasOwnProperty.call(updateValue, "profile")
      ) {
        delete updateValue.profile;
      }
      updateEmployee(updateValue, reset);
    }
  };
  const languageColumns = [
    {
      title: "Language",
      dataIndex: "language",
    },
    {
      title: "Read",
      dataIndex: "read",
    },
    {
      title: "Write",
      dataIndex: "write",
    },
    {
      title: "Speak",
      dataIndex: "speak",
    },
  ];
  const skillColumns = [
    {
      title: "Skill",
      dataIndex: "skill",
    },
    {
      title: "Version",
      dataIndex: "version",
    },
    {
      title: "Experience",
      dataIndex: "year",
      render: (record) => {
        return record?.years === 0 ? (
          <div>{`${record.months} Month`}</div>
        ) : (
          <div>{`${record.years} Year and ${record.months} Month`}</div>
        );
      },
    },
    {
      title: "Proficiency",
      dataIndex: "level",
    },
  ];
  const skillData = oneEmployeeData[0]?.skills;

  const languageData = oneEmployeeData[0]?.languages;
  const datas = [
    {
      key: "1",
      label: <span>About</span>,
      children: (
        <div className="m-5 flex justify-center">
          {oneEmployeeData[0]?.about ? (
            <p className="text-left w-full"> {`${oneEmployeeData[0].about}`}</p>
          ) : (
            <Empty
              className="self-center"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
            />
          )}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <span>
          <SettingOutlined />
          Skills
        </span>
      ),
      children: (
        <div>
          <div className="m-3">
            <h1 className="mb-3 text-[#555555] text-xl font-bold">Skills</h1>
            {oneEmployeeData[0]?.skills?.length > 0 ? (
              <Table
                columns={skillColumns}
                dataSource={skillData}
                size="small"
                pagination={false}
              />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
          <div className="m-3">
            <h1 className="my-3 text-[#555555] text-xl font-bold">Languages</h1>
            {oneEmployeeData[0]?.languages?.length > 0 ? (
              <Table
                columns={languageColumns}
                dataSource={languageData}
                size="small"
                pagination={false}
              />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Button
        type="link"
        className="text-black hover:!text-black flex items-center p-0"
        onClick={() => navigate(-1)}
      >
        <LeftOutlined />
        <span className="text-base">Back</span>
      </Button>
      {isError ? (
        <Result
          status="warning"
          title="There are some problems with your operation."
        />
      ) : isLoading ? (
        <Loader />
      ) : (
        <div>
          {(oneEmployeeData[0]?.role !== "admin" || readOnly) && (
            <div className="flex justify-between mt-10 min-[640px]:max-lg:flex-wrap ">
              <div className="mr-10 flex flex-col basis-3/6 min-[640px]:max-lg:basis-full  min-[640px]:max-lg:mr-0  ">
                <div className=" flex flex-col items-center bg-white rounded-xl">
                  <div className="flex flex-col items-center justify-around  bg-neutral-600 w-full relative ">
                    <span className="font-bold text-xl max-w-[270px] text-center text-white mt-4">
                      {oneEmployeeData?.length > 0
                        ? `${oneEmployeeData[0].firstName} ${oneEmployeeData[0].lastName}`
                        : "-"}
                    </span>
                    <span className="mt-2 mb-16 text-base text-white"></span>

                    <span>
                      {formattedRole?.label ? `${formattedRole?.label}` : "-"}
                    </span>

                    <span className="absolute -bottom-12 profileImg w-[100px] h-[100px]">
                      {/* <ImageViewer url={oneEmployeeData[0]?.profile?.url} /> */}
                      <Avatar
                        style={{
                          backgroundColor: "#3788d8",
                          verticalAlign: "middle",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          fontSize: "36px",
                          textTransform: "uppercase",
                        }}
                        size="large"
                      >
                        {oneEmployeeData[0].firstName?.[0]}
                        {oneEmployeeData[0].lastName?.[0]}
                      </Avatar>
                    </span>
                  </div>
                  <div className=" w-full flex flex-col justify-center items-center h-36">
                    {oneEmployeeData[0]?.address && (
                      <span className="mt-16 text-center max-w-sm ">
                        {oneEmployeeData[0].address}
                      </span>
                    )}
                    {oneEmployeeData[0]?.mobile && (
                      <span className="mt-5 mb-5">
                        <PhoneFilled className="mr-2 rotate-90" />
                        {oneEmployeeData[0].mobile}
                      </span>
                    )}
                  </div>
                </div>

                <div className="bg-white mt-5 mb-5 h-max rounded-xl ">
                  <Tabs
                    className="flex justify-between "
                    tabBarGutter={150}
                    centered
                    size={"large"}
                    defaultActiveKey="1"
                    items={datas}
                    indicatorSize={(origin) => origin + 60}
                  />
                </div>
              </div>
              <EmployeeProfileAbotPage
                oneEmployeeData={oneEmployeeData}
                setModalOpen={setModalOpen}
                loginData={loginData}
                setPassChange={setPassChange}
              />
            </div>
          )}
          {oneEmployeeData[0]?.role === "admin" && !readOnly && (
            <div>
              <AdminProfile
                oneEmployeeData={oneEmployeeData[0]}
                setPassChange={setPassChange}
              />
            </div>
          )}
        </div>
      )}

      <EmployeeModal
        visible={oneEmployeeData[0]?.role !== "admin" ? modalOpen : false}
        onSave={handleEdit}
        onCancel={() => setModalOpen(false)}
        selectedData={oneEmployeeData[0]}
        isLoading={isLoading || updateLoading || uploadLoading}
      />

      <AdminModal visible={passChange} onCancel={() => setPassChange(false)} />
    </div>
  );
}
EmployeeProfile.propTypes = {
  readOnly: PropTypes.bool,
};

export default EmployeeProfile;
