import { Button } from "antd";
import React from "react";
import { LogoutOutlined } from "@ant-design/icons";
import "../../assets/styles/permissionDenied.css";
import { ROUTE_CONSTANTS } from "../../constants/routeConstants";
import AccessDeniedIcon from "../../assets/images/access-denied.png";

function PermissionDenied() {
  const handleLogout = () => {
    localStorage.clear();
    window.location.href = ROUTE_CONSTANTS.HOME;
  };
  return (
    <div className="permission-denied">
      <img src={AccessDeniedIcon} alt="accessdenied" className="whistle" />
      <h1>403</h1>
      <h2>
        Access denied. If you do not have permission for this action, please
        contact your organization for assistance.
      </h2>
      <div className="flex justify-center mt-8">
        <Button
          icon={<LogoutOutlined className="text-white" />}
          onClick={handleLogout}
          className="border-none text-white font-sans"
        >
          Logout
        </Button>
      </div>
    </div>
  );
}

export default PermissionDenied;
