import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import PropTypes from "prop-types";
import "../../assets/styles/eventCalendar.css";
import { Divider, Popover } from "antd";

import { useGetEventsQuery } from "../../service/eventService";

function Calendar() {
  const { data } = useGetEventsQuery();
  const event = data ? data : [];
  const [calendarEvents, setCalendarEvents] = useState([]);
  const formatEventTime = (date) => {
    const eventTime = new Date(date);
    const hours = eventTime.getHours().toString().padStart(2, "0");
    const minutes = eventTime.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if (event) {
      const formattedEvents = event?.map((eventItem) => ({
        title: eventItem.title,
        date: `${eventItem.date}T${eventItem.startTime}`,
        id: eventItem._id.toString(),
        description: eventItem.description,
        assignedTo: eventItem.members,
        image: eventItem.attachment,
      }));
      setCalendarEvents(formattedEvents);
    }
  }, [event]);

  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={calendarEvents}
        eventContent={(eventInfo) => {
          return (
            <Popover
              content={
                <div className="w-56">
                  <div>
                    <div className="space-y-2">
                      <div className=" flex justify-between px-3 pt-3">
                        <p className="text-slate-500 font-semibold text-xs">
                          TITLE:
                        </p>
                        <p className="text-xs font-semibold text-right">
                          {eventInfo.event.title}
                        </p>
                      </div>
                      <div className="flex justify-between px-3">
                        <p className="text-slate-500 font-semibold text-xs">
                          TIME:
                        </p>
                        <p className="font-medium text-xs">
                          {formatEventTime(eventInfo.event.start)}
                        </p>
                      </div>
                      <div className="flex justify-between px-3">
                        <p className="text-slate-500 font-semibold text-xs">
                          MEMBERS:
                        </p>
                        <div>
                          {eventInfo.event._def.extendedProps.assignedTo?.map(
                            (member, index, array) => (
                              <div
                                className="font-medium text-xs text-right"
                                key={index}
                              >
                                {member.firstName} {member.lastName}
                                {index < array.length - 1 && ","}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <Divider className="m-0 border" />
                      <div className="px-3 pb-3">
                        <p className="text-gray-500 font-semibold text-xs">
                          DESCRIPTION:
                        </p>
                        <p className="indent-2.5 font-medium text-xs text-justify">
                          {eventInfo.event._def.extendedProps.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              }
              arrow={false}
            >
              <div className="w-full h-5 bg-slate-100 border-l-[3px] border-slate-100 border-l-[#ffb612]">
                <div className="event pl-1 overflow-auto">
                  {formatEventTime(eventInfo.event.start)} -{" "}
                  {eventInfo.event.title}
                </div>
              </div>
            </Popover>
          );
        }}
      />
      b
    </div>
  );
}

Calendar.propTypes = {
  openModal: PropTypes.func.isRequired,
  openEventModal: PropTypes.func.isRequired,
  openDeleteModal: PropTypes.func.isRequired,
  event: PropTypes.array.isRequired,
};
export default Calendar;
