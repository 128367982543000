import React from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import "../../../assets/styles/eventCalendar.css";
import { useGetHolidaysQuery } from "../../../service/holidaysService";
import { Col, Row, Popover, Divider } from "antd";
function HolidaysCalendar() {
  const { data } = useGetHolidaysQuery();
  const holidays = data ? data : [];
  return (
    <div>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={holidays}
        eventContent={(eventInfo) => {
          const extendedProp = eventInfo.event._def.extendedProps;
          return (
            <Popover
              arrow={false}
              content={
                <div className="w-56 space-y-2">
                  <Row className="px-3 pt-3">
                    <Col
                      span={8}
                      className="font-medium text-xs w-20 text-slate-500"
                    >
                      TITLE :
                    </Col>
                    <Col span={16} className="font-medium text-xs text-right">
                      {eventInfo.event.title}
                    </Col>
                  </Row>
                  <Row className="px-3">
                    <Col
                      span={8}
                      className="font-medium text-xs text-slate-500"
                    >
                      DATE :
                    </Col>
                    <Col span={16} className="font-medium text-xs text-right">
                      {eventInfo.event.startStr}
                    </Col>
                  </Row>
                  <Row
                    className={`px-3 ${!extendedProp.description && "pb-3"}`}
                  >
                    <Col
                      span={8}
                      className="font-medium text-xs text-slate-500"
                    >
                      TYPE :
                    </Col>
                    <Col span={16} className="font-medium text-xs text-right">
                      {extendedProp.holidayType}
                    </Col>
                  </Row>
                  {extendedProp.description && (
                    <div>
                      <Divider className="m-0 border" />
                      <Row className="p-3">
                        <Col className="font-medium text-xs text-slate-500">
                          DESCRIPTION :
                        </Col>
                        <Col className="font-medium text-xs indent-2.5 text-justify">
                          {extendedProp.description}
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              }
            >
              <div className="w-full bg-slate-100 border-l-[3px] border-slate-100 border-l-holidaysColor text-black">
                <div className="event w-full pl-1 overflow-auto">
                  {eventInfo.event.title}
                </div>
              </div>
            </Popover>
          );
        }}
      />
    </div>
  );
}

export default HolidaysCalendar;
