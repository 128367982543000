import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import { downloadFileAtUrl } from "../../utils/helpers";
import { useDeleteProjectAttachmentMutation } from "../../service/projectService";
import { message } from "antd";
import DeleteModal from "../../components/common/DeleteModal";

function FileMenu({ fileName, url, setOpenIndex, projectId }) {
  const [open, setOpen] = useState(false);
  const [deleteAttachment, { isLoading }] =
    useDeleteProjectAttachmentMutation();

  const hidePopover = () => setOpenIndex(null);

  const handleDownload = () => {
    downloadFileAtUrl(fileName, url);
    hidePopover();
  };

  const handleCancel = () => {
    hidePopover();
    setOpen(false);
  };

  const handleDelete = () => {
    deleteAttachment({ id: projectId, key: fileName })
      .unwrap()
      .then((response) => {
        const messageText = response?.message ? response.message : "Success";
        message.success(messageText);
      })
      .catch((error) => {
        const messageText = error?.message ? error.message : "Error";
        message.error(messageText);
        console.log(error); // eslint-disable-line
      })
      .finally(() => {
        hidePopover();
      });
  };

  return (
    <div className="p-1 flex flex-col">
      <DeleteModal
        visible={open}
        onOk={handleDelete}
        onCancel={handleCancel}
        title="Are you sure want to delete this data ?"
        isLoading={isLoading}
      />
      <button
        className="py-1 px-2 text-start hover:bg-gray-200 rounded"
        onClick={handleDownload}
      >
        <FontAwesomeIcon icon={faDownload} />
        <span className="ml-2"> Download</span>
      </button>
      <button
        className="py-1 px-2 text-start text-red-500 hover:bg-gray-200 rounded"
        onClick={() => setOpen(true)}
      >
        <FontAwesomeIcon icon={faTrash} />
        <span className="ml-2"> Delete</span>
      </button>
    </div>
  );
}

FileMenu.propTypes = {
  fileName: PropTypes.string,
  url: PropTypes.string,
  setOpenIndex: PropTypes.func,
  projectId: PropTypes.string,
};

export default FileMenu;
