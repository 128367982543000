import React from "react";
import BalanceCard from "../../components/common/BalanceCard";
import Header from "../../components/common/Header";
import { useGetPermissionBalanceQuery } from "../../service/MyLeavesService";
import MyPermissionTable from "./MyPermissionTable";

function Permission() {
  const { data: balance, refetch } = useGetPermissionBalanceQuery();
  return (
    <div>
      <Header title="My Permission" />
      <div className="my-4 grid sm:grid-cols-1 md:grid-cols-3 gap-4">
        {Array.isArray(balance) &&
          balance.map((doc, idx) => {
            const {
              name,
              monthTotal,
              monthBalance,
              annualBalance,
              annualTotal,
            } = doc;
            return (
              <BalanceCard
                title={name}
                value={monthBalance}
                total={monthTotal}
                key={idx}
                annualvalue={annualBalance}
                annualTotal={annualTotal}
                type="Permission"
              />
            );
          })}
      </div>
      <section className="mt-5">
        <MyPermissionTable balanceRefetch={refetch} />
      </section>
    </div>
  );
}

export default Permission;
