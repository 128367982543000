import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, message, Row, Col, Select } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import {
  useAddProjectMutation,
  useUpdateProjectMutation,
} from "../../service/projectService";
import { useGetAllEmployeesQuery } from "../../service/employeeService";
import { useGetClientsQuery } from "../../service/clientService";
import "../../assets/styles/main.css";
const { Option } = Select;

const ProjectModal = ({
  visible,
  setModalVisible,
  mode,
  project,
  setSelectedProjectForEdit,
}) => {
  const [form] = Form.useForm();
  const { data: employeeData = [] } = useGetAllEmployeesQuery();
  const { data: clients } = useGetClientsQuery();
  const [associates, setAssociates] = useState([]);
  const [selectedClients, setSelectedClients] = useState(null);
  const [selectedTeamMembersId, setSelectedTeamMembersId] = useState([]);
  const [selectedAssociate, setSelectedAssociate] = useState(null);
  const [addProjectMutation] = useAddProjectMutation();
  const [updateProjectMutation] = useUpdateProjectMutation();
  const isAdd = mode === "add";

  const handleClientSelect = (value) => {
    const selected = clients.find((client) => client?._id === value);
    setSelectedClients(value);
    setAssociates(selected?.associates || []);
  };

  const handleClearTeamMembers = () => {
    setSelectedTeamMembersId([]);
  };

  const handleClientDeselect = () => {
    setSelectedClients(null);
  };

  const handleClientClear = () => {
    setSelectedClients([]);
  };

  const handleAssociateDeselect = () => {
    setSelectedAssociate(null);
  };

  const handleEmployeeSelect = (value) => {
    setSelectedTeamMembersId(value);
  };

  const handleAssociateSelect = (value) => {
    setSelectedAssociate(value);
  };

  const handleCancel = () => {
    setSelectedProjectForEdit(null);
    setModalVisible(false);
    form.resetFields();
  };

  const handleAddOrUpdate = async () => {
    try {
      const projectData = await form.validateFields();
      const apiController = isAdd ? addProjectMutation : updateProjectMutation;
      const payload = isAdd
        ? projectData
        : { ...projectData, id: project?._id };
      const response = await apiController(payload);
      const toastMes = isAdd ? "Added Successfully" : "Updated Successfully";
      if (response.data) message.success(toastMes);
      handleCancel();
      form.resetFields();
    } catch (error) {
      const errMsg = error?.response?.data?.message
        ? error?.response?.data.message
        : "please fill all the fields";
      console.error("Error adding/updating project:", errMsg); //eslint-disable-line
      message.error(errMsg);
    }
  };
  const checkDateValidity = (_, value) => {
    const createdDate = form.getFieldValue("created");
    if (moment(value).isBefore(createdDate, "day")) {
      return Promise.reject(new Error("Invalid deadline"));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (project) {
      const members = project?.team.map((emp) => emp?._id);
      const formField = {
        deadline: moment(new Date(project.deadline)).format("YYYY-MM-DD"),
        created: moment(new Date(project.created)).format("YYYY-MM-DD"),
        title: project.title,
        priority: project.priority,
        department: project.department,
        team: members,
        description: project.description,
        clientId: project.clientId,
        selectedAssociateId: project.selectedAssociateId,
      };
      form.setFieldsValue(formField);
      setSelectedClients(project?.client_data);
      setAssociates(project?.client_data.associates || []);
    } else {
      form.resetFields();
    }
  }, [form, project]);

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      centered
      title={project ? "Edit Project" : "Add Project"}
      footer={[
        <div key="footer" className="flex">
          <Button
            key="cancel"
            size="large"
            onClick={handleCancel}
            className="w-1/2 text-base"
          >
            Cancel
          </Button>
          ,
          <Button
            key="addOrUpdate"
            size="large"
            onClick={() => handleAddOrUpdate()}
            className="bg-blue-600 !text-white w-1/2 text-base"
          >
            {isAdd ? "Add" : "Update"}
          </Button>
        </div>,
      ]}
      className="rounded-md"
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Title"
              name="title"
              rules={[{ required: true, message: "Please enter a title" }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Department"
              name="department"
              rules={[
                { required: true, message: "Please select a department" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Priority"
              name="priority"
              rules={[{ required: true, message: "Please select a priority" }]}
            >
              <Select>
                <Option value="high">HIGH</Option>
                <Option value="medium">MEDIUM</Option>
                <Option value="low">LOW</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Team"
              name="team"
              rules={[{ required: true, message: "Please select a team" }]}
            >
              <Select
                mode="multiple"
                onChange={handleEmployeeSelect}
                onClear={handleClearTeamMembers}
                value={selectedTeamMembersId}
              >
                {employeeData?.map((employee) => (
                  <Option key={employee._id} value={employee._id}>
                    {employee.firstName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Created Date"
              name="created"
              rules={[
                { required: true, message: "Please select the created date" },
                { validator: checkDateValidity },
              ]}
            >
              <Input type="date" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Deadline"
              name="deadline"
              rules={[
                { required: true, message: "Please select the deadline" },
                { validator: checkDateValidity },
              ]}
            >
              <Input type="date" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Client"
              name="clientId"
              rules={[{ required: true, message: "Please select a client" }]}
            >
              <Select
                onChange={handleClientSelect}
                onDeselect={handleClientDeselect}
                onClear={handleClientClear}
                value={selectedClients}
              >
                {clients?.map((client) => (
                  <Option key={client._id} value={client._id}>
                    {client.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Associates"
              name="selectedAssociateId"
              rules={[
                {
                  required: true,
                  message: "Please select at least one associate",
                },
              ]}
            >
              <Select
                onChange={handleAssociateSelect}
                onDeselect={handleAssociateDeselect}
                onClear={() => setSelectedAssociate([])}
                value={selectedAssociate}
              >
                {associates?.map((associate) => (
                  <Option key={associate._id} value={associate._id}>
                    {associate.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                { required: true, message: "Please enter a description" },
              ]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ProjectModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func,
  mode: PropTypes.oneOf(["add", "edit"]).isRequired,
  project: PropTypes.object,
  setSelectedProjectForEdit: PropTypes.func,
};

export default ProjectModal;
