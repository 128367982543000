import React, { useState, useEffect } from "react";
import { Button, Form, Input, Modal, Select, message } from "antd";
import PropTypes from "prop-types";
import {
  UserOutlined,
  MailOutlined,
  NumberOutlined,
  MoneyCollectOutlined,
} from "@ant-design/icons";

const EmployeeModal = ({ visible, onOk, onCancel, title, initialValues, onEdit }) => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (initialValues && initialValues.key) {
      setIsEditing(true);
    } else {
      setIsEditing(false);
    }
  }, [initialValues]);

  const handleOk = async () => {
    const values = await form.validateFields().catch(() => {});
  
    if (values) {
      if (isEditing) {
        onEdit(values);
        message.success("Employee edited successfully");
      } else {
        onOk(values);
        message.success("Employee added successfully");
      }
      onCancel(); 
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const footerContent = (
    <div className="flex">
      <Button className="w-1/2" size="large" onClick={handleCancel}>
        Cancel
      </Button>
      <Button type="primary" size="large" className="bg-blue-500 w-1/2" onClick={handleOk}>
        Save
      </Button>
    </div>
  );

  return (
    <Modal title={title} open={visible} onOk={handleOk} onCancel={handleCancel} footer={footerContent}>
      <Form form={form} initialValues={initialValues} className="p-3" layout="vertical">
        <Form.Item 
        label="Name" 
        name="name" 
        rules={[{ required: true, message: "Please enter the name" }
        ]}>
          <Input placeholder="Name" suffix={<UserOutlined />} />
        </Form.Item>

        <div className="flex justify-between gap-2">
          <Form.Item
            className="w-1/2"
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter the email" },
              { type: "email", message: "Invalid email format" },
            ]}
          >
            <Input placeholder="Email" suffix={<MailOutlined />} />
          </Form.Item>

          <Form.Item
            className="w-1/2"
            label="Employee ID"
            name="employeeID"
            rules={[
              { required: true, message: "Please enter the Employee ID" },
            ]}
          >
            <Input placeholder="Employee ID" suffix={<NumberOutlined />} />
          </Form.Item>
        </div>

        <div className="flex justify-between gap-2">
          <Form.Item
            className="w-1/2"
            label="Role"
            name="role"
            rules={[{ required: true, message: "Please select the role" }]}
          >
           <Select initialValues={""} options={[
              {
                value: '',
                label: 'Select Role',
                disabled: true
              },
              {
                value: 'Develpoer',
                label: 'Develpoer',
              },
              {
                value: 'Tester',
                label: 'Tester',
              },
              {
                value: 'Manager',
                label: 'Manager',
              },
            ]}/>
          </Form.Item>

          <Form.Item
            className="w-1/2"
            label="Department"
            name="department"
            rules={[
              { required: true, message: "Please select the department" },
            ]}
          >
            <Select initialValues={""} options={[
              {
                value: '',
                label: 'Select Department',
                disabled: true
              },
              {
                value: 'Designing',
                label: 'Designing',
              },
              {
                value: 'Developing',
                label: 'Developing',
              },
              {
                value: 'Marketing',
                label: 'Marketing',
              },
            ]}/>
          </Form.Item>
        </div>

        <Form.Item label="Salary" name="salary" rules={[{ required: true, message: "Please enter the salary" }]}>
          <Input placeholder="Salary" suffix={<MoneyCollectOutlined />} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

EmployeeModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  onEdit: PropTypes.func.isRequired,
};

export default EmployeeModal;
