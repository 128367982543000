import React from "react";
import DashBoardCards from "./DashBoardCards";
import ProjectSurvayChart from "./ProjectSurvayChart";

function ProjectSurvay() {
  return (
    <div className="flex gap-5 mb-5">
      <ProjectSurvayChart />
      <DashBoardCards />
    </div>
  );
}

export default ProjectSurvay;
