import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";

const roundOf = (number) => {
  let value = Number(number);
  if (value < 10 && Number.isInteger(value)) {
    value = `0${value}`;
  }
  return value;
};

function BalanceCard({
  title,
  total,
  value,
  isRequest,
  annualvalue,
  annualTotal,
  type,
  onClick,
}) {
  const currentMonthName = useMemo(() => moment(new Date()).format("MMMM"), []);

  const handleApply = () => {
    if (typeof onClick === "function") onClick(title);
  };

  return (
    <section className="shadow-md rounded-md bg-white">
      <div className="p-4 flex items-center gap-6 ">
        <div>
          <span className="text-3xl font-semibold">{roundOf(value)}/</span>
          <span className="text-xl font-semibold text-gray-400">
            {roundOf(total)}
          </span>
          <p className="text-xs font-semibold text-gray-400">
            {currentMonthName}
          </p>
        </div>
        <div className="flex-col mt-2">
          <p className="text-xl font-semibold text-gray-500">{title}</p>
          <span className="text-xs font-semibold text-gray-400">
            Remaining Annual {type} {roundOf(annualvalue)}/
            {roundOf(annualTotal)}
          </span>
        </div>
        {isRequest && (
          <p
            className="text-blue-500 underline cursor-pointer"
            onClick={handleApply}
            role="presentation"
          >
            Apply
          </p>
        )}
      </div>
    </section>
  );
}

BalanceCard.propTypes = {
  title: PropTypes.string.isRequired,
  total: PropTypes.string,
  value: PropTypes.string.isRequired,
  isRequest: PropTypes.bool,
  annualvalue: PropTypes.string.isRequired,
  annualTotal: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
};

export default BalanceCard;
