import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Form, Input, message } from "antd";
import { useUpdateEmployeeMutation } from "../../service/employeeService";
import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Image } from "antd";
import { imageFallBack } from "../../constants/appConstants";

const AdminProfile = ({ oneEmployeeData, setPassChange }) => {
  const [form] = Form.useForm();
  const [isEditable, setIsEditable] = useState(false);
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const handleEdit = () => {
    setIsEditable(true);
  };
  useEffect(() => {
    setIsFormChanged(false);
  }, [oneEmployeeData]);
  const handleOk = async () => {
    try {
      const values = await form.validateFields();

      const payload = {
        ...values,
        id: oneEmployeeData._id,
      };

      updateEmployee(payload)
        .then(() => {
          setIsEditable(false);
        })
        .catch(() => {});
    } catch (errorInfo) {
      message.error("Validation failed:", errorInfo);
    }
  };

  if (oneEmployeeData) {
    form.setFieldsValue({
      ...oneEmployeeData,
    });
  }
  const handleFormChange = () => {
    setIsFormChanged(true);
  };
  return (
    <div className="flex justify-center">
      <div className="bg-white p-10 rounded-lg">
        <FontAwesomeIcon icon={faPenToSquare} className="ml-72 text-blue-500" />
        <div className="flex justify-center pb-5">
          <Image
            width={150}
            height={150}
            src={oneEmployeeData?.profile?.url}
            preview={{
              src: oneEmployeeData?.profile?.url,
            }}
            fallback={imageFallBack}
            className="rounded-full border-4 border-white border-solid object-cover "
          />
        </div>

        <Form
          form={form}
          layout="vertical"
          className="pt-2"
          onValuesChange={handleFormChange}
        >
          <div className="w-full flex">
            <Form.Item
              className="w-1/2 mr-1"
              label="First Name"
              name="firstName"
            >
              <Input disabled={!isEditable} />
            </Form.Item>
            <Form.Item className="w-1/2 ml-1" label="Last Name" name="lastName">
              <Input disabled={!isEditable} />
            </Form.Item>
          </div>
          <Form.Item className="-mt-5" label="Email" name="email">
            <Input disabled={!isEditable} type="email" />
          </Form.Item>
        </Form>

        <div className="font-semibold text-blue-500 -mt-4">
          <button onClick={handleEdit} className="">
            Edit Profile
          </button>
        </div>
        <div className=" flex float-right pt-2">
          <Button
            className="px-3  mr-1 border-blue-500"
            onClick={() => setPassChange(true)}
          >
            <span className="text-blue-500">Change Password</span>
          </Button>
          <Button
            type="primary"
            onClick={handleOk}
            className="bg-blue-500 px-8"
            disabled={!isEditable || !isFormChanged}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

AdminProfile.propTypes = {
  oneEmployeeData: PropTypes.array,
  setPassChange: PropTypes.func,
};

export default AdminProfile;
