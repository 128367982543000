import React, { useEffect, useMemo } from "react";
import { Button, DatePicker, Form, Input, message, Select } from "antd";
import { customFormat } from "../Leave/MyLeavesModal";
import { useGetProjectsQuery } from "../../../service/projectService";
import {
  useGetCompOffQuery,
  usePostCompOffMutation,
  useUpdateCompOffMutation,
} from "../../../service/MyLeavesService";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { getDaysArray } from "../../../utils/helpers";

const validation = (values, data) => {
  const { fromDate, toDate } = values;
  return new Promise((resolve, reject) => {
    const appliedDays = getDaysArray(dayjs(fromDate), dayjs(toDate));
    for (const document of data) {
      const restrictedDates = getDaysArray(
        dayjs(document.fromDate),
        dayjs(document.toDate)
      );
      const isApplied = appliedDays.some((day) =>
        restrictedDates.includes(day)
      );
      if (isApplied) {
        reject("Application already submitted for this date.");
      }
    }
    resolve("Valid");
  });
};

function CompOffForm({ source, clear }) {
  const [form] = Form.useForm();
  const { data: projects, isLoading, isError } = useGetProjectsQuery();
  const [postCompOff, { isLoading: compOffLoader }] = usePostCompOffMutation();
  const [updateCompOff, { isLoading: updateCompOffLoader }] =
    useUpdateCompOffMutation();
  const { data: totalRequest } = useGetCompOffQuery();
  const { TextArea } = Input;
  const isEdit = source && Object.keys(source).length > 0;

  const userProject = useMemo(() => {
    return Array.isArray(projects)
      ? projects.map((doc) => ({
          label: doc.title,
          value: doc._id,
        }))
      : [];
  }, [projects]);

  const handleClear = () => {
    clear();
    form.resetFields();
  };

  const handleSubmit = (values) => {
    if (isEdit) {
      updateCompOff({ id: source?._id, ...values })
        .unwrap()
        .then((response) => {
          const mes = response?.message ? response.message : "Success";
          message.success(mes);
          handleClear();
        })
        .catch((error) => {
          console.log(error); // eslint-disable-line
          message.error("Something went wrong");
        });
    } else {
      validation(values, totalRequest)
        .then(() => {
          postCompOff(values)
            .unwrap()
            .then((response) => {
              const mes = response?.message ? response.message : "Success";
              message.success(mes);
              form.resetFields();
            })
            .catch((error) => {
              console.log(error); // eslint-disable-line
              message.error("Something went wrong");
            });
        })
        .catch((errorMessage) => {
          message.error(errorMessage);
        });
    }
  };

  const updateForm = () => {
    const formSource = { ...source };
    formSource.fromDate = dayjs(formSource.fromDate);
    formSource.toDate = dayjs(formSource.toDate);
    form.setFieldsValue(formSource);
  };

  useEffect(() => {
    if (isEdit) {
      updateForm();
    } else {
      form.resetFields();
    }
  }, [source]);

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      {(values) => {
        const disabledToDate = (current) =>
          current &&
          values.fromDate &&
          current < values.fromDate.startOf("day");
        return (
          <div>
            <div className="grid grid-cols-2 gap-4">
              <Form.Item
                label="From date"
                name="fromDate"
                rules={[
                  { required: true, message: "Please enter the From date" },
                  { type: "object", message: "Invalid date format" },
                ]}
              >
                <DatePicker
                  disabled={isEdit}
                  format={customFormat}
                />
              </Form.Item>
              <Form.Item
                label="To date"
                name="toDate"
                rules={[
                  { required: true, message: "Please enter the To date" },
                  { type: "object", message: "Invalid date format" },
                ]}
              >
                <DatePicker
                  disabled={!values.fromDate || isEdit}
                  disabledDate={disabledToDate}
                  format={customFormat}
                />
              </Form.Item>
            </div>
            <div className="grid grid-cols-1">
              <Form.Item
                label="Reason"
                name="reason"
                rules={[{ required: true, message: "Please enter the reason" }]}
              >
                <TextArea rows={4} placeholder="reason..." />
              </Form.Item>
            </div>
            <div className="grid grid-cols-2">
              <Form.Item label="Project" name="project">
                <Select disabled={isLoading || isError} options={userProject} />
              </Form.Item>
            </div>
            <div className="flex justify-end gap-4">
              {isEdit && (
                <Button danger htmlType="button" onClick={handleClear}>
                  Cancel
                </Button>
              )}
              <Form.Item>
                <Button
                  type="primary"
                  className="bg-blue-600 !text-white"
                  htmlType="submit"
                  loading={compOffLoader || updateCompOffLoader}
                >
                  {isEdit ? "Update" : "Apply"}
                </Button>
              </Form.Item>
            </div>
          </div>
        );
      }}
    </Form>
  );
}

CompOffForm.propTypes = {
  source: PropTypes.any,
  clear: PropTypes.func,
};

export default CompOffForm;
