import React from "react";
import ReactApexChart from "react-apexcharts";

function EmployeeWorkTime() {
  const data = [
    {
      name: "Working Hours",
      data: [8, 7, 5, 7.4, 6, 9],
    },
    {
      name: "Break Hours",
      data: [2, 4, 1, 5, 2, 7],
    },
  ];

  const barChartOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        columnWidth: 40,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
    },
    fill: {
      opacity: 1,
      colors: ["#674ec9", "#c1c1c1"],
    },
    legend: {
      position: "bottom",
      offsetX: 0,
      offsetY: 5,
      markers: {
        fillColors: ["#674ec9", "#c1c1c1"],
      },
    },
  };

  return (
    <div className="flex flex-col w-full lg:w-2/3 p-2 bg-white rounded-lg shadow-lg mb-4 lg:mb-0">
      <h1 className="text-lg text-gray-400 p-4 font-bold mb-4">
        Weekly Working Hours
      </h1>
      <ReactApexChart
        options={barChartOptions}
        series={data}
        type="bar"
        height={400}
      />
    </div>
  );
}

export default EmployeeWorkTime;
