import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const myPermissionService = createApi({
  reducerPath: "myPermissionService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["PERMISSIONS"],

  endpoints: (builder) => ({
    getMyPermission: builder.query({
      query: () => ENDPOINTS_CONSTANTS.PERMISSION,
      providesTags: ["PERMISSIONS"],
      transformResponse: (res) =>
        res.Permission.map((values) => ({ ...values, key: values._id })),
    }),

    postMyPermission: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.PERMISSION,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["PERMISSIONS"],
    }),
    putMyPermission: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `${ENDPOINTS_CONSTANTS.PERMISSION}/${id}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["PERMISSIONS"],
    }),
    deleteMyPermission: builder.mutation({
      query: ({ id, ...payload }) => ({
        url: `${ENDPOINTS_CONSTANTS.PERMISSION}/${id}`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["PERMISSIONS"],
    }),
    getPermissionApprovals: builder.query({
      query: () => ENDPOINTS_CONSTANTS.PERMISSION_APPROVALS,
      transformResponse: (response) =>
        Array.isArray(response?.data)
          ? response.data.map((doc) => ({ ...doc, key: doc._id }))
          : [],
      providesTags: ["PERMISSION_APPROVAL"],
    }),
    putPermissionApprovals: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.UPDATE_PERMISSION_APPROVALS,
        method: "PUT",
        body: payload,
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["PERMISSION_APPROVAL"],
    }),
  }),
});

export const {
  useGetMyPermissionQuery,
  usePostMyPermissionMutation,
  useDeleteMyPermissionMutation,
  usePutMyPermissionMutation,
  useGetPermissionApprovalsQuery,
  usePutPermissionApprovalsMutation
} = myPermissionService;
