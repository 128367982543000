import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";
import moment from "moment";

export const timeSheetService = createApi({
  reducerPath: "timeSheetService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["TASKS"],
  endpoints: (builder) => ({
    getTasks: builder.query({
      query: (userId) => `${ENDPOINTS_CONSTANTS.GET_TASKS}?empId=${userId}`,
      providesTags: ["TASKS"],
      transformResponse: (response) => {
        return response.map((val) => {
          const { date, ...res } = val;
          return { ...res, date: moment(new Date(date)).format("YYYY-MM-DD") };
        });
      },
    }),

    postTask: builder.mutation({
      query: (payload) => {
        return {
          url: ENDPOINTS_CONSTANTS.ADD_TASK,
          method: "POST",
          body: payload,
          headers: {
            "content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["TASKS"],
    }),

    updateTask: builder.mutation({
      query: (payload) => {
        const { id, ...body } = payload;
        return {
          url: `${ENDPOINTS_CONSTANTS.UPDATE_TASK}/${id}`,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["TASKS"],
    }),

    updateAllTasks: builder.mutation({
      query: (payload) => {
        const { date, empId, ...body } = payload;
        return {
          url: `${ENDPOINTS_CONSTANTS.UPDATEALL_TASK}/${date}/${empId}`,
          method: "PUT",
          body,
          headers: {
            "content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["TASKS"],
    }),

    deleteTask: builder.mutation({
      query: (_id) => {
        return {
          url: `${ENDPOINTS_CONSTANTS.DELETE_TASK}/${_id}`,
          method: "DELETE",
          headers: {
            "content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["TASKS"],
    }),
    updateTaskStatus: builder.mutation({
      query: (body) => {
        return {
          url: ENDPOINTS_CONSTANTS.UPDATE_TASK_STATUS,
          method: "PUT",
          body,
        };
      },
      invalidatesTags: ["TASKS"],
    }),
  }),
});

export const {
  useGetTasksQuery,
  usePostTaskMutation,
  useUpdateTaskMutation,
  useDeleteTaskMutation,
  useUpdateAllTasksMutation,
  useUpdateTaskStatusMutation,
} = timeSheetService;
