import React from "react";
import { Flex, Card, Space, Avatar } from "antd";
import {
  UserOutlined,
  TwitterOutlined,
  MobileOutlined,
  MailOutlined,
  EnvironmentFilled,
} from "@ant-design/icons";
import Ratings from "../../components/common/Ratings";
import Header from "../../components/common/Header";

function MyTeam() {
  const datas = [
    {
      userName: "Sarah Smith",
      qualification: "B.E",
      ratings: 4,
      ratingOutOff: 5,
      totalRatings: 123456,
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer...`,
      address: "Shanti Nagar Bldg No B 4, Sector No 6, Mira Road",
      twitterId: `sarah_smith`,
      mobileNumber: 123456789,
      emailId: `sarah@example.com`,
    },
    {
      userName: "Jay Soni",
      qualification: "Computer Science",
      ratings: 3,
      ratingOutOff: 5,
      totalRatings: 6545,
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer...`,
      address: `Shanti Nagar Bldg No B 4, Sector No 6, Mira Road`,
      twitterId: `jay_soni`,
      mobileNumber: 1234567,
      emailId: `jay@example.com`,
    },
    {
      userName: "Megha Trivedi",
      qualification: "M.C.A.",
      ratings: 4,
      ratingOutOff: 5,
      totalRatings: 6545,
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer...`,
      address: `Shanti Nagar Bldg No B 4, Sector No 6, Mira Road`,
      twitterId: `megha_trivedi`,
      mobileNumber: 12345678,
      emailId: `megha@example.com`,
    },
    {
      userName: "Jacob Ryan",
      qualification: "B.E., M.E.",
      ratings: 5,
      ratingOutOff: 5,
      totalRatings: 6545,
      description: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer...`,
      address: `Shanti Nagar Bldg No B 4, Sector No 6, Mira Road`,
      twitterId: `jacob_ryan`,
      mobileNumber: 12345678,
      emailId: `jacob@example.com`,
    },
  ];
  return (
    <div>
      <Header title="My Teams" />
      <Space
        className="bg-white mt-7 p-4 rounded-lg flex"
        direction="vertical"
        size="middle"
      >
        {datas.map((data) => (
          <Card
            size="large"
            className="shadow-md mb-1 w-full"
            key={data.mobileNumber}
          >
            <Flex
              wrap="wrap"
              justify="space-between"
              align="flex-start"
              className="w-full"
            >
              <Avatar
                className="border-4 border-white shadow-lg flex-none !rounded-3xl mr-8 mb-2"
                shape="square"
                size={150}
                icon={<UserOutlined />}
              />
              <Space.Compact
                direction="vertical"
                className="max-w-[41rem] truncate  flex flex-[2] text-sm mb-3"
              >
                <p className="text-2xl font-semibold text-sky-400 mb-1.5">
                  {data.userName}
                </p>
                <p className="mb-4">{data.qualification}</p>
                <Ratings
                  rating={data.ratings}
                  ratingOutOff={data.ratingOutOff}
                  totalRatings={data.totalRatings}
                />
                <p className="leading-[1.4rem] truncate">{data.description}</p>
              </Space.Compact>
              <div className="max-w-2xl flex flex-1 text-sm ml-6 flex-col h-full self-center truncate">
                <p className="mb-3.5 truncate ">
                  <EnvironmentFilled className="mr-4 text-base" />
                  {data.address}
                </p>
                <p className="mb-3.5 truncate ">
                  <TwitterOutlined className="mr-4 text-base" />
                  {data.twitterId}
                </p>
                <p className="mb-3.5 truncate">
                  <MobileOutlined className="mr-4 text-base" />
                  {data.mobileNumber}
                </p>
                <p className="truncate">
                  <MailOutlined className="mr-4 text-base" />
                  {data.emailId}
                </p>
              </div>
            </Flex>
          </Card>
        ))}
      </Space>
    </div>
  );
}

export default MyTeam;
