import { Popover } from "antd";
import React from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
function DetailsCompletionPopover({ record }) {
  const content = (
    <div>
      <p className="text-base font-semibold bg-gray-200 px-2 py-1">
        {"User not completed these pages"}
      </p>
      <div className="p-2">
        {renderDetail("Education", record.education)}
        {renderDetail("Skills", record.skills)}
        {renderDetail("Languages", record.languages)}
      </div>
    </div>
  );
  function renderDetail(detailName, detailArray) {
    return !detailArray.length && <p>{`${detailName} detail not filled`}</p>;
  }
  return (
    <Popover content={content}>
      <ExclamationCircleOutlined className="text-red-400 cursor-pointer" />
    </Popover>
  );
}
DetailsCompletionPopover.propTypes = {
  record: PropTypes.object,
};
export default DetailsCompletionPopover;
