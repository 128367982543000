import { baseService } from "../service/baseService";
import { timeSheetService } from "../service/timeSheetService";
import { eventService } from "../service/eventService";
import { employeeService } from "../service/employeeService";
import { myPermissionService } from "../service/MyPermissionService";
import { projectService } from "../service/projectService";
import { clientService } from "../service/clientService";
import { invoiceService } from "../service/invoiceService";
import { myLeaveService } from "../service/MyLeavesService";
import { holidaysService } from "../service/holidaysService";
import { roleService } from "../service/roleService";
import { departmentService } from "../service/departmentService";
import { blogService } from "../service/blogQuery";

const middleware = [
  baseService.middleware,
  timeSheetService.middleware,
  eventService.middleware,
  projectService.middleware,
  employeeService.middleware,
  myLeaveService.middleware,
  myPermissionService.middleware,
  myPermissionService.middleware,
  clientService.middleware,
  invoiceService.middleware,
  holidaysService.middleware,
  roleService.middleware,
  departmentService.middleware,
  blogService.middleware
];

export default middleware;
