import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const roleService = createApi({
  reducerPath: "roleService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["ROLE"],
  endpoints: (builder) => ({
    getRoles: builder.query({
      query: () => ENDPOINTS_CONSTANTS.ROLE,
      providesTags: ["ROLE"],
      transformResponse: (res) =>
        res.role.map((values) => ({ ...values, key: values._id })),
    }),
  }),
});

export const { useGetRolesQuery } = roleService;
