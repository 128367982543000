import React, { useState, useEffect} from "react";
import moment from "moment";
import PropTypes from "prop-types";
import DropdownforTimeSheet from "../../pages/TimeSheet/DropdownforTimeSheet";
import { Tooltip, Dropdown, message, Spin, Result } from "antd";
import {
  useGetTasksQuery,
  useUpdateTaskStatusMutation,
} from "../../service/timeSheetService";
import { LikeFilled, DislikeFilled, MoreOutlined } from "@ant-design/icons";

function TimeSheetTasks({
  userId,
  openTaskModal,
  setDeleteModal,
  passingId,
  isPermit,
  selectedMonth,
  setFilteredData,
}) {
  const { data, isFetching, isError } = useGetTasksQuery(userId);
  const [updateAllTask] = useUpdateTaskStatusMutation();
  const TaskData = data && data ? data : [];
  const [dateWiseTasks, setDateWiseTasks] = useState({});

  const taskGroups = (tasks) => {
    const sortedTasks = {};
    tasks.forEach((taskDetails) => {
      const taskDate = moment.utc(taskDetails.date).format("YYYY-MM-DD");
      sortedTasks[taskDate] = sortedTasks[taskDate] || [];
      sortedTasks[taskDate].push(taskDetails);
    });
    setDateWiseTasks(sortedTasks);
  };

  useEffect(() => {
    if (selectedMonth) {
      const { month, year } = selectedMonth;
      const filteredData = TaskData.filter((task) => {
        const taskDate = moment.utc(task.date).toDate();
        const taskMonth = moment.utc(taskDate).format("MMMM");
        const taskYear = moment.utc(taskDate).format("YYYY");
        return taskMonth === month && taskYear == year;
      });
      setFilteredData(filteredData);
      taskGroups(filteredData);
    } else {
      setDateWiseTasks({});
      taskGroups(TaskData);
    }
  }, [selectedMonth, TaskData, setFilteredData]);

  const calculateTotalWorkingHoursOfTheDay = (date) => {
    let totalTime = 0;
    const curTasks = dateWiseTasks[date];
    if (curTasks && curTasks.length > 0) {
      curTasks.forEach((task) => {
        const eachTaskDiff = calculateTimeDifference(
          task.startTime,
          task.endTime
        );
        const [hours, minutes] = eachTaskDiff.split(":").map(Number);
        totalTime += hours * 3600000 + minutes * 60000;
      });
    }
    return moment.utc(totalTime).format("HH:mm:ss");
  };

  const renderFormattedTime = (date) => {
    const givenDate = moment.utc(date).toDate();
    const hour = moment(givenDate).format("HH");
    const minute = moment(givenDate).format("mm");
    return `${hour} : ${minute}`;
  };

  const calculateTimeDifference = (startTime, endTime) => {
    const _startingTime = moment(startTime);
    const _endingTime = moment(endTime);
    const duration = moment.duration(_endingTime.diff(_startingTime));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;
    return `${hours}:${minutes}`;
};

  const approveAll = (allTask) => {
    const taskId = Array.isArray(allTask) ? allTask.map((doc) => doc._id) : [];
    const payload = {
      id: taskId,
      userId,
      isApproved: true,
    };
    updateAllTask(payload)
      .then(() => {
        message.success("Task updated successfully");
      })
      .catch((error) => {
        console.log(error); //eslint-disable-line
        message.error("Something went wrong");
      });
  };

  const denyAll = (allTask) => {
    const taskId = Array.isArray(allTask) ? allTask.map((doc) => doc._id) : [];
    const payload = {
      id: taskId,
      userId,
      isApproved: false,
    };
    updateAllTask(payload)
      .then(() => {
        message.success("Task updated successfully");
      })
      .catch((error) => {
        console.log(error); //eslint-disable-line
        message.error("Something went wrong");
      });
  };

  return (
    <div>
      {isFetching ? (
        <div className="w-full h-[300px] flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : isError ? (
        <Result
          status="404"
          title="There are some problems with your operation."
        />
      ) : Object.keys(dateWiseTasks).length > 0 ? (
        Object.keys(dateWiseTasks)?.map((todayTask, index) => (
            <div className="bg-gray-200 p-0.5 rounded-md mb-4" key={index}>
              <div className="flex justify-between items-center p-2 mb-3">
                <p className="text-base font-semibold ">
                  {moment(todayTask).format("ddd DD MMM YYYY")}
                </p>
                <p>
                  <span className="text-sm">Total: </span>
                  <span className="font-semibold text-base">
                    {calculateTotalWorkingHoursOfTheDay(todayTask)}
                  </span>
                  {isPermit ? (
                    <Dropdown
                      menu={{
                        items: [
                          {
                            label: "Approve all",
                            key: "0",
                            icon: <LikeFilled />,
                            onClick: () => approveAll(TaskData),
                            disabled: dateWiseTasks[todayTask]?.every(
                              (task) => task?.isApproved
                            ),
                          },
                          {
                            label: "Deny all",
                            key: "1",
                            icon: <DislikeFilled />,
                            onClick: () => denyAll(TaskData),
                            disabled: dateWiseTasks[todayTask]?.every(
                              (task) => !task?.isApproved
                            ),
                          },
                        ],
                      }}
                      trigger={"click"}
                      className="mr-2"
                    >
                      <MoreOutlined className="text-lg" />
                    </Dropdown>
                  ) : null}
                </p>
              </div>
              {dateWiseTasks[todayTask]?.map((task, index) => (
                <div
                  key={index}
                  className="bg-white rounded flex justify-between items-center p-4 mb-1 sm:max-lg:h-auto sm:max-lg:flex-col sm:max-lg:items-start"
                >
                  <div className="flex items-center max-w-[50%] sm:max-lg:max-w-full sm:max-lg:flex-col sm:max-lg:items-start sm:max-lg:justify-around ">
                    <Tooltip title={task.desc}>
                      <p className="text-[#475467] font-medium text-sm m-0 max-w-[80%] sm:max-lg:max-w-full sm:max-lg:whitespace-normal truncate">
                        {task.desc}
                      </p>
                    </Tooltip>
                    <Tooltip title={task.title}>
                      <span className="text-violet-700 bg-violet-50 rounded-2xl font-medium text-xs text-center ml-[10px] max-w-[120px] p-1 truncate sm:max-lg:max-w-full sm:max-lg:ml-0 sm:max-lg:my-2">
                        {task.title}
                      </span>
                    </Tooltip>
                  </div>
                  <div className=" flex sm:max-lg:flex-col sm:max-lg:h-[85px] sm:max-lg:justify-between sm:max-lg:items-start">
                    {task.isApproved ? (
                      <span className="font-medium text-xs rounded-2xl text-white bg-blue-600 text-center p-1 ">
                        {"Approved"}
                      </span>
                    ) : (
                      <span className="font-medium text-xs rounded-2xl text-violet-700 bg-violet-50 text-center p-1 ">
                        {"Not yet Approved"}
                      </span>
                    )}

                    <div className="flex items-center sm:max-lg:flex sm:max-lg:flex-col sm:max-lg:items-center">
                      <p className="ml-2 font-semibold text-base sm:max-lg:ml-0">
                        {`${renderFormattedTime(
                          task.startTime
                        )} - ${renderFormattedTime(task.endTime)}`}
                      </p>
                      <div className="w-[24px] h-0 border border-gray-300 rotate-90 sm:max-lg:rotate-180 sm:max-lg:w-[110px]"></div>
                      <p className="font-semibold text-base mr-1">
                        {`${calculateTimeDifference(
                          task.startTime,
                          task.endTime
                        )}:00`}
                      </p>
                    </div>
                    <div className="sm:max-lg:hidden">
                      <DropdownforTimeSheet
                        openTaskModal={openTaskModal}
                        setDeleteModal={setDeleteModal}
                        task={task}
                        passingId={passingId}
                        isPermit={isPermit}
                      />
                    </div>
                  </div>
                  <div className="sm:max-lg:w-full lg:hidden sm:max-lg:visible">
                    <div className="sm:max-lg:float-right w-fit">
                      <DropdownforTimeSheet
                        openTaskModal={openTaskModal}
                        setDeleteModal={setDeleteModal}
                        task={task}
                        passingId={passingId}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
        ))
      ) : (
        <Result
          status="403"
          title={
            isPermit
              ? "No records found!."
              : "Begin to accelerate your work with us!."
          }
        />
      )}
    </div>
  );
}

TimeSheetTasks.propTypes = {
  userId: PropTypes.object,
  openTaskModal: PropTypes.object.isRequired,
  setDeleteModal: PropTypes.bool.isRequired,
  passingId: PropTypes.number.isRequired,
  isPermit: PropTypes.bool,
  selectedMonth: PropTypes.object,
  setFilteredData: PropTypes.func,
};

export default TimeSheetTasks;
