import React, { useState, useEffect } from "react";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import Datatable from "../../components/common/Datatable";
import DeleteModal from "../../components/common/DeleteModal";
import EventModal from "./EventModal";
import { Button, message, Popover } from "antd";
import {
  useGetEventsQuery,
  useUpdateEventMutation,
  useDeleteEventsMutation,
  usePostEventMutation,
} from "../../service/eventService";
import Header from "../../components/common/Header";
import IconButton from "../../components/common/IconButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";

function Event() {
  const loginData = useSelector((state) => state.application.user);
  const { data, refetch } = useGetEventsQuery();
  const [deleteEvents, { isLoading: deleteLoading }] =
    useDeleteEventsMutation();
  const [updateEvent, { isLoading: updateLoading }] = useUpdateEventMutation();
  const [addEvent, { isLoading: addLoading }] = usePostEventMutation();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [eventState, setEventState] = useState({
    isModalOpen: false,
    selectedEventData: null,
    selectedValue: "list",
    selectedId: [],
    selectedDate: null,
  });
  // const { data: employeeData } = useGetAllEmployeesQuery();
  const event = data ? data : [];
  const filteredEvents = event
    ?.filter((data) => data.type !== "Holiday")
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  const handleEdit = (editedData, id, reset) => {
    const payload = { ...editedData, id };
    updateEvent(payload)
      .unwrap()
      .then(() => {
        message.success("Successfully updated");
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        closeModal();
        reset();
      });
  };

  const handleDelete = () => {
    deleteEvents(eventState.selectedId)
      .unwrap()
      .then(() => {
        message.success("Success");
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        setDeleteModalVisible(false);
        setEventState({ ...eventState, selectedId: null });
      });
  };

  const handleAdd = (values, reset) => {
    addEvent({ ...values, type: "Event" })
      .unwrap()
      .then(() => {
        message.success("Successfully added");
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        closeModal();
        reset();
      });
  };

  const handleReload = () => {
    refetch();
  };

  const openModal = (data) => {
    setEventState({ ...eventState, isModalOpen: true, selectedDate: data });
  };

  const closeModal = () => {
    setEventState({
      ...eventState,
      isModalOpen: false,
      selectedEventData: null,
    });
  };

  const openEventModal = (data) => {
    setEventState({
      ...eventState,
      selectedEventData: data,
      isModalOpen: true,
    });
  };

  const openDeleteModal = (id, reset) => {
    setDeleteModalVisible(true);
    setEventState({
      ...eventState,
      selectedId: id,
    });
    reset && reset();
  };

  const renderActions = (keys, reset) => {
    if (loginData.role === "admin") {
      return (
        <div className="flex space-x-3 items-center">
          {keys.length < 1 && (
            <Button
              className="flex justify-center items-center bg-blue-600 !text-white"
              onClick={openModal}
            >
              <PlusOutlined /> Add
            </Button>
          )}
          {keys.length < 1 && (
            <Button
              className="flex items-center  bg-blue-600 !text-white"
              onClick={handleReload}
            >
              <ReloadOutlined className="active:rotate-180 delay-[5000ms] duration-300 ease-in-out" />
              Refresh
            </Button>
          )}
          {keys.length > 1 && (
            <Button
              className="flex items-center  bg-red-600 !text-white"
              onClick={() => openDeleteModal(keys, reset)}
            >
              <FontAwesomeIcon className="mr-2" icon={faTrashCan} />
              Delete
            </Button>
          )}
        </div>
      );
    }
  };

  const columns = [
    {
      title: "Event",
      dataIndex: "title",
    },
    {
      title: "Date",
      dataIndex: "date",
    },
    {
      title: "Time",
      dataIndex: "startTime",
    },
    {
      title: "Description",
      dataIndex: "description",
      ellipsis: true,
    },
    {
      title: "Assigned To",
      dataIndex: "members",
      render: (members) => {
        const visibleTeam = members?.slice(0, 2);
        const remainingTeamCount = members?.length - 2;
        return (
          <Popover
            content={
              <div>
                <p className="text-base font-semibold bg-gray-200 px-2 py-1">
                  {"Event Assigned Members"}
                </p>
                <div className="p-2">
                  {members?.map((member, index) => (
                    <div key={index}>
                      {index + 1}.{member.firstName} {member.lastName}
                    </div>
                  ))}
                </div>
              </div>
            }
          >
            <div>
              <div>
                {visibleTeam?.map((member, index) => (
                  <div className="bg-yellow-500 m-1 p-1 rounded-md" key={index}>
                    {member.firstName} {member.lastName}
                  </div>
                ))}
              </div>

              {remainingTeamCount > 0 && (
                <div className="text-sm text-right m-1">
                  +{remainingTeamCount} more
                </div>
              )}
            </div>
          </Popover>
        );
      },
    },
  ];

  if (loginData.role === "admin") {
    columns.push({
      title: "Actions",
      dataIndex: "_id",
      fixed: "right",
      width: 140,
      render: (_, record) => (
        <div className="flex space-x-2.5">
          <IconButton
            title="Edit"
            icon={
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={() => openEventModal(record)}
              />
            }
          />
          <IconButton
            title="Delete"
            icon={
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => openDeleteModal([record._id])}
              />
            }
          />
        </div>
      ),
    });
  }

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      <Header title="Events" />
      <section className="mt-4 customizedTable">
        <Datatable
          tableData={filteredEvents}
          columns={columns}
          checkBox={loginData.role === "admin"}
          rowSelection
          title="All Events"
          renderActions={(selectedRowKeys, reset) =>
            renderActions(selectedRowKeys, reset)
          }
          pagination={{
            pageSize: 5,
          }}
        />
      </section>
      <EventModal
        title={eventState.selectedEventData ? "Update Event" : "Add Event"}
        open={eventState.isModalOpen}
        onCancel={closeModal}
        onSave={handleAdd}
        onEdit={handleEdit}
        selectedData={eventState.selectedEventData}
        selectedDate={eventState.selectedDate}
        isLoading={updateLoading || addLoading}
      />
      <DeleteModal
        visible={deleteModalVisible}
        onOk={handleDelete}
        onCancel={() => setDeleteModalVisible(false)}
        isLoading={deleteLoading}
        title="Are you sure want to delete this data ?"
        width={350}
      />
    </div>
  );
}

export default Event;
