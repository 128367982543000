import { Tabs } from "antd";
import React, { useMemo, useState } from "react";
import CompOffTable from "./CompOffTable";
import CompOffForm from "./CompOffForm";

function CompOffModal() {
  const [activeKey, setActiveKey] = useState("1");
  const [editableForm, setEditabal] = useState(null);

  const handleEdit = (payload) => {
    setActiveKey("1");
    setEditabal(payload);
  };

  const clear = () => {
    setEditabal(null);
  };

  const tabItems = useMemo(() => {
    return [
      {
        label: `Request Form`,
        children: <CompOffForm source={editableForm} clear={clear} />,
        key: "1",
      },
      {
        label: `List`,
        children: <CompOffTable onEdit={handleEdit} />,
        key: "2",
      },
    ];
  }, [editableForm]);

  const handleTabChange = (key) => {
    setActiveKey(key);
    setEditabal(null);
  };

  return (
    <div>
      <Tabs items={tabItems} activeKey={activeKey} onChange={handleTabChange} />
    </div>
  );
}

export default CompOffModal;
