import React, { useEffect, useState } from "react";
import {
  ContentState,
  convertFromHTML,
  convertToRaw,
  EditorState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import PropTypes from "prop-types";

function RichEditor({ invalid, onChange, initialValue }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    const contentState = editorState.getCurrentContent();
    const rawContentState = convertToRaw(contentState);
    const html = draftToHtml(rawContentState);
    if (typeof onChange === "function") onChange(html);
  };

  useEffect(() => {
    if (typeof initialValue === "string" && initialValue.trim()) {
      const contentBlock = convertFromHTML(initialValue);
      const block = contentBlock.contentBlocks
        ? ContentState.createFromBlockArray(contentBlock.contentBlocks)
        : ContentState.createFromText("");
      const initialEditorState = EditorState.createWithContent(block);
      setEditorState(initialEditorState);
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [initialValue]);

  return (
    <Editor
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      editorClassName={`${
        invalid ? "border-red-500" : "border-[#F1F1F1]"
      } border`}
    />
  );
}

RichEditor.propTypes = {
  invalid: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  initialValue: PropTypes.any,
};

export default RichEditor;
