import React from "react";
import ReactApexChart from "react-apexcharts";
import { Button } from "antd";

function EmployeeProjectProgress() {
  const series = [
    { name: "Project 1", data: [44], color: "#4CAF50" },
    { name: "Project 2", data: [55], color: "#FFC107" },
    { name: "Project 3", data: [67], color: "#2196F3" },
  ];
  const options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            fontSize: "22px",
          },
          value: {
            fontSize: "16px",
          },
          total: {
            show: true,
            label: "Total",
            formatter: () => {
              return 249;
            },
          },
        },
      },
    },
    labels: series.map((project) => project.name),
    colors: series.map((project) => project.color),
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      fontSize: "14px",
    },
  };

  return (
    <div className="flex flex-col w-full lg:w-1/3 p-2 bg-white rounded-lg shadow-lg mb-4 lg:mb-0 lg:ml-4">
      <h1 className="text-lg text-gray-400 p-6 font-bold">Project Progress</h1>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series.map((project) => project.data)}
          type="radialBar"
          height={350}
        />
        <div className="flex justify-center mt-5 lg:mb-4 ">
          <Button className="mb-4">More Details</Button>
        </div>
      </div>
    </div>
  );
}

export default EmployeeProjectProgress;
