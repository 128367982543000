import React from "react";
import Datatable from "../../../components/common/Datatable";
import moment from "moment";
import { PlusOutlined, ReloadOutlined } from "@ant-design/icons";
import MyLeavesModal from "./MyLeavesModal";
import DeleteModal from "../../../components/common/DeleteModal";
import LeaveHoc from "../LeaveHoc";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import IconButton from "../../../components/common/IconButton";
import { statusText } from "../../../constants/appConstants";
import { Button, Tag } from "antd";

const MyLeavesTable = ({
  leave,
  sortedLeaves,
  handleOk,
  handleCancel,
  onEdit,
  deleteButton,
  handleDelete,
  editButton,
  plus,
  handleReload,
  data,
  isFetching,
  isError,
  leaveTypes,
}) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "fromDate",
      key: "fromDate",
      render: (_, record) => {
        const { fromDate, toDate } = record;
        const formattedfromDate = moment(fromDate).format("DD-MM-YYYY");
        const formattedtoDate = moment(toDate).format("DD-MM-YYYY");
        if (formattedfromDate != formattedtoDate) {
          return `${moment(fromDate).format("DD-MM-YYYY")} to ${moment(
            toDate
          ).format("DD-MM-YYYY")}`;
        } else {
          return moment(fromDate).format("DD-MM-YYYY");
        }
      },
    },
    {
      title: "No of Days",
      dataIndex: "noOfDays",
      key: "noOfDays",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (categoryId) => {
        const category = leaveTypes?.find((doc) => doc._id === categoryId);
        return <div>{category?.name}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <Tag
          color={
            record.status === 1 ? "green" : record.status === 0 ? "blue" : "red"
          }
        >
          {statusText[text]}
        </Tag>
      ),
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      width: 120,
      render: (_, record) => (
        <div className="space-x-3 flex ">
          <IconButton
            icon={
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={() => editButton(record)}
              />
            }
            title="Edit"
          />
          <IconButton
            icon={
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => deleteButton(record)}
              />
            }
            title="Delete"
          />
        </div>
      ),
    },
  ];

  const renderActions = (_id) => {
    return (
      <div className="flex space-x-3 items-center">
        <Button
          className="flex justify-center items-center bg-blue-600 !text-white"
          onClick={plus}
        >
          <PlusOutlined /> Add
        </Button>
        <Button
          className="flex items-center  bg-blue-600 !text-white"
          onClick={handleReload}
        >
          <ReloadOutlined className="active:rotate-180 delay-[5000ms] duration-300 ease-in-out" />
          Refresh
        </Button>
        {_id.length > 1 && (
          <Button className="flex items-center  bg-red-600 !text-white">
            <FontAwesomeIcon className="mr-2" icon={faTrashCan} />
            Delete
          </Button>
        )}
      </div>
    );
  };
  return (
    <section className="customizedTable">
      <Datatable
        tableData={sortedLeaves}
        columns={columns}
        checkBox
        rowSelection
        title="My Leaves"
        renderActions={(selectedRowKeys) => renderActions(selectedRowKeys)}
        isError={isError}
        isLoading={isFetching}
        pagination={{
          pageSize: 5,
        }}
      />
      <MyLeavesModal
        visible={leave.isAddModelVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        title={leave.editModal ? "Edit Leave Request" : "Create leave request"}
        editModal={leave.editModal}
        editModalData={leave.editModalData}
        onEdit={onEdit}
        data={data}
        leaveTypes={leaveTypes}
      />
      <DeleteModal
        title="Are you sure you want to delete"
        visible={leave.deleteModalVisible}
        onOk={handleDelete}
        onCancel={handleCancel}
        onConfirmDelete={handleDelete}
      />
    </section>
  );
};

MyLeavesTable.propTypes = {
  leave: PropTypes.object,
  handleReload: PropTypes.func,
  data: PropTypes.object,
  plus: PropTypes.func,
  cancelApprovedLeaveRequest: PropTypes.func,
  editButton: PropTypes.func,
  handleCancel: PropTypes.func,
  handleDelete: PropTypes.func,
  deleteButton: PropTypes.func,
  onEdit: PropTypes.func,
  handleOk: PropTypes.func,
  sortedLeaves: PropTypes.array,
  isError: PropTypes.any,
  isFetching: PropTypes.any,
  refetch: PropTypes.func,
  leaveTypes: PropTypes.any,
};

export default LeaveHoc(MyLeavesTable);
