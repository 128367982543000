import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const clientService = createApi({
  reducerPath: "clientService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["Clients", "Associates"],
  endpoints: (builder) => ({
    getClients: builder.query({
      query: () => ENDPOINTS_CONSTANTS.ALL_CLIENTS,
      providesTags: ["Clients"],
      transformResponse: (res) =>
        res.Client.map((values) => ({ ...values, key: values._id })),
    }),

    postClient: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.ADD_CLIENT,
        method: "POST",
        body: payload.values,
        headers: {
          "content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Clients"],
    }),

    updateClient: builder.mutation({
      query: (payload) => ({
        url: `${ENDPOINTS_CONSTANTS.UPDATE_CLIENT}${payload.clientId}`,
        method: "PUT",
        body: payload.values,
        headers: {
          "content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Clients"],
    }),

    activateClient: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.ACTIVATE_CLIENT,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Clients"],
    }),

    deactivateClient: builder.mutation({
      query: (payload) => ({
        url: ENDPOINTS_CONSTANTS.DEACTIVATE_CLIENT,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Clients"],
    }),

    getAssociates: builder.query({
      query: (id) => `${ENDPOINTS_CONSTANTS.GET_ASSOCIATES}${id}`,
      providesTags: ["Associates"],
      transformResponse: (res) =>
        res.Client.map((values) => ({ ...values, key: values._id })),
    }),

    postAssociate: builder.mutation({
      query: (payload) => ({
        url: `${ENDPOINTS_CONSTANTS.ADD_ASSCOCIATE}${payload.clientId}`,
        method: "POST",
        body: payload.values,
        headers: {
          "content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Associates"],
    }),

    updateAssociate: builder.mutation({
      query: (payload) => ({
        url: `${ENDPOINTS_CONSTANTS.UPDATE_ASSOCIATE}${payload.associateId}`,
        method: "PUT",
        body: payload.values,
        headers: {
          "content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Associates"],
    }),

    deleteAssociate: builder.mutation({
      query: (payload) => ({
        url: `${ENDPOINTS_CONSTANTS.DELETE_ASSOCIATE}${payload.associateId}`,
        method: "DELETE",
        body: payload.isActive,
      }),
      invalidatesTags: ["Associates"],
    }),
  }),
});

export const {
  useGetClientsQuery,
  usePostClientMutation,
  useUpdateClientMutation,
  useActivateClientMutation,
  useDeactivateClientMutation,
  useGetAssociatesQuery,
  usePostAssociateMutation,
  useUpdateAssociateMutation,
  useDeleteAssociateMutation,
  useLazyGetClientsQuery
} = clientService;
