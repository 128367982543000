import React, { useState } from "react";
import MyLeavesTable from "./Leave/MyLeavesTable";
import Header from "../../components/common/Header";
import LeaveHoc from "./LeaveHoc";
import {
  useGetLeaveBalanceQuery,
  useGetLeaveTypesQuery,
} from "../../service/MyLeavesService";
import LeaveBalanceCard from "../../components/common/BalanceCard";
import CompOffModal from "./CompOff";
import { Modal } from "antd";

function MyLeaves() {
  const [compOffRequested, setCompOffRequested] = useState(false);
  const { data: leaveTypes } = useGetLeaveTypesQuery();
  const { data: balance, refetch } = useGetLeaveBalanceQuery();

  const handleRequest = (type) => {
    if (type === "Comp Off") {
      setCompOffRequested(true);
    }
  };

  return (
    <div>
      <Modal
        title="Comp Off"
        width={900}
        onCancel={() => setCompOffRequested(false)}
        visible={compOffRequested}
        footer
      >
        <CompOffModal />
      </Modal>
      <Header title="My Leaves" />
      <div className="my-4 grid sm:grid-cols-1 md:grid-cols-3 gap-4">
        {Array.isArray(balance) &&
          balance.map((doc, idx) => {
            const {
              name,
              monthTotal,
              monthBalance,
              annualBalance,
              annualTotal,
            } = doc;
            const isRequest = name === "Comp Off";
            return (
              <LeaveBalanceCard
                title={name}
                value={monthBalance}
                total={monthTotal}
                key={idx}
                isRequest={isRequest}
                annualvalue={annualBalance}
                annualTotal={annualTotal}
                type="Leave"
                onClick={handleRequest}
              />
            );
          })}
      </div>
      <section className="mt-5">
        {LeaveHoc(MyLeavesTable)({ leaveTypes, balanceRefetch: refetch })}
      </section>
    </div>
  );
}

export default MyLeaves;
