import React from "react";
import Header from "../../../components/common/Header";
import EmployeeCards from "./EmployeeCards";
import EmployeeWorkTime from "./EmployeeWorkTime";
import EmployeeProjectProgress from "./EmployeeProjectProgress";
import EmployeeTeams from "./EmployeeTeams";
import EmployeeTasks from "./EmployeeTasks";
import EmployeeTodoList from "./EmployeeTodoList";
import EmployeeTicketResolved from "./EmployeeTicketResolved";
import EmployeeTicketStatus from "./EmployeeTicketStatus";
function EmployeeDashboard() {
  return (
    <div className="max-w-screen-xl mx-auto">
      <Header title="Dashboard" />
      <EmployeeCards />
      <div className="flex flex-col mt-2 lg:flex-row">
        <EmployeeWorkTime />
        <EmployeeProjectProgress />
      </div>
      <div className="flex flex-col  lg:flex-row mt-6">
        <EmployeeTeams />
        <EmployeeTasks />
      </div>
      <div className="flex flex-col lg:flex-row mt-6 lg:space-x-4">
        <EmployeeTodoList />
        <EmployeeTicketStatus />
        <EmployeeTicketResolved />
      </div>
    </div>
  );
}

export default EmployeeDashboard;
