import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Form, Select, DatePicker } from "antd";
import TextArea from "antd/es/input/TextArea";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;
export const disabledDate = (current) => current && current < dayjs().startOf("day");
const MyLeavesForm = ({
  form,
  state,
  setState,
  validateToDate,
  customFormat,
  leaveTypes,
  handleCategoryChange,
  categoryId,
}) => {
  const disabledToDate = (current) =>
    current && state.fromDate && current < state.fromDate.startOf("day");
  const leaveTypeDetail = useMemo(
    () =>
      categoryId
        ? leaveTypes?.find((doc) => doc._id === categoryId)
        : categoryId,
    [categoryId]
  );

  return (
    <Form form={form} className="p-3" layout="vertical">
      <div className="flex justify-between gap-2">
        <Form.Item
          className="w-2/4"
          label="From date"
          name="fromDate"
          rules={[
            { required: true, message: "Please enter the From date" },
            { type: "object", message: "Invalid date format" },
          ]}
        >
          <DatePicker
            className="w-full"
            disabledDate={disabledDate}
            format={customFormat}
            onChange={(date) =>
              setState((prevState) => ({ ...prevState, fromDate: date }))
            }
          />
        </Form.Item>
        <Form.Item
          className="w-2/4"
          label="To date"
          name="toDate"
          rules={[
            { required: true, message: "Please enter the To date" },
            { type: "object", message: "Invalid date format" },
            { validator: validateToDate },
          ]}
        >
          <DatePicker
            disabled={!state.fromDate}
            className="w-full"
            disabledDate={disabledToDate}
            format={customFormat}
            onChange={(date) =>
              setState((prevState) => ({ ...prevState, toDate: date }))
            }
          />
        </Form.Item>
      </div>
      {state.isSameDate || state.editIsSameDate ? (
        <Form.Item
          className="w-full"
          label="Day Period"
          name="fromDayPeriod"
          rules={[{ required: true, message: "Please select the field" }]}
        >
          <Select placeholder="Select">
            <Option value="Full Day">Full Day</Option>
            <Option value="First Half">First Half</Option>
            <Option value="Second Half">Second Half</Option>
          </Select>
        </Form.Item>
      ) : (
        <div className="flex justify-between gap-2">
          <Form.Item
            className="w-2/4"
            label="Day Period (From)"
            name="fromDayPeriod"
            rules={[{ required: true, message: "Please select the field" }]}
          >
            <Select placeholder="Select">
              <Option value="Full Day">Full Day</Option>
              <Option value="First Half">First Half</Option>
              <Option value="Second Half">Second Half</Option>
            </Select>
          </Form.Item>
          <Form.Item
            className="w-2/4"
            label="Day Period (To)"
            name="toDayPeriod"
            rules={[{ required: true, message: "Please select the field" }]}
          >
            <Select placeholder="Select">
              <Option value="Full Day">Full Day</Option>
              <Option value="First Half">First Half</Option>
              <Option value="Second Half">Second Half</Option>
            </Select>
          </Form.Item>
        </div>
      )}
      <Form.Item
        className="w-full"
        label="Category"
        name="category"
        rules={[{ required: true, message: "Please select the Type" }]}
      >
        <Select
          placeholder="Select Type"
          value={categoryId}
          onChange={handleCategoryChange}
        >
          {leaveTypes?.map((doc, idx) => (
            <Option key={idx} value={doc._id}>
              {doc.name}
            </Option>
          ))}
        </Select>
        {leaveTypeDetail && (
          <div className="mt-2 text-blue-500 text-xs relative">
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="absolute top-[3px]"
            />
            <span className="ml-4">{leaveTypeDetail.description}</span>
            <span className="ml-2">
              Max of {leaveTypeDetail?.accrualRate} and{" "}
              {leaveTypeDetail?.entitlement} days leave can be taken in a month
              and year respectively
            </span>
          </div>
        )}
      </Form.Item>
      <Form.Item
        label="Reason"
        name="reason"
        rules={[{ required: true, message: "Please enter the reason" }]}
      >
        <TextArea placeholder="Reason" />
      </Form.Item>
    </Form>
  );
};

MyLeavesForm.propTypes = {
  form: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  validateToDate: PropTypes.func.isRequired,
  customFormat: PropTypes.func.isRequired,
  leaveTypes: PropTypes.any,
  handleCategoryChange: PropTypes.func,
  categoryId: PropTypes.string,
};

export default MyLeavesForm;
