export const ENDPOINTS_CONSTANTS = {
  LOGIN: "/auth/login",
  ME: "/auth/me",
  ADD_TASK: "/task/addTask",
  GET_TASKS: "/task/getTasks",
  UPDATE_TASK: "/task/updateTask",
  UPDATEALL_TASK: "/task/updateAll",
  DELETE_TASK: "/task/deleteTask",
  ALL_EVENTS: "/event",
  ADD_EVENT: "/event",
  UPDATE_EVENT: "/event/",
  DELETE_EVENTS: "/event",
  EMPLOYEE: "/employee",
  MYTASKS: "/mytasks",
  POSTEMPLOYEE: "auth/register",
  EMPLOYEEPROFILE: "/employees/:id",
  PAYROLL: "/employee-salary",
  GET_PROJECTS: "/project",
  UPDATE_PROJECTS: "/project",
  DELETE_PROJECTS: "/project",
  ADD_PROJECTS: "/project",
  ALL_PAYMENTS: "/account",
  EVENTS: "/event",
  FILE: "/file",
  DELETEEMPLOYEE: "/employee/deleteEmp",
  ALL_CLIENTS: "/clients/",
  ADD_CLIENT: "/clients/",
  UPDATE_CLIENT: "/clients/updateClient/",
  ACTIVATE_CLIENT: "/clients/activate/",
  DEACTIVATE_CLIENT: "/clients/deactivate/",
  ADD_ASSCOCIATE: "/clients/associate/",
  DELETE_ASSOCIATE: "/clients/associate/",
  UPDATE_ASSOCIATE: "/clients/associate/",
  GET_ASSOCIATES: "/clients/associate/",
  DELETE_PROJECTS_ATTACH: "/project/attachment",
  ADD_INVOICE: "/invoice/",
  UPDATE_INVOICE: "/invoice/",
  ALL_INVOICES: "/invoice/",
  SEND_EMAIL_REMINDER: "/invoice/sendReminderEmail",
  DELETE_INVOICE: "/invoice/",
  LEAVES: "/leave",
  PERMISSION: "/permission",
  LEAVES_TYPE: "/leaveType",
  LEAVE_BALANCE: "/leave/balance",
  PERMISSION_BALANCE: "/permission/balance",
  LEAVE_APPROVALS: "/leave/approvals",
  UPDATE_LEAVE_APPROVALS: "/leave/approvals/update",
  PERMISSION_APPROVALS: "/permission/approvals",
  UPDATE_PERMISSION_APPROVALS: "/permission/approvals/update",
  EMPLOYEE_BYMANAGER: "/employee/approvals/manager",
  UPDATE_TASK_STATUS: "/task/updateStatus",
  GET_HOLIDAYS: "/holidays/allHolidays",
  ADD_HOLIDAY: "/holidays/addHoliday",
  UPDATE_HOLIDAY: "/holidays/updateHoliday",
  DELETE_HOLIDAY: "/holidays/deleteHoliday",
  ROLE: "/role",
  DEPARTMENT: "/department",
  COMP_OFF: "/compoff",
  COMPOFF_APPROVALS: "/compoff/approvals",
  UPDATE_COMPOFF_APPROVALS: "/compoff/approvals/update",
  LOGOUT: "/auth/logout",
  BLOG: "/blog",
  BLOG_USER: "/blog/user",
};
