import { Card } from "antd";
import React from "react";
import ReactApexChart from "react-apexcharts";
import DashboardCardTitle from "../../DashboardCardTitle";

function SupportTicketSurvayChart() {
  const options = {
    series: [
      { name: "Payment", data: [44, 55, 41, 67, 22, 43] },
      { name: "Department", data: [13, 23, 20, 8, 13, 27] },
      { name: "Bugs", data: [11, 17, 15, 15, 21, 14] },
      { name: "New Errors", data: [21, 7, 25, 13, 22, 8] },
    ],
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      type: "category",
      categories: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri"],
    },
    legend: {
      position: "top",
      offsetY: 0,
    },
    fill: {
      opacity: 1,
      colors: ["#34C6BB", "#5F6B6D", "#FD817E", "#F5D33F"],
    },
  };

  return (
    <Card className="w-1/2 bg-white min-h-50px relative mb-5 border border-solid border-f2f4f9 rounded-10px">
      <DashboardCardTitle title="Support Ticket Survay" />
      <ReactApexChart
        options={options}
        series={options.series}
        type="bar"
        height={350}
      />
    </Card>
  );
}

export default SupportTicketSurvayChart;
