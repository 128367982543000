import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const baseService = createApi({
  reducerPath: "baseService",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: ENDPOINTS_CONSTANTS.LOGIN,
        method: "POST",
        body,
      }),
    }),
    logout: builder.mutation({
      query: () => ({
        url: ENDPOINTS_CONSTANTS.LOGOUT,
        method: "POST",
        body: {},
      }),
    }),
    me: builder.mutation({
      query: () => ({
        url: ENDPOINTS_CONSTANTS.ME,
        method: "POST",
        body: {},
      }),
    }),
    uploadFile: builder.mutation({
      query: (formData) => ({
        url: ENDPOINTS_CONSTANTS.FILE,
        method: "POST",
        body: formData,
      }),
      transformResponse: (response) => ({
        url: response?.result?.Location,
        key: response?.result?.Key,
      }),
    }),
    updateFile: builder.mutation({
      query: ({ key, data }) => ({
        url: `${ENDPOINTS_CONSTANTS.FILE}/${key}`,
        method: "PUT",
        body: data,
      }),
      transformResponse: (response) => ({
        url: response?.result?.Location,
        key: response?.result?.Key,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useMeMutation,
  useUploadFileMutation,
  useUpdateFileMutation,
  useLogoutMutation,
} = baseService;
