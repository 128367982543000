import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Upload, message } from "antd";
import PropTypes from "prop-types";
import {
  UserOutlined,
  MailOutlined,
  ContactsOutlined,
  UploadOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../assets/styles/main.css";
import {
  usePostClientMutation,
  useUpdateClientMutation,
} from "../../service/clientService";

const ClientModal = ({
  visible,
  onOk,
  onCancel,
  title,
  editData,
  setEditData,
  setSelected,
}) => {
  const [form] = Form.useForm();
  const [phoneForm, setPhoneform] = useState("");
  const [country, setCountry] = useState("");
  const [addClient, { isLoading: addLoading }] = usePostClientMutation();
  const [updateClient, { isLoading: updateLoading }] =
    useUpdateClientMutation();

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const reducedPhone = phoneForm.replace(country, "");

      if (editData) {
        onOk(values, editData._id);
        const payload = {
          clientId: editData._id,
          values: { ...values, countryCode: country, phone: reducedPhone },
        };
        updateClient(payload)
          .then(() => {
            message.success("Successfully Edited");
          })
          .catch((error) => {
            console.log(error); //eslint-disable-line
            message.error("Something went wrong");
          })
          .finally(() => {
            form.resetFields();
            setEditData(null);
            setSelected([]);
          });
      } else {
        onOk({ ...values, countryCode: country, phone: reducedPhone });
        const payload = {
          values: { ...values, countryCode: country, phone: reducedPhone },
        };
        addClient(payload)
          .unwrap()
          .then(() => {
            message.success("Successfully Added");
          })
          .catch((error) => {
            console.log(error); //eslint-disable-line
            message.error("Something went wrong");
          })
          .finally(() => {
            form.resetFields();
          });
      }
    } catch (error) {
      console.log(error); //eslint-disable-line
    }
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
    setEditData(null);
  };

  const footerContent = (
    <div className="flex">
      <Button className="w-1/2" size="large" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        className="bg-blue-500 w-1/2"
        onClick={handleOk}
        isLoading={addLoading || updateLoading}
      >
        {editData ? "Update" : "Add"}
      </Button>
    </div>
  );

  const updateForm = () => {
    if (editData) {
      const { name, website, email, countryCode, phone, address, description } =
        editData;
      form.setFieldsValue({
        name,
        website,
        email,
        phone: `${countryCode}${phone}`,
        address,
        description,
      });
      setPhoneform(phone?.toString());
      setCountry(countryCode?.toString());
    }
  };

  useEffect(() => {
    updateForm();
  }, [editData]);

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footerContent}
      centered
      className="bg-black bg-opacity-20"
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Client Name"
          name="name"
          rules={[{ required: true, message: "Please enter the Client Name " }]}
        >
          <Input
            placeholder="Client Name"
            suffix={<UserOutlined />}
            className="w-full"
          />
        </Form.Item>

        <Form.Item
          label="Client Website"
          name="website"
          rules={[
            { required: true, message: "Please enter the Client Website " },
          ]}
        >
          <Input
            placeholder="Client Website"
            suffix={<GlobalOutlined />}
            className="w-full"
          />
        </Form.Item>

        <div className="flex justify-between gap-2">
          <Form.Item
            className="w-1/2"
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please enter the email" },
              { type: "email", message: "Invalid email format" },
            ]}
          >
            <Input
              placeholder="Email"
              className="h-9"
              suffix={<MailOutlined />}
            />
          </Form.Item>

          <Form.Item
            className="w-1/2"
            label="Contact"
            name="phone"
            rules={[{ required: true, message: "Please enter Client Contact" }]}
          >
            <PhoneInput
              country={"in"}
              suffix={<ContactsOutlined />}
              className="clientContact"
              onChange={(phone, country) => {
                setPhoneform(phone);
                setCountry(country.dialCode);
              }}
            />
          </Form.Item>
        </div>

        <div className="flex gap-2">
          <Form.Item
            className="w-1/2"
            label="Client Address"
            name="address"
            rules={[
              { required: true, message: "Please enter the Client Address " },
            ]}
          >
            <Input
              placeholder="Client Address"
              suffix={<UserOutlined />}
              className="w-full"
            />
          </Form.Item>

          <Form.Item
            className="w-1/2"
            label="Client Description"
            name="description"
            rules={[
              {
                required: true,
                message: "Please enter the Client Description",
              },
            ]}
          >
            <Input
              placeholder="Client Description"
              suffix={<UserOutlined />}
              className="w-full"
            />
          </Form.Item>
        </div>

        <div className="flex justify-between gap-2">
          <Form.Item
            className="w-full hidden"
            label="Upload Image"
            name="image"
          >
            <Upload name="file" maxCount={1}>
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

ClientModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  clientId: PropTypes.string,
  editData: PropTypes.object,
  setEditData: PropTypes.func,
  setSelected: PropTypes.func.isRequired,
};

export default ClientModal;
