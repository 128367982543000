import React from "react";
import { Modal, Form, Button, Input, Select, message } from "antd";
import PropTypes from "prop-types";
import {
  usePostHolidaysMutation,
  useUpdateHolidayMutation,
} from "../../../service/holidaysService";
function ModalForAddEdit({ currentState, setCurrentState }) {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { Option } = Select;
  const [addHoliday] = usePostHolidaysMutation();
  const [updateHoliday] = useUpdateHolidayMutation();
  if (currentState.selectedData) {
    form.setFieldsValue(currentState.selectedData);
  }

  const handleModalSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (currentState.selectedData) {
          handleEdit(values);
        } else {
          handleAdd(values);
        }
        form.resetFields();
        closeModal();
      })
      .catch((error) => {
        console.log(error); //eslint-disable-line
        message.error("Please fill the required fields");
      });
  };

  const handleAdd = (values) => {
    addHoliday(values)
      .then(() => {
        message.success("Holiday added successfully");
      })
      .catch((error) => {
        console.log(error); //eslint-disable-line
        message.error("Something went wrong");
      });
  };

  const handleEdit = (values) => {
    const updated = { ...values, id: currentState.selectedData._id };
    updateHoliday(updated)
      .then(() => message.success("Holiday updated successfully"))
      .catch((error) => {
        console.log(error); //eslint-disable-line
        message.error("Something went wrong");
      });
  };

  const handleCancel = () => {
    closeModal();
    form.resetFields();
  };

  const closeModal = () => {
    setCurrentState({ isModalOpen: false, selectedData: null });
  };

  const footerContent = (
    <div className="flex justify-end space-x-4">
      <Button
        size="large"
        key="cancel"
        className="text-base w-1/2"
        onClick={handleCancel}
      >
        Cancel
      </Button>

      <Button
        key="add"
        size="large"
        className="bg-[#1677FF] text-base w-1/2 text-white hover:!text-white"
        onClick={handleModalSubmit}
      >
        {currentState.selectedData ? `Update` : `Add`}
      </Button>
    </div>
  );

  const config = {
    rules: [
      {
        required: true,
        message: "Please fill out this field",
      },
    ],
  };
  return (
    <Modal
      title={currentState.selectedData ? "Edit Holiday" : "Add Holiday"}
      centered
      open={currentState.isModalOpen}
      onCancel={handleCancel}
      footer={footerContent}
      className="max-h-[500px] overflow-y-auto"
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Title" {...config}>
          <Input className="p-2" />
        </Form.Item>
        <div className="flex justify-between items-center">
          <Form.Item name="date" label="Date" className="w-5/12" {...config}>
            <Input type="date" className="w-full p-2 text-base" />
          </Form.Item>
          <Form.Item
            name="holidayType"
            label="Type"
            className="w-5/12"
            {...config}
          >
            <Select placeholder="Select Holiday Type">
              <Option value="Goverment Holiday">Goverment Holiday</Option>
              <Option value="Restricted Holiday">Restricted Holiday</Option>
            </Select>
          </Form.Item>
        </div>
        <Form.Item name="description" label="Description">
          <TextArea placeholder="..." />
        </Form.Item>
      </Form>
    </Modal>
  );
}

ModalForAddEdit.propTypes = {
  currentState: PropTypes.object,
  setCurrentState: PropTypes.func,
};

export default ModalForAddEdit;
