import React from "react";
import { Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import Datatable from "../../../components/common/Datatable";
import Icon from "../../../components/common/Icon";

function AllLeaveRequest() {
  const data = [];
  for (let i = 0; i < 10; i++) {
    data.push({
      key: i,
      name: `Edward King ${i}`,
      leaveType: `sick${i}`,
      leaveFrom: `test@gmail.com${i}`,
      leaveTo: `test@gmail.com${i}`,
      noOfDays: ` ${i}`,
      reason: "fever",
      approval: "approved",
    });
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Leave Type",
      dataIndex: "leaveType",
    },
    {
      title: "Leave From",
      dataIndex: "leaveFrom",
    },
    {
      title: "Leave To",
      dataIndex: "leaveTo",
    },
    {
      title: "No Of Days",
      dataIndex: "noOfDays",
    },
    {
      title: "Approval",
      dataIndex: "approval",
      render: (tags) => {
        let color = tags === "approved" ? "green" : "red";
        return (
          <Tag color={color} className="p-2">
            {tags}
          </Tag>
        );
      },
    },

    {
      title: "Reason",
      dataIndex: "reason",
    },

    {
      title: "Action",
      dataIndex: "actions",
      render: () => (
        <div className="flex space-x-2">
          <CheckCircleOutlined className="w-5 h-5 text-white bg-green-500 text-lg font-extrabold rounded-full" />
          <CloseCircleOutlined className="w-5 h-5 text-white bg-red-500 text-lg font-extrabold rounded-full" />
        </div>
      ),
    },
  ];

  const renderActions = (keys) => {
    if (keys.length > 1) {
      return (
        <div className="flex">
          <Icon icon={<DeleteOutlined />} color="bg-red-500" />
        </div>
      );
    }
  };

  return (
    <div>
      <div className="flex">
        <h1 className="p-5 text-xl">Leave Requests</h1>
      </div>
      <div className="customizedTable">
        <Datatable
          title="Leave Request"
          columns={columns}
          tableData={data}
          checkBox
          renderActions={(selectedRowKeys) => renderActions(selectedRowKeys)}
        />
      </div>
    </div>
  );
}

export default AllLeaveRequest;
