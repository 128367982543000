import React from "react";
import PropTypes from "prop-types";
import { Form, Input, Select } from "antd";
import { useGetAllEmployeesQuery } from "../../service/employeeService";
function AddModalBalanceDetail({ selectedData, file, setFile }) {
  const { data } = useGetAllEmployeesQuery();
  const empList = data && data ? data : [];
  const { Option } = Select;
  const value = false;

  return (
    <div>
      <div className="flex w-full">
        <Form.Item
          className="w-1/2 mr-2"
          name="reportingManager"
          label="Reporting Manager"
        >
          <Select placeholder="Reporting Manager">
            {empList?.map((doc) => {
              return (
                <Option value={doc._id} key={doc._id}>
                  {doc.firstName} {doc.lastName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item
          className="w-1/2 mr-2"
          name="joiningDate"
          label="Joining Date"
          rules={[
            {
              required: true,
              message: "Please enter your Joining Date",
            },
          ]}
        >
          <Input
            type="date"
            className="w-full text-[13px]"
            placeholder="Joining Date"
            disabled={selectedData ? true : false}
          />
        </Form.Item>
      </div>
      <div className="flex w-full">
        {value ? (
          <Form.Item className="w-1/2 mr-2" label="Resume" value={file}>
            <input
              type="file"
              className="w-full"
              onChange={(e) => {
                setFile(e.target.files[0]);
              }}
            />
          </Form.Item>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
AddModalBalanceDetail.propTypes = {
  file: PropTypes.object,
  setFile: PropTypes.func,
  selectedData: PropTypes.array,
};
export default AddModalBalanceDetail;
