import React from "react";
import Header from "../../../components/common/Header";
import ProjectSurvay from "./ProjectSurvay";
import Invoice from "./Invoice";
import ClientSurvay from "./ClientSurvay";
import Project from "./Project";

function AdminDashboard() {
  return (
    <div>
      <Header title="Dashboard" />
      <div>
        <section>
          <ProjectSurvay />
          <Invoice />
          <ClientSurvay />
          <Project />
        </section>
      </div>
    </div>
  );
}

export default AdminDashboard;
