import React from "react";
import { Tag } from "antd";
import Datatable from "../../../components/common/Datatable";


function LeaveTypes() {
  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      leavetype: "feveer",
      approval: "active",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      leavetype: "feveer",
      approval: "active",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      leavetype: "feveer",
      approval: "inactive",
    },
    {
      key: "4",
      name: "Disabled User",
      age: 99,
      address: "Sydney No. 1 Lake Park",
      leavetype: "feveer",
      approval: "active",
    },
  ];

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Age",
      dataIndex: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
    },
    {
      title: "Leavetype",
      dataIndex: "leavetype",
    },
    {
      title: "Approval",
      dataIndex: "approval",
      render: (tags) => {
        let color = tags === "active" ? "green" : "red";
        return (
          <Tag color={color} className="p-2">
            {tags}
          </Tag>
        );
      },
    },
  ];

  return (
    <div>
      <div className="flex">
        <h1 className="p-5 text-xl">Leave Types</h1>
      </div>
      <div className="customizedTable">
        <Datatable
          title="Leave Types"
          columns={columns}
          tableData={data}
        />
      </div>
    </div>
  );
}


export default LeaveTypes;
