import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, message, Form } from "antd";
import { usePostMyLeavesMutation } from "../../../service/MyLeavesService";
import MyLeavesForm from "./MyLeavesForm";
import dayjs from "dayjs";
import { getDaysArray } from "../../../utils/helpers";

export const customFormat = (value) => (value ? value.format("DD-MM-YYYY") : "");
const MyLeavesModal = ({
  visible,
  onOk,
  onCancel,
  title,
  onEdit,
  editModalData,
  editModal,
  data,
  leaveTypes,
}) => {
  const [categoryId, setCategoryId] = useState(null);
  const [form] = Form.useForm();
  const [postMyLeaves] = usePostMyLeavesMutation();
  const [state, setState] = useState({
    isSameDate: false,
    editIsSameDate: false,
    fromDate: null,
    toDate: null,
    editModalData: null,
  });


  const existingDate = (fromDate, toDate, data, fromDayPeriod, toDayPeriod) => {
    if (!data || data.length === 0) {
      return false;
    }

    const daysInInput = getDaysArray(dayjs(fromDate), dayjs(toDate));

    for (const item of data) {
      const DaysInDB = getDaysArray(dayjs(item.fromDate), dayjs(item.toDate));
      const overlap = daysInInput.some((day) => DaysInDB.includes(day));

      if (overlap) {
        if (
          (item.fromDayPeriod === "Full Day" && fromDayPeriod === "Full Day") ||
          (item.toDayPeriod === "Full Day" && toDayPeriod === "Full Day")
        ) {
          return true;
        } else if (
          (item.fromDayPeriod === "First Half" &&
            fromDayPeriod === "First Half") ||
          (item.fromDayPeriod === "Second Half" &&
            fromDayPeriod === "Second Half")
        ) {
          return true;
        } else if (
          (item.toDayPeriod === "First Half" && toDayPeriod === "First Half") ||
          (item.toDayPeriod === "Second Half" && toDayPeriod === "Second Half")
        ) {
          return true;
        } else if (
          (item.fromDayPeriod === "First Half" &&
            fromDayPeriod === "Full Day") ||
          (item.fromDayPeriod === "Second Half" &&
            fromDayPeriod === "Full Day") ||
          (item.toDayPeriod === "First Half" && toDayPeriod === "Full Day") ||
          (item.toDayPeriod === "Second Half" && toDayPeriod === "Full Day")
        ) {
          return true;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    editModalfunc();
  }, [editModalData, editModal]);

  const editModalfunc = () => {
    if (editModalData && editModal) {
      const { fromDate, toDate, fromDayPeriod, toDayPeriod, category, reason } =
        editModalData;
      form.setFieldsValue({
        fromDate: dayjs(fromDate),
        toDate: dayjs(toDate),
        fromDayPeriod,
        toDayPeriod,
        category,
        reason,
      });
      setCategoryId(category);
      setState((prevState) => ({
        ...prevState,
        fromDate: dayjs(fromDate),
        toDate: dayjs(toDate),
      }));
    }
  };

  useEffect(() => {
    fromToDateFunc();
  }, [state.fromDate, state.toDate]);

  const fromToDateFunc = () => {
    const { fromDate, toDate } = state;
    const fromDateValue = fromDate;
    const toDateValue = toDate;

    setState((prevState) => ({
      ...prevState,
      editIsSameDate:
        fromDateValue &&
        toDateValue &&
        dayjs(fromDateValue).isSame(toDateValue, "day"),
    }));
  };
  useEffect(() => {
    editissamedate();
  }, [editModalData]);

  const editissamedate = () => {
    if (editModalData) {
      const { fromDate, toDate } = editModalData;
      const fromDateValue = fromDate;
      const toDateValue = toDate;
      setState((prevState) => ({
        ...prevState,
        editIsSameDate:
          fromDateValue &&
          toDateValue &&
          dayjs(fromDateValue).isSame(toDateValue, "day"),
      }));
    }
  };

  const validateToDate = (_, value) => {
    const { fromDate } = form.getFieldValue("fromDate");
    if (fromDate && value && fromDate.isAfter(value)) {
      return Promise.reject(
        new Error("To date must be equal or after From date")
      );
    }
    return Promise.resolve();
  };

  const handleCancel = () => {
    setState((prevState) => ({
      ...prevState,
      editIsSameDate: false,
      fromDate: null,
      toDate: null,
    }));
    onCancel();
    form.resetFields();
    setCategoryId(null);
  };

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (editModalData) {
          onEdit(values);
        } else {
          if (
            existingDate(
              values.fromDate,
              values.toDate,
              data,
              values.fromDayPeriod,
              values.toDayPeriod
            )
          ) {
            message.error("Leave request conflicts with existing date range");
            return;
          }
          postMyLeaves(values)
            .unwrap()
            .then(() => {
              message.success("Leave Requested successfully");
              form.resetFields();
              setCategoryId(null);
              onOk();
            })
            .catch((errorResponse) => {
              const text =
                typeof errorResponse?.data?.error === "string"
                  ? errorResponse.data.error
                  : "Leave Request not sent";
              message.error(text);
            });
        }
      })
      .catch(() => {
        message.error("Please enter all the fields");
      });
  };

  const handleCategoryChange = (value) => {
    form.setFieldValue("category", value);
    setCategoryId(value);
  };

  const footerContent = (
    <div className="flex">
      <Button className="w-1/2" size="large" onClick={handleCancel}>
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        className="bg-blue-500 w-1/2"
        onClick={handleOk}
      >
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title={title}
      open={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footerContent}
    >
      <MyLeavesForm
        form={form}
        state={state}
        setState={setState}
        validateToDate={validateToDate}
        customFormat={customFormat}
        leaveTypes={leaveTypes}
        handleCategoryChange={handleCategoryChange}
        categoryId={categoryId}
      />
    </Modal>
  );
};

MyLeavesModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onOk: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  onEdit: PropTypes.func,
  editModalData: PropTypes.object,
  editModal: PropTypes.bool,
  data: PropTypes.array,
  EMPID: PropTypes.string,
  leaveTypes: PropTypes.any,
};

export default MyLeavesModal;
