import React from "react";
import ReactApexChart from "react-apexcharts";
function EmployeeTicketStatus() {
  const chartData = {
    series: [67],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        offsetY: -10,
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            name: {
              fontSize: "16px",
              color: undefined,
              offsetY: 120,
            },
            value: {
              offsetY: 76,
              fontSize: "22px",
              color: undefined,
              formatter: (val) => val + "%",
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
      },
      stroke: {
        dashArray: 4,
      },
      labels: ["Closed Ticket"],
    },
  };
  return (
    <div className="flex flex-col w-full lg:w-1/3 p-4 bg-white rounded-lg shadow-lg mb-4 lg:mb-0">
      <h1 className="text-lg text-gray-400 p-2 font-bold mb-4">
        Ticket Status
      </h1>
      <div id="chart">
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="radialBar"
          height={350}
        />
      </div>
      <div className="flex flex-row items-center justify-around">
        <div className="text-center">
          <h1 className="text-lg font-bold">67%</h1>
          <p className="text-gray-600">New Ticket</p>
        </div>
        <div className="text-center">
          <h1 className="text-lg font-bold">67%</h1>
          <p className="text-gray-600">New Ticket</p>
        </div>
        <div className="text-center">
          <h1 className="text-lg font-bold">67%</h1>
          <p className="text-gray-600">New Ticket</p>
        </div>
      </div>
    </div>
  );
}

export default EmployeeTicketStatus;
