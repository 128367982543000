import { Button } from "antd";
import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import { DownloadOutlined } from "@ant-design/icons";
import * as XLSX from "xlsx";

function DownloadExcel({ excelData, title }) {
  const handleOk = () => {
    downloadExcel(excelData);
  };

  const downloadExcel = (data) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Tasks");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = title;
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <div className="flex space-x-2 items-center">
        <Button
          className="flex items-center  bg-blue-600 !text-white "
          onClick={handleOk}
        >
          <DownloadOutlined />
          Download
        </Button>
      </div>
    </div>
  );
}

// Validate props
DownloadExcel.propTypes = {
  excelData: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

export default DownloadExcel;
