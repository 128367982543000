import React from "react";
import ClientSurvayChart from "./ClientSurvayChart";
import SupportTicketSurvayChart from "./supportTicketSurvay";

function ClientSurvay() {
  return (
    <div className="flex justify-evenly gap-5">
      <ClientSurvayChart />
      <SupportTicketSurvayChart />
    </div>
  );
}

export default ClientSurvay;
