import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithReauth from "./baseQuery";
import moment from "moment";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";

export const holidaysService = createApi({
  reducerPath: "holidaysService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["HOLIDAYS"],
  endpoints: (builder) => ({
    getHolidays: builder.query({
      query: () => `${ENDPOINTS_CONSTANTS.GET_HOLIDAYS}`,
      providesTags: ["HOLIDAYS"],
      transformResponse: (response) => {
        return response.map((val) => {
          const { date, ...rest } = val;
          return { ...rest, date: moment(new Date(date)).format("YYYY-MM-DD") };
        });
      },
    }),

    postHolidays: builder.mutation({
      query: (payload) => {
        return {
          url: ENDPOINTS_CONSTANTS.ADD_HOLIDAY,
          method: "POST",
          body: payload,
        };
      },
      invalidatesTags: ["HOLIDAYS"],
    }),

    updateHoliday: builder.mutation({
      query: (payload) => {
        return {
          url: `${ENDPOINTS_CONSTANTS.UPDATE_HOLIDAY}/${payload.id}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["HOLIDAYS"],
    }),

    deleteHoliday: builder.mutation({
      query: (payload) => {
        return {
          url: `${ENDPOINTS_CONSTANTS.DELETE_HOLIDAY}`,
          method: "DELETE",
          body: { id: payload },
        };
      },
      invalidatesTags: ["HOLIDAYS"],
    }),
  }),
});

export const {
  useGetHolidaysQuery,
  usePostHolidaysMutation,
  useUpdateHolidayMutation,
  useDeleteHolidayMutation,
} = holidaysService;
