import React, { useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
  ReloadOutlined,
} from "@ant-design/icons";
import { Space, message } from "antd";
import Datatable from "../../components/common/Datatable";
import Header from "../../components/common/Header";
import DeleteModal from "../../components/common/DeleteModal";
import Icon from "../../components/common/Icon";
import AddModel from "./AddModel";

const initialData = [
  {
    key: "1",
    taskno: "Task 1",
    project: "Project A",
    client: "Client X",
    status: "Open",
    type: "Bug",
    priority: "Medium",
    executer: "John Doe",
    joiningdate: "2023-01-15",
    details: "Lorem ipsum details for Task 1",
    actions: "Edit/Delete",
  },
  {
    key: "2",
    taskno: "Task 2",
    project: "Project B",
    client: "Client Y",
    status: "Closed",
    type: "Feature",
    priority: "High",
    executer: "Jane Smith",
    joiningdate: "2023-01-16",
    details: "Lorem ipsum details for Task 2",
    actions: "Edit/Delete",
  },
  {
    key: "3",
    taskno: "Task 3",
    project: "Project C",
    client: "Client Y",
    status: "Open",
    type: "Development",
    priority: "Low",
    executer: "Jane Smith",
    joiningdate: "2023-01-16",
    details: "Lorem ipsum details for Task 3",
    actions: "Edit/Delete",
  },
];

function MyTasks() {
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [taskState, setTaskState] = useState({
    isModalOpen: false,
    selectedTaskData: null,
    taskData: initialData,
  });

  const handleEdit = (editedData) => {
    const updatedData = taskState.taskData.map((item) => {
      if (item.key === taskState.selectedTaskData.key) {
        return { ...item, ...editedData };
      }
      return item;
    });
    setTaskState({
      ...taskState,
      taskData: updatedData,
      isModalOpen: false,
      selectedTaskData: null,
    });
    message.success("Successfully updated");
  };

  const handleDelete = (key) => {
    const updatedTaskData = taskState.taskData.filter(
      (task) => task.key !== key
    );
    setTaskState({
      ...taskState,
      taskData: updatedTaskData,
    });
  };

  const handleAdd = (newTaskData) => {
    setTaskState((prevTaskState) => ({
      ...prevTaskState,
      taskData: [...prevTaskState.taskData, newTaskData],
      isModalOpen: false,
      selectedTaskData: null,
    }));
    message.success("Successfully added");
  };

  const handleReload = () => {
    window.location.reload();
  };

  const openModal = () => {
    setTaskState({ ...taskState, isModalOpen: true });
  };

  const closeModal = () => {
    setTaskState({ ...taskState, isModalOpen: false, selectedTaskData: null });
  };

  const openTaskModal = (key) => {
    const selected = taskState.taskData.find((item) => item.key === key);
    setTaskState({
      ...taskState,
      selectedTaskData: selected,
      isModalOpen: true,
    });
  };

  const renderActions = (keys) => {
    return (
      <div className="flex space-x-3">
        <Icon icon={<PlusOutlined onClick={openModal} />} color="bg-blue-600" />
        <Icon
          icon={<ReloadOutlined onClick={handleReload} />}
          color="bg-blue-600"
        />
        {keys.length > 1 && (
          <Icon
            icon={
              <DeleteOutlined onClick={() => setDeleteModalVisible(true)} />
            }
            color="bg-red-600"
          />
        )}
      </div>
    );
  };

  const renderStatus = (_, { status }) => {
    if (status === "Open") {
      return (
        <p className="text-orange-600 bg-red-100 w-14 border-rose-600 rounded-md text-center px-1 py-1 border-0">
          {status}
        </p>
      );
    } else {
      return (
        <p className="text-green-600 bg-green-100 w-14 border-0 border-emerald-600 rounded-md px-1 py-1 text-center">
          {status}
        </p>
      );
    }
  };

  const renderPriority = (_, { priority }) => {
    if (priority === "Low") {
      return (
        <p className="text-green-700 border w-14 border-emerald-600 rounded-md text-center">
          {priority}
        </p>
      );
    } else if (priority === "Medium") {
      return (
        <p className="text-blue-700 border w-14 border-blue-600 rounded-md text-center px-0">
          {priority}
        </p>
      );
    } else {
      return (
        <p className="text-orange-700 border w-14 border-rose-600 rounded-md text-center">
          {priority}
        </p>
      );
    }
  };

  const columns = [
    {
      title: "Task No",
      dataIndex: "taskno",
    },
    {
      title: "Project",
      dataIndex: "project",
    },
    {
      title: "Client",
      dataIndex: "client",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: renderStatus,
    },
    {
      title: "Type",
      dataIndex: "type",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      render: renderPriority,
    },
    {
      title: "Executer",
      dataIndex: "executer",
    },
    {
      title: "Joining Date",
      dataIndex: "joiningdate",
    },
    {
      title: "Details",
      dataIndex: "details",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <Space size="middle">
          <button
            type="primary"
            onClick={() => openTaskModal(record.key)}
            className="text-xl text-indigo-600"
          >
            <EditOutlined className="text-xl" />
          </button>
          <button>
            <DeleteOutlined
              className="text-red-500 text-xl"
              onClick={() => setDeleteModalVisible(true)}
            />
          </button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Header title="My Tasks" />
      <section className="mt-10 taskTable customizedTable">
        <Datatable
          tableData={taskState.taskData}
          columns={columns}
          checkBox
          rowSelection
          title="My Tasks"
          renderActions={(selectedRowKeys) => renderActions(selectedRowKeys)}
        />
        <DeleteModal
          visible={deleteModalVisible}
          onOk={(key) => handleDelete(key)}
          onCancel={() => setDeleteModalVisible(false)}
          title="Are you sure want to delete this data ?"
        />
      </section>
      <AddModel
        title={taskState.selectedTaskData ? "Update MyTasks" : "New MyTasks"}
        visible={taskState.isModalOpen}
        onCancel={closeModal}
        onSave={handleAdd}
        onEdit={handleEdit}
        selectedData={taskState.selectedTaskData}
      />
    </div>
  );
}

export default MyTasks;
