import React, { useState, useMemo } from "react";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Button, message, Result, Spin, Select } from "antd";
import Projectmodal from "./Projectmodal";
import { Input } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  useGetProjectsQuery,
  useDeleteProjectMutation,
} from "../../service/projectService";
import moment from "moment";
import DeleteModal from "../../components/common/DeleteModal";
import Previewmodal from "../../pages/Projects/Previewmodal";
import Header from "../../components/common/Header";
import { useSelector } from "react-redux";
const { Option } = Select;
function Project() {
  const [hoveredProject, setHoveredProject] = useState(null);
  const [sortBy, setSortBy] = useState("All Projects");
  const [searchTerm, setSearchTerm] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedProjectForEdit, setSelectedProjectForEdit] = useState(null);
  const { data: project, isFetching, isError } = useGetProjectsQuery();
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [previewedProject, setPreviewedProject] = useState(null);
  const [selectedProjectForDelete, setSelectedProjectForDelete] = useState(null);
  const user = useSelector((state) => state.application.user);
  const calculateProjectProgress = (created, deadline) => {
    if (created && deadline) {
      const currentDate = moment();
      const startDate = moment(created);
      const endDate = moment(deadline);
      const totalDuration = endDate.diff(startDate, "days");
      const elapsedDuration = currentDate.diff(startDate, "days");
      const rawProgress = (elapsedDuration / totalDuration) * 100;
      const progress = Math.max(Math.min(Math.round(rawProgress), 100), 0);
      return progress;
    }
    return 0;
  };
  const sortedProjects = useMemo(() => {
    const projectsCopy = (project || []).map((projectItem) => ({
      ...projectItem,
      projectProgress: calculateProjectProgress(
        projectItem.created,
        projectItem.deadline
      ),
    }));
    switch (sortBy) {
      case "createdAt":
        projectsCopy.sort((a, b) => new Date(b.created) - new Date(a.created));
        break;
      case "priority":
        projectsCopy.sort((a, b) => {
          const priorityOrder = { high: 1, medium: 2, low: 3 };
          return priorityOrder[a.priority] - priorityOrder[b.priority];
        });
        break;
      default:
        break;
    }
    return projectsCopy;
  }, [project, sortBy]);
  const handleEditClick = (project) => {
    setSelectedProjectForEdit(project);
    setModalVisible(true);
  };

  const handleAddClick = () => {
    setModalVisible(true);
  };

  const [deleteProject] = useDeleteProjectMutation();
  const handleDeleteClick = (project) => {
    setSelectedProjectForDelete(project);
    setIsDeleteModalVisible(true);
  };
  const handleDeleteConfirm = async () => {
    try {
      const result = await deleteProject(selectedProjectForDelete._id);
      if (result.data) {
        message.success("Project deleted successfully"); //eslint-disable-line
        setIsDeleteModalVisible(false);
      } else {
        message.error("Error deleting project"); //eslint-disable-line
      }
    } catch (error) {
      console.error("Error deleting project:", error); //eslint-disable-line
    }
  };
  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
  };
  const handlePreviewClick = (index) => {
    setPreviewedProject(index);
    setIsPreviewModalVisible(true);
  };
  const getMenu = (project) => (
    <Menu>
      {user.role === "admin" ? (
        <Menu.Item key="edit" onClick={() => handleEditClick(project)}>
          Edit
        </Menu.Item>
      ) : (
        ""
      )}
      {user.role === "admin" ? (
        <Menu.Item key="delete" onClick={() => handleDeleteClick(project)}>
          Delete
        </Menu.Item>
      ) : (
        ""
      )}
      <Menu.Item key="preview" onClick={() => handlePreviewClick(project)}>
        Preview
      </Menu.Item>
    </Menu>
  );
  const filteredProjects = sortedProjects?.filter(
    (project) =>
      project.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      project.department.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (project.created &&
        moment(project.created).format("DD-MM-YYYY").includes(searchTerm)) ||
      (project.deadline &&
        moment(project.deadline).format("DD-MM-YYYY").includes(searchTerm))
  );
  const displayedProjects = Array.isArray(filteredProjects)
    ? filteredProjects
    : [];
  return (
    <div>
      <Header title="Projects" />
      <div className="flex justify-between items-center mb-6 mt-4">
        <Select
          value={sortBy}
          onChange={(e) => setSortBy(e)}
          className="w-36 focus:outline-none bg-transparent text-sm cursor-pointer"
        >
          <Option value="All Projects">All Projects</Option>
          <Option value="createdAt">Sort by Date</Option>
          <Option value="priority">Sort by Priority</Option>
        </Select>
        <div className="flex items-center gap-6">
          <Input
            size="large"
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="ml-6"
            prefix={<SearchOutlined />}
          />
          {user.role === "admin" && (
            <Button
              className="bg-blue-600 !text-white border-none h-full"
              icon={<PlusOutlined />}
              onClick={handleAddClick}
            >
              Add
            </Button>
          )}
        </div>
      </div>
      {isFetching ? (
        <div className="mt-10 flex justify-center">
          <Spin size="large" />
        </div>
      ) : isError ? (
        <Result
          status="404"
          title="There are some problems with your operation."
        />
      ) : displayedProjects.length > 0 ? (
        <div className="clients-container mt-8 gap-4 grid  sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4">
          {displayedProjects.map((project) => (
            <div
              key={project._id}
              className="card relative bg-white p-6 shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)] overflow-hidden col-span-1"
              onMouseEnter={() => setHoveredProject(project._id)}
              onMouseLeave={() => setHoveredProject(null)}
            >
              <div className="mt-1 relative group ">
                <div className="absolute top-2 right-2 cursor-pointer opacity-0 transition-opacity duration-300 group-hover:opacity-100">
                  <Dropdown
                    overlay={getMenu(project)}
                    trigger={["click"]}
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MoreOutlined
                      className="text-2xl transform rotate-90  "
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Dropdown>
                </div>
                <div className="flex items-center justify-center mb-4">
                  <div
                    className={`w-16 h-16 rounded-full bg-gray-300 transition-colors duration-300 group-hover:bg-blue-600 relative`}
                  >
                    <span className="absolute inset-0 flex items-center justify-center z-10 text-white group-hover:hidden">
                      {project.title.charAt(0)}
                    </span>
                    <span className="absolute inset-0 flex items-center justify-center z-10 text-white opacity-0 group-hover:opacity-100">
                      {project.projectProgress}%
                    </span>
                  </div>
                </div>
                <p className="mb-2 text-lg tracking-tight text-black text-center">
                  {project.title || "Title Not Available"}
                </p>
                <p className="text-l text-gray-600 text-center">
                  {project.department || "Department Not Available"}
                </p>
                <div>
                  <hr className="my-4 border-dotted border-t-2 border-gray-300 w-full " />
                  <div className="flex ">
                    <div className="w-1/2 text-center">
                      <p className="text-l">Bugs</p>
                      <p className="text-md font-bold">{0}</p>
                    </div>
                    <div className="w-1/2 text-center">
                      <p className="text-l">Tasks</p>
                      <p className="text-md font-bold">{0}</p>
                    </div>
                  </div>
                  <hr className="my-4 border-dotted border-t-2 border-gray-300 w-full" />
                </div>
                <div className="mt-2 flex flex-col items-center">
                  {hoveredProject === project._id ? (
                    <div className="flex max-h-10 ">
                      <div className="flex flex-col">
                        <span className="text-gray-600 text-l ml-2 text-center">
                          From
                        </span>
                        {project.created && (
                          <span className="p-0 text-black font-bold text-md ml-2 text-center">
                            {moment(project.created).format("DD-MM-YYYY")}
                          </span>
                        )}
                      </div>
                      <div className=" w-1 border-r-red-600" />
                      <div className="flex flex-col">
                        <span className="text-gray-600 text-l  ml-2 text-center">
                          To
                        </span>
                        {project.deadline && (
                          <span className="p-0 text-black font-bold text-md ml-2 text-center">
                            {moment(project.deadline).format("DD-MM-YYYY")}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="flex h-10">
                      {project.team?.slice(0, 3).map((employee, index) => (
                        <div
                          key={index}
                          className="bg-blue-600 text-white font-light  p-1 rounded-full mr-2 h-8 w-8 flex items-center justify-center overflow-hidden flex-row"
                        >
                          {employee?.firstName?.charAt(0).toUpperCase()}
                        </div>
                      ))}
                      {project.team.length > 3 && (
                        <span className="text-black text-xl font-light  ml-2">
                          +{project.team.length - 3}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Result status="403" title="No data available" />
      )}
      <Previewmodal
        project={previewedProject ? previewedProject : {}}
        visible={isPreviewModalVisible}
        onCancel={() => setIsPreviewModalVisible(false)}
        user={user}
      />
      <Projectmodal
        visible={modalVisible}
        setModalVisible={setModalVisible}
        mode={!selectedProjectForEdit ? "add" : "edit"}
        project={selectedProjectForEdit}
        setSelectedProjectForEdit={setSelectedProjectForEdit}
      />
      <DeleteModal
        visible={isDeleteModalVisible}
        onOk={() => handleDeleteConfirm()}
        onCancel={() => handleDeleteCancel()}
        message="Are you sure do you want to delete this?"
        title="Delete"
      />
    </div>
  );
}
export default Project;
