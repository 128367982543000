import React from "react";
import PropTypes from "prop-types";

function Icon({ icon, color }) {
  return (
    <div className={`w-10 h-10 text-white text-lg font-extrabold rounded-full flex items-center justify-center ${color}`}>
        {icon }
    </div>
  );
}

Icon.propTypes = {
  icon: PropTypes.element,
  color: PropTypes.string,
};

export default Icon;
