import React from "react";
import { Avatar, Tooltip } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import DetailsCompletionPopover from "./DetailsCompletionPopover";
import PropTypes from "prop-types";

function EmpTableProfile({ profileImageUrl, name, record }) {
  return (
    <div className="flex items-center gap-2">
      {profileImageUrl ? (
        <img
          src={profileImageUrl}
          className="rounded-full h-[30px] w-[30px] object-cover"
          alt="profile"
        />
      ) : (
        <Avatar size="30">
          {`${record.firstName[0]}`}
          {`${record.lastName[0]}`}
        </Avatar>
      )}
      <Tooltip title={name}>
        <div className="max-w-[150px] truncate">{name}</div>
      </Tooltip>
      <div>
        {record.languages?.length &&
        record.skills?.length &&
        record.education?.length ? (
          <CheckCircleOutlined className="text-green-400" />
        ) : (
          <DetailsCompletionPopover record={record} />
        )}
      </div>
    </div>
  );
}
EmpTableProfile.propTypes = {
  profileImageUrl: PropTypes.string,
  name: PropTypes.string,
  record: PropTypes.object,
};
export default EmpTableProfile;
