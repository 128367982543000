import React, { useState } from "react";

function EmployeeTodoList() {
  const [todos, setTodos] = useState([
    { id: 1, text: "Task 1", priority: "high", completed: false },
    { id: 2, text: "Task 2", priority: "normal", completed: true },
    { id: 3, text: "Task 3", priority: "low", completed: false },
  ]);

  const handleCheckboxChange = (id) => {
    setTodos((prevTodos) =>
      prevTodos.map((todo) =>
        todo.id === id ? { ...todo, completed: !todo.completed } : todo
      )
    );
  };
  const renderPriority = (priority) => {
    switch (priority) {
      case "high":
        return "text-red-600";
      case "normal":
        return "text-yellow-600";
      default:
        return "text-green-600";
    }
  };
  return (
    <div className="flex flex-col w-full lg:w-1/3 p-4 bg-white rounded-lg shadow-lg mb-4 lg:mb-0 border">
      <h1 className="text-lg text-gray-400 p-2 font-bold mb-4">Todo List</h1>
      <div className="border divide-y overflow-y-auto max-h-96">
        {todos.map((todo) => (
          <div key={todo.id} className="flex items-center mb-2 p-2">
            <input
              type="checkbox"
              checked={todo.completed}
              onChange={() => handleCheckboxChange(todo.id)}
              className="mr-2"
            />
            <span
              className={`text-base ${
                todo.completed ? "line-through" : ""
              } flex-grow text-gray-800`}
            >
              {todo.text}
            </span>
            <span className={`text-base ${renderPriority(todo.priority)}`}>
              {todo.priority}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default EmployeeTodoList;
