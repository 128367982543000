import React from "react";
import EmployeeFilter from "./EmployeeFilter";
import { PlusOutlined, ReloadOutlined, MoreOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
// import EmployeeExcel from "./EmployeeExcel";
import PropTypes from "prop-types";

function EmpTableBtn({
  keys,
  reset,
  handleMenuItemClick,
  // handleConvert,
  handleReload,
  openModal,
  deleteID,
  filteredEmployeeData,
  setFilteredEmployeeData,
}) {
  // const excelChange = true;
  const getMenu = (keys) => (
    <Menu>
      {keys.length < 1 && (
        <Menu.Item
          key="Add"
          className="flex items-center border-none  w-full"
          onClick={openModal}
        >
          <PlusOutlined className="mr-1" /> Add
        </Menu.Item>
      )}

      {keys.length < 1 && (
        <Menu.Item
          key="delete"
          className="flex items-center justify-center border-none  w-full"
          onClick={handleReload}
        >
          <ReloadOutlined className="mr-2" />
          Refresh
        </Menu.Item>
      )}

      {keys.length > 0 && (
        <Menu.Item
          key="delete"
          className="flex items-center  border-none w-full"
          onClick={() => deleteID(keys, reset)}
        >
          <FontAwesomeIcon className="mr-2" icon={faTrashCan} />
          Delete
        </Menu.Item>
      )}

      {/* <Menu.Item key="upload">
        {keys.length < 1 && (
          <EmployeeExcel excelChange={excelChange} onConvert={handleConvert} />
        )}
      </Menu.Item> */}
    </Menu>
  );
  return (
    <div>
      <div className="flex space-x-2 items-center h-full max-[950px]:hidden min-[950px]:visible">
        {keys.length < 1 && (
          <EmployeeFilter
            handleMenuItemClick={handleMenuItemClick}
            filteredEmployeeData={filteredEmployeeData}
            setFilteredEmployeeData={setFilteredEmployeeData}
          />
        )}
        {keys.length < 1 && (
          <Button
            className="flex justify-center items-center bg-blue-600 !text-white"
            onClick={openModal}
          >
            <PlusOutlined /> Add
          </Button>
        )}
        {keys.length < 1 && (
          <Button
            className="flex items-center  bg-blue-600 !text-white"
            onClick={handleReload}
          >
            <ReloadOutlined />
            Refresh
          </Button>
        )}
        {keys.length > 1 && (
          <Button
            className="flex items-center  bg-red-600 !text-white"
            onClick={() => deleteID(keys, reset)}
          >
            <FontAwesomeIcon className="mr-2" icon={faTrashCan} />
            Delete
          </Button>
        )}
        {/* {keys.length < 1 && <EmployeeExcel onConvert={handleConvert} />} */}
      </div>

      <div className="flex space-x-2 items-center h-full min-[950px]:hidden max-[950px]:visible">
        <EmployeeFilter
          handleMenuItemClick={handleMenuItemClick}
          filteredEmployeeData={filteredEmployeeData}
          setFilteredEmployeeData={setFilteredEmployeeData}
        />
        <Dropdown overlay={getMenu(keys)} trigger={["click"]}>
          <MoreOutlined className="text-2xl" />
        </Dropdown>
      </div>
    </div>
  );
}

EmpTableBtn.propTypes = {
  keys: PropTypes.array,
  reset: PropTypes.array,
  handleMenuItemClick: PropTypes.func,
  handleConvert: PropTypes.func,
  handleReload: PropTypes.func,
  openModal: PropTypes.func,
  deleteID: PropTypes.func,
  filteredEmployeeData: PropTypes.array,
  setFilteredEmployeeData: PropTypes.func,
};

export default EmpTableBtn;
