import React from "react";
import {
  CheckCircleOutlined,
  ProjectOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Form, Input, Modal, Select, message } from "antd";
import PropTypes from "prop-types";

const { Option } = Select;

const AddModel = ({
  visible,
  onSave,
  onCancel,
  title,
  onEdit,
  selectedData,
}) => {
  const [form] = Form.useForm();

  if (selectedData) {
    form.setFieldsValue(selectedData);
  }

  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        if (selectedData) {
          onEdit(values);
        } else {
          onSave(values); // This should trigger handleAdd in MyTasks
        }
        form.resetFields();
        onCancel();
      })
      .catch(() => {
        message.error("Please enter all the fields");
      });
  };

  const handleCancel = () => {
    onCancel();
    form.resetFields();
  };

  const footerContent = (
    <div className="flex">
      <Button
        className="ml-3 w-1/2 border border-red-500 text-red-500"
        size="large"
        onClick={handleCancel}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        size="large"
        className="mr-2 bg-blue-500 text-white w-1/2"
        onClick={handleOk}
      >
        Save
      </Button>
    </div>
  );

  return (
    <Modal
      title={title}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={footerContent}
    >
      <Form className="p-5" form={form}>
        <div className="grid grid-cols-2 gap-8">
          <div className="col-span-1">
            <Form.Item
              name="taskno"
              className="mb-0"
              rules={[{ required: true, message: "Please enter the task no" }]}
            >
              <Input
                type="text"
                placeholder="Task No"
                className="p-2"
                suffix={<CheckCircleOutlined className="text-black-400" />}
              />
            </Form.Item>
          </div>
          <div className="col-span-1">
            <Form.Item
              name="project"
              className="mb-0"
              rules={[{ required: true, message: "Please enter the project" }]}
            >
              <Input
                type="text"
                placeholder="Project"
                className="p-2"
                suffix={<ProjectOutlined className="text-black-400" />}
              />
            </Form.Item>
          </div>
          <div className="col-span-1">
            <Form.Item
              name="client"
              className="mb-0"
              rules={[{ required: true, message: "Please enter the client" }]}
            >
              <Input
                type="text"
                placeholder="Client"
                className="p-2"
                suffix={<UserOutlined className="text-black-400" />}
              />
            </Form.Item>
          </div>
          <div className="col-span-1">
            <Form.Item
              name="status"
              className="mb-0"
              rules={[{ required: true, message: "Please select status" }]}
            >
              <Select size="large" className="" placeholder="Status">
                <Option value="Open">Open</Option>
                <Option value="Closed">Closed</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-1">
            <Form.Item
              name="priority"
              className="mb-0"
              rules={[{ required: true, message: "Please select priority" }]}
            >
              <Select size="large" className="" placeholder="Priority">
                <Option value="High">High</Option>
                <Option value="Medium">Medium</Option>
                <Option value="Low">Low</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-1">
            <Form.Item
              name="type"
              className="mb-0"
              rules={[{ required: true, message: "Please select a type" }]}
            >
              <Select size="large" className="" placeholder="Type">
                <Option value="Bug">Bug</Option>
                <Option value="Error">Error</Option>
                <Option value="Development">Development</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="col-span-1">
            <Form.Item
              name="executer"
              className="mb-0"
              rules={[{ required: true, message: "Please enter the executer" }]}
            >
              <Input
                type="text"
                placeholder="Executer"
                className="p-2"
                suffix={<TeamOutlined className="text-black-400" />}
              />
            </Form.Item>
          </div>
          <div className="col-span-1">
            <Form.Item
              name="joiningdate"
              className="mb-0"
              rules={[{ required: true, message: "Please select date" }]}
            >
              <Input
                type="date"
                className="w-full text-black-600 bg-white border border-gray-300 rounded px-3 py-2"
              />
            </Form.Item>
          </div>
          <div className="col-span-2">
            <Form.Item
              name="details"
              className="mb-0"
              rules={[{ required: true, message: "Please enter the details" }]}
            >
              <Input.TextArea rows={3} placeholder="400 Characters only" />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

AddModel.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  selectedData: PropTypes.object,
};

export default AddModel;
