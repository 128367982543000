import React, { useState } from "react";
import Datatable from "../../components/common/Datatable";
import Header from "../../components/common/Header";
import { ReloadOutlined, PlusOutlined, LeftOutlined } from "@ant-design/icons";
import AssociateModal from "./associateModal";
import DeleteModal from "../../components/common/DeleteModal";
import { useNavigate, useLocation } from "react-router-dom";
import {
  useDeleteAssociateMutation,
  useGetAssociatesQuery,
} from "../../service/clientService";
import { message, Button } from "antd";
import IconButton from "../../components/common/IconButton";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Profile = () => {
  const [state, setState] = useState({
    deleteModalVisible: false,
    addModalVisible: false,
    id: null,
    editData: null,
  });
  const location = useLocation();
  const clientData = location.state?.clientData || null;
  const { data: Associate, refetch } = useGetAssociatesQuery(clientData._id);

  const handleCancel = () => {
    setState({ ...state, addModalVisible: false, editData: null });
  };

  const handleOk = async () => {
    setState({ ...state, addModalVisible: false, editData: null });
  };

  const [deleteAssociate, { isLoading: deleteLoading }] =
    useDeleteAssociateMutation();
  const navigate = useNavigate();

  const openDeleteModal = (id) => {
    setState({ ...state, id, deleteModalVisible: true });
  };

  const editModal = (data) => {
    setState({ ...state, addModalVisible: true, editData: data });
  };

  const columns = [
    {
      title: "Associate Name",
      dataIndex: "name",
    },
    {
      title: "Mail",
      dataIndex: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (_, record) => (
        <div className="flex space-x-2.5">
          <IconButton
            title="Edit"
            icon={
              <FontAwesomeIcon
                icon={faPenToSquare}
                onClick={() => editModal(record)}
              />
            }
          />
          <IconButton
            title="Delete"
            icon={
              <FontAwesomeIcon
                icon={faTrashCan}
                onClick={() => openDeleteModal(record._id)}
              />
            }
          />
        </div>
      ),
    },
  ];

  const renderActions = (keys) => {
    return (
      <div className="flex space-x-3 items-center">
        <Button
          className="flex justify-center items-center bg-blue-600 !text-white"
          onClick={() => {
            setState({ ...state, addModalVisible: true, editData: null });
          }}
        >
          <PlusOutlined /> Add
        </Button>
        <Button
          className="flex items-center  bg-blue-600 !text-white"
          onClick={() => refetch()}
        >
          <ReloadOutlined className="active:rotate-180 delay-[5000ms] duration-300 ease-in-out" />
          Refresh
        </Button>

        {keys.length > 1 && (
          <Button className="flex items-center  bg-red-600 !text-white">
            <FontAwesomeIcon className="mr-2" icon={faTrashCan} />
            Delete
          </Button>
        )}
      </div>
    );
  };

  const handleDelete = () => {
    const payload = { associateId: state.id };
    deleteAssociate(payload)
      .unwrap()
      .then(() => {
        message.success("Successfully Deleted");
        setState({ ...state, deleteModalVisible: false });
      })
      .catch((error) => {
        console.log(error); // eslint-disable-line
        message.error("Something went wrong");
      })
      .finally(() => {
        setState({ ...state, deleteModalVisible: false });
      });
  };

  const deleteCancel = () => {
    setState({ ...state, deleteModalVisible: false });
  };

  return (
    <div>
      <Button
        type="link"
        className=" text-black hover:!text-black flex items-center p-0 mb-5"
        onClick={() => {
          navigate("/clients");
        }}
      >
        <LeftOutlined />
        <span className="text-base">Back</span>
      </Button>
      <Header title="Associate Profile" />
      <section className="mt-10 customizedTable">
        <Datatable
          tableData={Associate}
          columns={columns}
          rowSelection
          title="Associate Profile"
          renderActions={renderActions}
        />
        <AssociateModal
          title={state.editData ? "Edit Associate" : "Add Associate"}
          visible={state.addModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          clientData={clientData}
          editData={state.editData}
        />
        <DeleteModal
          visible={state.deleteModalVisible}
          onOk={() => handleDelete()}
          onCancel={() => deleteCancel()}
          title="Delete Associate"
          message="Are you sure to delete this Associate?"
          isLoading={deleteLoading}
        />
      </section>
    </div>
  );
};

export default Profile;
