import { Button, message, Modal, Result, Spin } from "antd";
import React, { useState } from "react";
import Header from "../../components/common/Header";
import { PlusOutlined } from "@ant-design/icons";
import BlogForm from "./form/BlogForm";
import {
  useDeleteBlogMutation,
  useGetBlogsQuery,
} from "../../service/blogQuery";
import DeleteModal from "../../components/common/DeleteModal";

const initialState = {
  flag: false,
  data: null,
};

export const initialBlog = {
  title: "",
  category: "",
  description: "",
  thumbnail: { file: null, list: [] },
  author: "",
  tags: [],
};

export const initialContent = {
  title: "",
  description: "",
  image: [],
};

function Blogs() {
  const [formState, setFormState] = useState(initialState);
  const [blog, setBlog] = useState(initialBlog);
  const [tableOfContent, setTableOfContent] = useState([initialContent]);
  const [activeKey, setActiveKey] = useState("1");
  const [deleteModal, setDeleteModal] = useState(null);
  const [thumbnailInitialEditorState, setThumbnailInitialEditorState] =
    useState("");
  const [contentInitialEditorState, setContentInitialEditorState] = useState(
    {}
  );

  const resetForm = () => {
    setActiveKey("1");
    setBlog(initialBlog);
    setTableOfContent([initialContent]);
  };

  const closeModal = () => {
    setFormState(initialState);
    resetForm();
  };
  // RTK Query
  const { data: blogs, isFetching } = useGetBlogsQuery();
  const [deleteBlog, { isLoading: deleteLoading }] = useDeleteBlogMutation();
  const isEdit =
    formState.data && formState.flag && typeof formState.data === "object";

  const handleAdd = () => {
    setFormState({ flag: true, data: null });
    setThumbnailInitialEditorState("");
    setContentInitialEditorState({});
  };

  const handleEdit = (editData) => {
    const {
      title,
      description,
      thumbnail,
      author,
      content,
      _id,
      category,
      tags,
    } = editData;
    setBlog({
      title,
      description,
      category,
      tags,
      thumbnail: {
        file: {},
        list: [
          {
            name: thumbnail.key,
            status: "done",
            url: thumbnail.url,
          },
        ],
      },
      author: author?.uniqueId,
      _id,
    });
    const contentDescription = {};
    if (Array.isArray(content) && content.length > 0) {
      const updatedContent = content.map(
        ({ description, title, image, _id }, index) => {
          const contentImage = Array.isArray(image)
            ? image.map(({ key, url }) => ({
                file: {},
                meta: {
                  name: key,
                  status: "done",
                  url,
                },
              }))
            : [];
          contentDescription[index] = description;
          return {
            title,
            description,
            image: contentImage,
            isRead: true,
            _id,
          };
        }
      );
      setTableOfContent(updatedContent);
    }

    setThumbnailInitialEditorState(description);
    setContentInitialEditorState(contentDescription);
    setFormState({ flag: true, data: editData });
  };

  const handleDelete = () => {
    deleteBlog(deleteModal)
      .unwrap()
      .then((response) => {
        const textMessage = response?.message || "Success !";
        message.success(textMessage);
        setDeleteModal(null);
      })
      .catch((error) => {
        console.log(error); //eslint-disable-line
        message.error("Something went wrong");
      });
  };

  return (
    <>
      <Spin fullscreen spinning={isFetching} />
      <DeleteModal
        visible={Boolean(deleteModal)}
        onOk={handleDelete}
        onCancel={() => setDeleteModal(null)}
        isLoading={deleteLoading}
        title="Are you sure want to delete this data ?"
        width={350}
      />
      <div>
        <Modal
          title={isEdit ? "Edit Blog" : "Add Blog"}
          open={formState.flag}
          footer={false}
          centered
          className="bg-black bg-opacity-20"
          width={850}
          onCancel={closeModal}
        >
          <BlogForm
            closeModal={closeModal}
            editData={formState.data}
            blog={blog}
            setBlog={setBlog}
            tableOfContent={tableOfContent}
            setTableOfContent={setTableOfContent}
            activeKey={activeKey}
            setActiveKey={setActiveKey}
            resetForm={resetForm}
            thumbnailEditor={thumbnailInitialEditorState}
            contentEditor={contentInitialEditorState}
          />
        </Modal>
        <header className="flex justify-between items-center">
          <Header title="Blogs" />
          <Button
            className="bg-blue-600 !text-white border-none h-full"
            icon={<PlusOutlined />}
            onClick={handleAdd}
          >
            Add blog
          </Button>
        </header>
        {Array.isArray(blogs) && blogs.length > 0 ? (
          <div className="mt-6 gap-4 grid  sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pb-4">
            {blogs.map((item) => {
              return (
                <div
                  className="relative flex flex-col text-gray-700 bg-white shadow-md bg-clip-border rounded-xl"
                  key={item?._id}
                >
                  <div className="relative mx-4 mt-4 overflow-hidden text-gray-700 bg-white bg-clip-border rounded-xl h-96">
                    <img
                      src={item?.thumbnail?.url}
                      alt="card"
                      className="object-cover w-full h-full"
                    />
                  </div>
                  <div className="p-6">
                    <div className="flex items-center justify-between mb-2">
                      <p className="block font-sans text-base antialiased font-medium leading-relaxed text-blue-gray-900">
                        {item?.title}
                      </p>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}
                      className="block font-sans text-sm antialiased font-normal leading-normal text-gray-700 opacity-75"
                    />
                  </div>
                  <div className="p-6 pt-0 flex gap-4">
                    <button
                      className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100 border-slate-400 border"
                      type="button"
                      onClick={() => handleEdit(item)}
                    >
                      Edit
                    </button>
                    <button
                      className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg shadow-gray-900/10 hover:shadow-gray-900/20 focus:opacity-[0.85] active:opacity-[0.85] active:shadow-none block w-full bg-blue-gray-900/10 text-blue-gray-900 shadow-none hover:scale-105 hover:shadow-none focus:scale-105 focus:shadow-none active:scale-100 border-red-500 border text-red-500"
                      type="button"
                      onClick={() => setDeleteModal(item?._id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="w-full flex justify-center mt-8">
            <Result status="404" subTitle="No records found" />
          </div>
        )}
      </div>
    </>
  );
}

export default Blogs;
