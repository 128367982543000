import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";

function IconButton({ onClick, icon, title }) {
  const renderBody = () => {
    return (
      <div
        className="w-[30px] h-[30px] bg-gray-100 rounded-full grid place-content-center cursor-pointer hover:bg-gray-200"
        onClick={onClick}
        aria-hidden="true"
      >
        {icon}
      </div>
    );
  };
  return title ? <Tooltip title={title}>{renderBody()}</Tooltip> : renderBody();
}

IconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  title: PropTypes.string,
};
export default IconButton;
