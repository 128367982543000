import { createApi } from "@reduxjs/toolkit/query/react";
import { ENDPOINTS_CONSTANTS } from "../constants/endpointConstants";
import baseQueryWithReauth from "./baseQuery";

export const invoiceService = createApi({
  reducerPath: "invoiceService",
  baseQuery: baseQueryWithReauth,
  tagTypes: ["INVOICE"],

  endpoints: (builder) => ({
    postInvoice: builder.mutation({
      query: (invoiceData) => {
        return {
          url: ENDPOINTS_CONSTANTS.ADD_INVOICE,
          method: "POST",
          body: invoiceData,
          headers: {
            "content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["INVOICE"],
    }),

    updatePaymentStatus: builder.mutation({
      query: (invoiceId) => {
        return {
          url: `${ENDPOINTS_CONSTANTS.UPDATE_INVOICE}${invoiceId}`,
          method: "PUT",
          headers: {
            "content-type": "application/json; charset=UTF-8",
          },
        };
      },
      invalidatesTags: ["INVOICE"],
    }),

    sendReminderEmail: builder.mutation({
      query : (invoiceData) => {
        return{
          url: ENDPOINTS_CONSTANTS.SEND_EMAIL_REMINDER,
          method: "POST",
          body: invoiceData,
        };
      },
    }),

    getInvoices: builder.query({
      query: () => ENDPOINTS_CONSTANTS.ALL_INVOICES,
      providesTags: ["INVOICE"],
      transformResponse: (res) =>
        res.invoice.map((values, index) => ({ ...values, index  })),
    }),

    deleteInvoice: builder.mutation({
      query: (payload) => ({
        url: `${ENDPOINTS_CONSTANTS.DELETE_INVOICE}${payload.invoiceId}`,
        method: "DELETE",
        body: payload.isActive,
      }),
      invalidatesTags: ["INVOICE"],
    }),
  }),
});

export const {
  usePostInvoiceMutation,
  useSendReminderEmailMutation,
  useUpdatePaymentStatusMutation,
  useGetInvoicesQuery,
  useDeleteInvoiceMutation,
} = invoiceService;
